import axios from 'axios';
import { apiErrorHandler } from './api-hander';
import { API_BASE_URL } from '../../config';
import { store } from '../../store';
import { logoutUser } from '../../store/slices/auth-slice';
import toast from 'react-hot-toast';
import { setAfterLoginAction } from '../../store/slices/common-slice';

// axios instance
export const AxiosInstance = axios.create({
  baseURL: `${API_BASE_URL}/api`,
});

// Add a request interceptor
AxiosInstance.interceptors.request.use(
  function (config) {
    // Do something before request is sent

    const Token = localStorage.getItem('token');
    if (Token) {
      config.headers.Authorization = `Bearer ${Token}`;
    }

    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
AxiosInstance.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    if (error?.response?.status === 401) {
      // logout user on 401 error
      store.dispatch(logoutUser());
      store.dispatch(
        setAfterLoginAction({
          action: "",
          redirectPath: '/',
        })
      );
      localStorage.clear();
      if (typeof window !== 'undefined') {
        window.location.assign = '/';
      }
      toast.error('You are authorised please login again',{id: "unauthorised error"});
    }
    apiErrorHandler(error);
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);

import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import AvatarEditor from "react-avatar-editor";
import AppModal from "../modals";
import toast from "react-hot-toast";

function Dropzone(props) {
  const { handleProfileSelected, selected, onClose,isVisible,setSelectedOption } = props;
  const [showChooseFromGallery, setShowChooseFromGallery] = useState(false);
  const hasOpenedDialog = useRef(false);
  const [zoom, setZoom] = useState("0.5");
  const imageRef = useRef();

  const onClickSave = () => {
    if (imageRef.current && imageRef.current.getImage) {
      const canvas = imageRef.current.getImage().toDataURL();
      handleProfileSelected("profileImage", canvas);
      setShowChooseFromGallery(false);
      onClose()
    }
  };

  const onDrop = useCallback(
    (acceptedFiles) => {
      const file = acceptedFiles[0];
      // Ensure that the file is an image before processing
      if (file && file.type.startsWith("image/")) {
        const reader = new FileReader();
        reader.onload = () => {
          handleProfileSelected("profileImage", reader.result);
          setShowChooseFromGallery(true);
        };
        reader.readAsDataURL(file);
      } else {
        toast.error("Please select a valid image file.",{id: "onDrop Error"});
        onClose()
      }
    },
    [handleProfileSelected]
  );

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop,
    accept: "image/*",
    noClick: true,
    noKeyboard: true,
  });

  useEffect(() => {
    if (isVisible && !hasOpenedDialog.current) {
      open();
      hasOpenedDialog.current = true;
    } else {
      hasOpenedDialog.current = false;
      setSelectedOption()
    }
  }, [open, isVisible]);


  return (
    <>
      <div {...getRootProps()}>
        <input {...getInputProps()} />
      </div>

      {/* {showChooseFromGallery && <AppModal
        show={showChooseFromGallery}
        onClose={() => onClose()}
        heading="Crop Image"
        bodyClass="w-100 mw-100"
      >
        <div className="ChooseFromGallery">
          {selected && (
            <>
              <input
                onChange={(event) => setZoom(event.target.value)}
                type="range"
                min="1"
                max="100"
                step="0.1"
                value={zoom}
              />
              <AvatarEditor
                ref={imageRef}
                image={selected}
                width={200}
                height={200}
                border={50}
                color={[255, 255, 255, 0.6]} // RGBA
                scale={+zoom}
                rotate={0}
                borderRadius={0}
              />
            </>
          )}
        </div>
        <button onClick={() => onClickSave()} className="btn-dark btn-sm mb-4">
          Save
        </button>
      </AppModal>} */}
    </>
  );
}

export default Dropzone;

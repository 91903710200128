export const API_END_POINT = {
  homeDetails: '/Home/GetByHomePage',
  homeDetailsAfterLogin: '/Home/GetHomePageByUser',
  sendOtp: '/identity/OTP',
  verifyOtp: '/identity/OTPLogin',
  registerUser: '/identity/register',
  getUserProfile: '/identity/GetUserDetail',
  updateProfile: '/identity/UpdateUserDetail',
  addClaimData: '/ClaimForm/ClaimFormAddEdit',
  avatarList: '/Masters/GetAvatar',
  getAddressByUId: '/User/getaddressbyuserid',
  getAddressByAId: '/User/getaddressbyid',
  addEditAddress: '/User/addeditaddress',
  deleteAddress: '/User/deleteaddress',
  defaultAddress: '/User/setdefaultaddress',
  sendRefer: '/User/sendreferal',
  cityStateByPinCode: '/Geolocation/GetByPinCode',
  // getWalletData: '/Rewards/GetTierWiseRewardsByUser',
  getWalletData: '/Rewards/GetAllRewards',

  getRewardsHistory: '/Rewards/GetAllRewardsHistory',
  getAllArtist: '/Video/GetAllArtistList',
  faqDetails: '/Masters/GetFAQ',
  resendReferral : '/User/resendreferal',
  sendReferral: '/User/sendreferal',
  getReferral: '/User/GetReferralList',
  getAllNotification: '/Notification/GetNotificationListPageingById',
  readNotification: '/Notification/ReadNotificationByID',
  readAllNotification: '/Notification/ReadAllNotification',
  deleteNotificationById: '/Notification/DeleteNotificationById',
  deleteAllNotification: '/Notification/DeleteAllNotification',
  getContest: '/Contest/GetAllContest',
  getAllBehindThescneData: '/Video/GetBehindScenes',
  getAllMelodies: '/Video/GetAllMelodies',
  getAllTreasures: '/Video/GetAllTreasure',
  getAllUGC: '/Video/GetAllUGCVideo',
  getAllTheSeasons: '/Video/GetSeasons',
  userViewedVedio: '/User/AddUserVideo',
  likeVedios: '/Video/SaveLikeVideoById',
  uniqueCode: '/MixCode/verify-mixcode',
  validateRefCode:'/identity/ValidateCode',
  verifyRefCode:'/User/VerifyRefCode',
  pendingReferalList:'/User/GetPendingReferralList',
  getStateList : `/Geolocation/GetStateDDL`,
  getCityList : `/Geolocation/GetCityDDL`,
  getLeaderBoardList : `/LeaderBoard/GetAllLeaderBoard`,
  songDrop:`/songdrop`,
  socialMedia : `/Identity/SocialMediaLogin`
};

import React, { useState } from 'react';
import { FacebookShareButton } from 'react-share';
import { FacebookIcon } from 'react-share';
import { CopyToClipboard } from "react-copy-to-clipboard";
import * as Yup from 'yup';
import AppModal from '../../modals';
import useTranslation from '../../../utils/hooks/use-translation';

const ShareBadgePopup = (props) => {

    const { t } = useTranslation();
    const { show, onClose, type } = props;
    const shareUrl = "www.google1.com";
    const instagramShareUrl = `https://www.instagram.com/create/story/?url=${shareUrl}`;
    const tiktokShareUrl = `https://www.tiktok.com/upload?u=${shareUrl}`;

    const [copyLink, setCopyLink] = useState(false)

    const validationSchema = Yup.object().shape({
        link: Yup.string()
            .matches(
                /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
                'Please enter the valid link!'
            )
            .required('Please enter link'),
    });

    // const handleShareInstagram = () => {
    //     window.open(`https://www.instagram.com/create/story/?url=${shareUrl}`);
    // };

    // const handleShareTikTok = () => {
    //     window.open(`https://www.tiktok.com/upload?u=${url}`);
    // };



    return (
        <>
            <AppModal
                show={show}
                onClose={onClose}
                bodyClass="px-6 pt-2 pb-5"
                size="md">
                <div className="text-center">

                    <h6 className='modal-heading pb-md-3 pb-2'>Share your achievements?</h6>
                    <div className='social-media-share'>
                        <div>
                            <FacebookShareButton
                                url={'https://devcokestudio.coke2home.com/'}
                                quote={'Badge Link'}
                                hashtag="#fan"
                            >
                                <FacebookIcon size={32} round />
                            </FacebookShareButton>

                            <a href={instagramShareUrl} target="_blank" rel="noopener noreferrer">
                                <img src='/assets/img/icons/instagram.png' alt="Instagram" style={{ width: '34px', height: '37px' }} />
                            </a>

                            <a href={tiktokShareUrl} target="_blank" rel="noopener noreferrer">
                                <img src='/assets/img/icons/tiktok.png' alt="TikTok" style={{ width: '34px', height: '26px' }} />
                            </a>


                        </div>
                        <h6 className='text-left my-3'>or copy link</h6>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="input-group">
                                    <input type="text" className="form-control" value={shareUrl} readOnly />
                                    <div className="input-group-append">
                                        <CopyToClipboard onCopy={() => setCopyLink(true)} text={shareUrl}>
                                            <p className="btn btn-outline-secondary" type="button">Copy</p>
                                        </CopyToClipboard>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>

            </AppModal>

        </>
    );
};

export default ShareBadgePopup;

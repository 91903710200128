import React from 'react'

function PointsBox({coin}) {
    return (
        <div className="points-box points-box-black">
            <h6 className="points-num points-black info-card-pts">
               {coin ? coin : 600}
            </h6>
            <img
                src="/assets/img/icons/Layer 1.svg"
                alt="coin"
                className="points-sign info-card-sign"
            />
        </div>
    )
}

export default PointsBox;
import { createContext, useState, useCallback } from 'react';
import UniqueCodeModal from '../appForm/UniqueCode';
import ReferAFriendForm from '../appForm/ReferFriendForm';
import SuccessModel from '../../modals/api_response_model';
import ReferFriendModal from '../../modals/ReferFriendModal';
import ReferFriend from '../../../pages/ReferaFriend';
export const PopupContext = createContext();

// ... other imports

export const PopupProvider = ({ children }) => {
  const [showPopup, setShowPopup] = useState({ type: '', show: false });

  const openPopup = useCallback(
    (type, action, params) => {
      setShowPopup({
        type,
        show: action !== undefined ? action : !showPopup.show,
        params: params,
      });
    },
    [showPopup]
  );

  const closePopup = useCallback(() => {
    setShowPopup({ type: '', show: false, params: '' });
  }, []);

  return (
    <>
      <PopupContext.Provider value={{ openPopup }}>
        {children}
        {showPopup.type === 'unique_code' && (
          <UniqueCodeModal
            show={showPopup.show}
            onClose={closePopup}
            type={'model'}
            params={showPopup.params}
          />
        )}
        {showPopup.type === 'refer' && (
          <ReferAFriendForm
            show={showPopup.show}
            onClose={closePopup}
            type={'refer_code'}
          />
        )}
        {showPopup.type === 'refer_friend' && (
          <ReferFriendModal
            show={showPopup.show}
            onClose={closePopup}
            setShowPopup={setShowPopup}
            type={'refer_number'}
            getReferralList={() => console.log('')}
          />
        )}


        {showPopup.type === 'success_model' && (
          <SuccessModel
            show={showPopup.show}
            onClose={closePopup}
            {...showPopup.params}
          />
        )}

      </PopupContext.Provider>
    </>
  );
};

import { AxiosInstance } from "../utils/axios-helper/axios-helper";
import { API_END_POINT } from "../utils/constants/api-end-points";
import { version } from '../config'

//add claim form details
export const addClaimDetails = async (data) => {
  return await AxiosInstance.post(`${version}${API_END_POINT.addClaimData}`, data);
};

export const getAddressByUserId = async () => {
  return await AxiosInstance.get(`${version}${API_END_POINT.getAddressByUId}`);
};

export const getAddressById = async (addressid) => {
  return await AxiosInstance.get(`${version}${API_END_POINT.getAddressByAId}?addressid=${addressid}`);
};

export const addUpdateAddress = async (data) => {
  return await AxiosInstance.post(`${version}${API_END_POINT.addEditAddress}`, data);
};

export const deleteAddress = async (data) => {
  return await AxiosInstance.post(`${version}${API_END_POINT.deleteAddress}`, data);
};

export const setAsDefaultAddress = async (data) => {
  return await AxiosInstance.post(`${version}${API_END_POINT.defaultAddress}`,data);
};


export const getCityStateByPinCode = async (pincode) => {
  return await AxiosInstance.get(`${version}${API_END_POINT.cityStateByPinCode}?pincode=${pincode}`);
}


export const getStateList = async (countryId) => {
  return await AxiosInstance.get(`${version}${API_END_POINT.stateList}?countryID=${countryId}`);
}


export const getCityList = async (stateID) => {
  return await AxiosInstance.get(`${version}${API_END_POINT.cityList}?stateID=${stateID}`);
}


export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const version = 'v1';
export const IMAGE_BASE_URL = "https://017521-content-dev.s3.ap-south-1.amazonaws.com"
//export const GOOGLE_CLIENT_ID = '876198659295-fhs3bm3p842q6ltngi79k0c409r6m529.apps.googleusercontent.com'
//export const FACEBOOK_APP_ID = '930514504584520'
export const LANGUAGEID = 1

export const GOOGLE_CLIENT_ID = '363085826688-2injlogrkd72rjfnng30nqc1ojpru3ih.apps.googleusercontent.com'
export const FACEBOOK_APP_ID = '930514504584520'

export const RailId = {
  fresh_arrival:'04017cb5-222e-4508-9005-4c237e467554',
  spotlight : '54c6c680-ea8d-4650-a892-21ee06b7559d',
  artist: 'afd01d0e-6fd3-4312-9c91-416c779df8c0', 
  // hashtag:'6ed2c98a-454f-4eb4-ac53-6ebd49bc485a',
  trending_videos:'4f9894d9-6895-4351-a60c-47cd57ebdac3',
  behind_the_scene: '715a3131-678d-42c7-931c-1850eec2ae84',
  melodies_rewind : 'f315ecdc-8113-4916-a923-d6ab22ad1182',
  around_the_world:'1fe45062-7fcf-4991-9f72-faf544f5e31f'
  };

  export const contestStaticData = [
    
      {
        "id": 10,
        "sectionName": "ContestBanner",
        "title": "",
        "desc": "",
        "iconURL": null,
        "imageURL": null,
        "contestBanners": [
          {
            "text": "Banner1",
            "description": "Banner1",
            "imageUrl": "https://017526-content-dev.s3.ap-south-1.amazonaws.com/Contest/Desktop/ContestBanner.webp",
            "isActive": true,
            "isDeleted": false,
            "createdOn": "2023-12-11T05:25:38.6966667",
            "isRedirectLink": false,
            "redirectURL": null
          }
        ],
        "contestRewardsPools": [],
        "allContests": []
      },
      {
        "id": 11,
        "sectionName": "ContestRewardsPool",
        "title": "Exciting Rewards",
        "desc": "Turn your earned coins into fascinating rewards.",
        "iconURL": null,
        "imageURL": null,
        "contestBanners": [],
        "contestRewardsPools": [
          {
            "winnerRewardId": 4,
            "name": "Tomorrowland 2024",
            "description": "All expenses paid trip",
            "longDesc": "Win an all-expenses paid couples trip to the biggest music festival of the year - Tomorrowland Summer (July '24) in Belgium.",
            "imageUrl": "img08.webp",
            "iconUrl": "https://017526-content-dev.s3.ap-south-1.amazonaws.com/Rewards/Icons/Tomorrowland2024.svg",
            "noOfCoins": "",
            "displayOrder": 4,
            "canclaim": false,
            "isIdRequired": false,
            "isPassportRequired": true,
            "collectNow": true,
            "enterNow": false,
            "claimTimer": "00:00:00",
            "claimed": false,
            "watchVideo": false,
            "startQuiz": false
          },
          {
            "winnerRewardId": 3,
            "name": "Lollapalooza 2024",
            "description": "Tickets",
            "longDesc": "Win a ticket to Lollapalooza music festival (Sep '24) in Berlin.",
            "imageUrl": "img02.webp",
            "iconUrl": "https://017526-content-dev.s3.ap-south-1.amazonaws.com/Rewards/Icons/Lollapalooza2024.svg",
            "noOfCoins": "500",
            "displayOrder": 3,
            "canclaim": false,
            "isIdRequired": true,
            "isPassportRequired": false,
            "collectNow": true,
            "enterNow": false,
            "claimTimer": "00:00:00",
            "claimed": false,
            "watchVideo": false,
            "startQuiz": false
          },
          {
            "winnerRewardId": 2,
            "name": "iPhone 15 (128GB)",
            "description": "",
            "longDesc": "Grab this chance to win a brand-new iPhone with all the latest features.",
            "imageUrl": "img04.webp",
            "iconUrl": "https://017526-content-dev.s3.ap-south-1.amazonaws.com/Rewards/Icons/iPhone15_128GB.webp",
            "noOfCoins": "300",
            "displayOrder": 2,
            "canclaim": false,
            "isIdRequired": true,
            "isPassportRequired": false,
            "collectNow": true,
            "enterNow": false,
            "claimTimer": "00:00:00",
            "claimed": false,
            "watchVideo": false,
            "startQuiz": false
          },
          {
            "winnerRewardId": 1,
            "name": "JioSaavn Subscription",
            "description": "Redeem by 31st October, 2024",
            "longDesc": "Listen to all your favorite songs by redeeming  one month premium subscription to JioSaavn.",
            "imageUrl": "img05.webp",
            "iconUrl": "https://s3.ap-south-1.amazonaws.com/017073-cokestudio2.0-dev/rewards/Icons/jioSavan.webp",
            "noOfCoins": "40",
            "displayOrder": 1,
            "canclaim": false,
            "isIdRequired": false,
            "isPassportRequired": false,
            "collectNow": true,
            "enterNow": false,
            "claimTimer": "00:00:00",
            "claimed": false,
            "watchVideo": false,
            "startQuiz": false
          }
        ],
        "allContests": []
      },
      {
        "id": 12,
        "sectionName": "AllContests",
        "title": "How to Collect Coke<sup>&reg;</sup> Coins",
        "desc": "Earn coins as you complete Coke<sup>&reg;</sup> Studio conquests",
        "iconURL": null,
        "imageURL": null,
        "contestBanners": [],
        "contestRewardsPools": [],
        "allContests": [
          {
            "id": 12,
            "text": "Complete Profile",
            "description": "Bring Coke Studio to your college",
            "imageUrl": "https://s3.ap-south-1.amazonaws.com/017073-cokestudio2.0-dev/CokeCoins/complete-profile.svg",
            "winnerCoin": 10,
            "condition": "Post Completion",
            "isPublished": true,
            "publishedby": "418f13a5-b3b5-4642-b056-0a5079fd5f90",
            "isActive": true,
            "isDeleted": false,
            "createdBy": "418f13a5-b3b5-4642-b056-0a5079fd5f90",
            "createdOn": "2023-12-08T19:04:10.6866667",
            "lastModifiedBy": null,
            "lastModifiedOn": null,
            "isRedirectLink": true,
            "redirectURL": "/my_profile"
          },
          {
            "id": 4,
            "text": "Watch an exclusive Coke<sup>&reg;</sup> Studio video",
            "description": "Here the Best of Coke Studio",
            "imageUrl": "\nhttps://s3.ap-south-1.amazonaws.com/017073-cokestudio2.0-dev/CokeCoins/Group13919.svg",
            "winnerCoin": 2,
            "condition": "Per Week",
            "isPublished": true,
            "publishedby": "418f13a5-b3b5-4642-b056-0a5079fd5f90",
            "isActive": true,
            "isDeleted": false,
            "createdBy": "418f13a5-b3b5-4642-b056-0a5079fd5f90",
            "createdOn": "2023-12-11T05:25:30.21",
            "lastModifiedBy": null,
            "lastModifiedOn": null,
            "isRedirectLink": true,
            "redirectURL": "#coke_studio_treasures"
          },
          {
            "id": 7,
            "text": "Refer a Friend",
            "description": "Get a Friend Along",
            "imageUrl": "\nhttps://s3.ap-south-1.amazonaws.com/017073-cokestudio2.0-dev/CokeCoins/Group14139.svg",
            "winnerCoin": 4,
            "condition": "Per Successful Referral",
            "isPublished": true,
            "publishedby": "418f13a5-b3b5-4642-b056-0a5079fd5f90",
            "isActive": true,
            "isDeleted": false,
            "createdBy": "418f13a5-b3b5-4642-b056-0a5079fd5f90",
            "createdOn": "2023-12-11T05:25:30.21",
            "lastModifiedBy": null,
            "lastModifiedOn": null,
            "isRedirectLink": true,
            "redirectURL": "referral"
          },
          {
            "id": 10,
            "text": "Use Invite Code",
            "description": "Enter Your Refferal Code",
            "imageUrl": "https://s3.ap-south-1.amazonaws.com/017073-cokestudio2.0-dev/CokeCoins/use-invite.svg",
            "winnerCoin": 2,
            "condition": "Per User",
            "isPublished": true,
            "publishedby": "418f13a5-b3b5-4642-b056-0a5079fd5f90",
            "isActive": true,
            "isDeleted": false,
            "createdBy": "418f13a5-b3b5-4642-b056-0a5079fd5f90",
            "createdOn": "2023-12-11T05:25:30.21",
            "lastModifiedBy": null,
            "lastModifiedOn": null,
            "isRedirectLink": false,
            "redirectURL": null
          }
        ]
      }
    ]
  

  export const rewards = [
    {
      "totalCoinsEarned": 0,
      "currentBalance": 0,
      "lastRedeemedCoins": 0,
      "rewards": [
          {
              "rewardsId": 1,
              "rewardsName": "JioSaavn Subscription",
              "rewardsDescription": "Redeem by 31st October, 2024",
              "rewardsLongDesc": "Listen to all your favorite songs by redeeming  one month premium subscription to JioSaavn.",
              "noOfCoins": "40",
              "coinTypeId": 1,
              "coinTypeName": "General",
              "quotaTypeId": 1,
              "quotaTypeName": "PerWeek_JioSaavn",
              "iconURL": "https://s3.ap-south-1.amazonaws.com/017073-cokestudio2.0-dev/rewards/Icons/jioSavan.webp",
              "imageURL": "img05.webp",
              "canClaim": false,
              "rewardsDisplayOrder": 1,
              "isIdRequired": false,
              "isPassportRequired": false,
              "collectNow": true,
              "enterNow": false,
              "claimTimer": "00:00:00",
              "claimed": false,
              "watchVideo": false,
              "startQuiz": false,
              "displayMessage": "",
              "isPromocode": true,
              "type": "Bharat",
              "promoCode": null,
              "isRewardUnlocked": false,
              "quotaOver": false
          },
          {
              "rewardsId": 2,
              "rewardsName": "iPhone 15 (128GB)",
              "rewardsDescription": "",
              "rewardsLongDesc": "Grab this chance to win a brand-new iPhone with all the latest features.",
              "noOfCoins": "300",
              "coinTypeId": 1,
              "coinTypeName": "General",
              "quotaTypeId": 2,
              "quotaTypeName": "PerWeek_Iphone",
              "iconURL": "https://017526-content-dev.s3.ap-south-1.amazonaws.com/Rewards/Icons/iPhone15_128GB.webp",
              "imageURL": "img04.webp",
              "canClaim": false,
              "rewardsDisplayOrder": 2,
              "isIdRequired": true,
              "isPassportRequired": false,
              "collectNow": true,
              "enterNow": false,
              "claimTimer": "00:00:00",
              "claimed": false,
              "watchVideo": false,
              "startQuiz": false,
              "displayMessage": "",
              "isPromocode": false,
              "type": "Bharat",
              "promoCode": null,
              "isRewardUnlocked": false,
              "quotaOver": false
          },
          {
              "rewardsId": 3,
              "rewardsName": "Lollapalooza 2024",
              "rewardsDescription": "Tickets",
              "rewardsLongDesc": "Win a ticket to Lollapalooza music festival (Sep '24) in Berlin.",
              "noOfCoins": "500",
              "coinTypeId": 1,
              "coinTypeName": "General",
              "quotaTypeId": 3,
              "quotaTypeName": "PerWeek_Lollpalooza",
              "iconURL": "https://017526-content-dev.s3.ap-south-1.amazonaws.com/Rewards/Icons/Lollapalooza2024.svg",
              "imageURL": "img02.webp",
              "canClaim": false,
              "rewardsDisplayOrder": 3,
              "isIdRequired": true,
              "isPassportRequired": false,
              "collectNow": true,
              "enterNow": false,
              "claimTimer": "00:00:00",
              "claimed": false,
              "watchVideo": false,
              "startQuiz": false,
              "displayMessage": "",
              "isPromocode": false,
              "type": "Bharat",
              "promoCode": null,
              "isRewardUnlocked": false,
              "quotaOver": false
          },
          {
              "rewardsId": 4,
              "rewardsName": "Tomorrowland 2024",
              "rewardsDescription": "All expenses paid trip",
              "rewardsLongDesc": "Win an all-expenses paid couples trip to the biggest music festival of the year - Tomorrowland Summer (July '24) in Belgium.",
              "noOfCoins": "",
              "coinTypeId": 1,
              "coinTypeName": "General",
              "quotaTypeId": 4,
              "quotaTypeName": "Per3Months_Tomorrowland",
              "iconURL": "https://017526-content-dev.s3.ap-south-1.amazonaws.com/Rewards/Icons/Tomorrowland2024.svg",
              "imageURL": "img08.webp",
              "canClaim": false,
              "rewardsDisplayOrder": 4,
              "isIdRequired": false,
              "isPassportRequired": true,
              "collectNow": true,
              "enterNow": false,
              "claimTimer": "00:00:00",
              "claimed": false,
              "watchVideo": false,
              "startQuiz": false,
              "displayMessage": "",
              "isPromocode": false,
              "type": "Bharat",
              "promoCode": null,
              "isRewardUnlocked": false,
              "quotaOver": false
          }
      ]
  }
  ]
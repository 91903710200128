import React, { useEffect, useState } from 'react';
import { sendOtp } from '../../services';
import { useLocation } from 'react-router';
import useLoader from '../hooks/use-loader';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';
import { MESSAGES } from '../constants/validation-constants';
import useTranslation from '../hooks/use-translation';
import { getCDSPixelId } from '../../utils/helpers';

const ResendOTPTimer = ({ onSuccess, resetForm,gaClass }) => {
  const [timer, setTimer] = useState(30);
  const { state } = useLocation();
  const { toggleLoader } = useLoader();
  const { t } = useTranslation();

  useEffect(() => {
    const interval = setInterval(
      () => setTimer((prev) => (prev > 0 ? prev - 1 : 0)),
      1000
    );
    return () => clearInterval(interval);
  }, []);

  const resendOtp = async () => {
    // Handle form submission
    if (state && state.mobile) {
      toggleLoader(true);
      const hfSessionKey = await getCDSPixelId();
      try {
        const reqData = {
          mobileNo:state && String(state.mobile),
          HfSession: hfSessionKey,
          languageId: 1
        };
        const res = await sendOtp(reqData);
        if (res.status === 200) {
          setTimer(30);
          onSuccess();
          toast.success(res?.data?.data)
        }
      } catch (err) {
      } finally {
        toggleLoader(false);
      }
    } else {
      toast.error(MESSAGES.CANT_FETCH_MOBILE,{id: "resendOtp error"});
    }
  };

  const formatTime = (time) => {
    return time < 10 ? `0${time}` : time;
  }

  return (
    <div>
      {timer ? (
        <p style={{ fontSize: '1.2rem' }}>{t.auth.try_again_timer} {`00:${formatTime(timer)}`}</p>
      ) : (
        <span
          style={{ fontSize: '1.3rem' }}
          id={gaClass}
          className={`text-dark btn-underline ${gaClass}`}
          onClick={() => {
            resendOtp();
            resetForm();
          }}>
          {t.auth.resend_code}
        </span>
      )}
    </div>
  );
};

export default ResendOTPTimer;

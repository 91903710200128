import toast from "react-hot-toast";
import { getHomepageDetailsAfterLogin, getWalletData } from "../../services";
import { setHomePageDetails, setRewardDetails } from "../../store/slices/common-slice";
import { LANGUAGEID } from "../../config";
import { getCDSPixelId } from "../helpers";

/**
 * @method apiErrorHandler
 * @description handle api error
 */
export const apiErrorHandler = (res) => {
  if (res && res.message) {
    
    if(res?.response?.data?.message) {
      toast.error(res?.response?.data?.message, {id: "Error Message"})
    } else {
      toast.error(res.message,{id: "Error Message"})
    }
  } else {
    toast.error('Something went wrong please try again later',{id: "something went wrong err"})
  }
};

export const getRewardListAPI = async (dispatch, toggleLoader) => {
  toggleLoader(true);
  const hfSessionKey = await getCDSPixelId();
  const reqData = { hfSession: hfSessionKey || '', langid: 1 };
  try {
    const res = await getWalletData(reqData);
    if (res.status === 200) {
      let rewardList = res.data && res.data.data && res.data.data.rewards
      dispatch(setRewardDetails(rewardList))
    }
  } catch (err) {
  } finally {
    toggleLoader(false);
  }
};
//get all home page after login
export const getAllHomepageDetailsALogin = async (dispatch, toggleLoader) => {
  toggleLoader(true);
  try {
    let reqData = { languageId: LANGUAGEID };
    const res = await getHomepageDetailsAfterLogin(reqData);
    if (res.status === 200 && res.data.succeeded) {
      let section = res.data && res.data.data && res.data.data.sections
      let notificationCount = res.data && res.data.data && res.data.data.newNotification
      let coinCount = res.data && res.data.data && res.data.data.walletCoins
      const updatedPayload = {
        sections: [...section], // Replace with the actual updated array
        notificationCount: notificationCount,
        coinCount: coinCount,
      };

      dispatch(setHomePageDetails(updatedPayload));
    }
  } catch (err) {
  } finally {
    toggleLoader(false);
  }
};
export const referalSuccessContentType = (t,referralCode) => {
    console.log(referralCode,"referralCode")
    return {
      image: "/assets/img/modal/right-icon.svg",
      heading: t.model_messages.thank_you,
      subHeading: t.model_messages.an_invite_sent,
      referralCode: referralCode ? referralCode : "",
      timer: false,
      description: t.model_messages.your_referral_point_credited,
      id: 15
    };
  };
import React from 'react';
import SectionHeader from './SectionHeader';
import { useNavigate } from 'react-router';
import { useViewport } from '../../utils/context/viewport-context';
import Header from '../header';
import { useLocation } from 'react-router-dom';

const CommonSection = ({ children, ...props }) => {
  const { isMobile } = useViewport();
  const { pathname } = useLocation();
  const { headerContent = {}, headerType = '', sectionClass = '' } = props;
  const navigate = useNavigate();
  const isHeaderVisible = ['/login', '/signup', '/otp_verification', '/referralinvite'].includes(
    pathname
  );

  const { pageHeaderClass = '', headerClass = '', containerClass = '' } = props;
  return (
    <>
      {!isMobile && isHeaderVisible && headerType === 1 && <Header />}
      {isMobile && (
        <div className={`page-header ${pageHeaderClass}`}>

          {headerType !== 2 && (
            <span
              className="icon icon-back icon-Back-01"
              onClick={() => navigate(-1)}></span>
          )}
          {/* Enable for logo only  login */}
          {headerType === 1 && (
            <div className="page-header-bottom justify-content-center pt-0">
              <img
                className="page-header-logo page-header-logo-md"
                src="/assets/img/icons/header-logo.svg"
                alt="Logo"
                onClick={() => navigate('/')}
              />
            </div>
          )}
          {/* Enable for logo and close button  claim header*/}
          {headerType === 2 && (
            <div className="page-header-bottom">
              <div className='w-100 text-center'>
                <img
                  className="page-header-logo ml-5"
                  src="/assets/img/icons/header-logo.svg"
                  alt="Logo"
                  onClick={() => navigate('/')}
                />
              </div>
              <span
                className="icon-close icon-Cross-01 font-weight-bold"
                onClick={() => navigate(-1)}></span>
            </div>
          )}
          {/* Enable section header  */}
          {headerContent &&
            headerType === 3 &&
            Object.keys(headerContent).length > 0 && (
              <SectionHeader
                {...headerContent}
                headerClass={`pt-4 pb-0 ${headerClass}`}
                containerClass={'px-0'}
              />
            )}
        </div>
      )}

      {/* {!isMobile && ( */}
      <div className={`container-fluid ${containerClass}`}>
        <div className="row justify-content-center">
          <section className={`col-12 ${sectionClass}`}>{children}</section>
        </div>
      </div>

      {/* )} */}
    </>
  );
};

export default CommonSection;

import React from "react";
import { Button } from "react-bootstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import ProfileImageSelector from "../components/common/profile/profile-image-selector";
import OtpInput from "react-otp-input";
import ResendOTPTimer from "./helpers/Timer";
import AddressForm from "../components/common/appForm/AddressForm";
import useTranslation from "./hooks/use-translation";

import 'react-phone-number-input/style.css'
import Input, { getCountryCallingCode } from 'react-phone-number-input/input'
import PhoneInput from "react-phone-number-input/input";
import { handleInputLimitedLength, handleKeyPressMobileNumber, handleKeyPressName } from "./helpers";

const CommonFormikForm = ({
  initialValues,
  validationSchema,
  onSubmit,
  renderInputs,
  profileState,
  formClass,
  submitBtnClass = "",
  formFooterClass = "",
  ButtonText = "",
  renderSeparator,
  inputStyle,
  isDisplayEditIcon = true,
  gaClass,
  errorClass
}) => {
  const { t } = useTranslation();

  const handleSelectChange = (selectedValue, setFieldValue) => {
    setFieldValue('countryCode', selectedValue);
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({
        handleSubmit,
        handleChange,
        values,
        errors,
        setFieldValue,
        touched,
        getFieldProps,
        setFieldError,
        resetForm
      }) => (
        <Form autoComplete="off" className={formClass}>
          {console.error("errors123333333", errors)}
          {renderInputs.map((inputProps, index) => {
            return (
              <div
                key={index}
                className={`form-group ${inputProps.formGroupClass || ""} ${inputProps.type === "image"
                  ? "position-relative text-center "
                  : ""
                  }`}
              >
                {inputProps.label && (
                  <label htmlFor={inputProps.name}>{inputProps.label}</label>
                )}
                {inputProps.type === "text" && inputProps.name !== "mobileCode" && inputProps.name !== "name" && (
                  <>
                    <Field
                      type={inputProps.type}
                      name={inputProps.name}
                      className={`form-control ${touched[inputProps.name] && errors[inputProps.name]
                        ? "is-invalid"
                        : ""
                        }`}
                      placeHolder={inputProps.placeHolder}
                      disabled={inputProps.disabled ? true : false}
                      onInput={(e) => {
                        if (inputProps.name === 'UniqueCode') {
                          handleInputLimitedLength(e, 8, 8);
                        }
                      }}
                    //onBlur={() => inputProps.action(values[inputProps.name], setFieldError)}
                    />

                  </>
                )}
                {inputProps.type === "text" && inputProps.name == "name" &&
                    (
                      <>
                        <Field
                          type={inputProps.type}
                          name={inputProps.name}
                          className={`form-control ${touched[inputProps.name] && errors[inputProps.name]
                            ? "is-invalid"
                            : ""
                            }`}
                          maxLength={25}
                          placeHolder={inputProps.placeHolder}
                          disabled={inputProps.disabled ? true : false}
                          onInput={(e) => {
                            if (inputProps.name === 'mobile') {
                              handleKeyPressMobileNumber(e);
                            } else if (inputProps.name === 'UniqueCode') {
                              handleInputLimitedLength(e, 8, 8);
                            } else
                              if (inputProps.name === 'name') {
                                handleKeyPressName(e)
                              }
                          }}
                        //onBlur={() => inputProps.action(values[inputProps.name], setFieldError)}
                        />

                      </>
                    )}
                {inputProps.type === "email" && (
                  <Field
                    type={inputProps.type}
                    name={inputProps.name}
                    className={`form-control ${touched[inputProps.name] && errors[inputProps.name]
                      ? ""
                      : ""
                      }`}
                    placeHolder={inputProps.placeHolder}
                    disabled={inputProps.disabled ? true : false}
                  />
                )}
                {inputProps.type === "password" && (
                  <Field
                    type="password"
                    name={inputProps.name}
                    className={`form-control ${touched[inputProps.name] && errors[inputProps.name]
                      ? "is-invalid"
                      : ""
                      }`}
                    placeHolder={inputProps.placeHolder}
                    disabled={inputProps.disabled ? true : false}
                  />
                )}

                {inputProps.type === "date" && (
                  <>
                    <Field
                      type={inputProps.type}
                      name={inputProps.name}
                      className={`form-control form-date ${touched[inputProps.name] && errors[inputProps.name]
                        ? "is-invalid"
                        : ""
                        }`}
                      placeHolder={inputProps.placeHolder}
                      disabled={inputProps.disabled ? true : false}
                      value={values[inputProps.name]}
                      format="MM/DD/YYYY"
                    />
                    <span className="text-muted pl-4">Select your date of Birth*</span>
                  </>

                )}
                {inputProps.type === "select" && inputProps.name !== "countryCode" && (
                  <>
                    <Field
                      as="select"
                      name={inputProps.name}
                      className={`form-select form-custom-select ${touched[inputProps.name] && errors[inputProps.name]
                        ? "is-invalid"
                        : ""
                        }`}
                      placeHolder={inputProps.placeHolder}
                      value={values[inputProps.name]}
                      disabled={inputProps.disabled ? true : false}
                    >
                      <option value="">{inputProps.placeHolder}</option>
                      {inputProps.options.map((el, optionIndex) => (
                        <option key={optionIndex} value={el.value}>
                          {el.label}
                        </option>
                      ))}

                    </Field>
                    {inputProps?.name === "gender" && (
                      <span className="text-muted pl-4">Select Gender from dropdown*</span>
                    )}
                  </>
                )}
                {inputProps.type === "image" && (

                  <ProfileImageSelector
                    selected={values?.profileImage}
                    setFieldValue={setFieldValue}
                    error={values?.profileImage && profileState?.error?.message}
                    values={values}
                    isDisplayEditIcon={isDisplayEditIcon}
                    getFieldProps={getFieldProps}
                  // dropPosition="down-centered"
                  />
                )}

                {inputProps.type === "address" && (
                  <AddressForm
                    selected={values?.profileImage}
                    setFieldValue={setFieldValue}
                    getFieldProps={getFieldProps}
                    source={"profile"}
                  />
                )}

                {inputProps.type === "checkbox" && (
                  <>
                    <div className="d-flex mb-8">
                      <Field
                        id="tnc"
                        type="checkbox"
                        name={inputProps.name}
                        className="form-group form-check mr-2"
                        placeHolder={inputProps.placeHolder}
                      />
                      <label
                        className="small mb-1"
                        htmlFor="tnc"
                        dangerouslySetInnerHTML={{
                          __html: inputProps.heading,
                        }}
                      ></label>
                    </div>
                  </>
                )}
                {inputProps.type === "react-otp-input" && (
                  <>
                    <OtpInput
                      value={values.otp}
                      onChange={(e) => setFieldValue(inputProps.name, e)}
                      numInputs={6}
                      containerStyle="justify-content-center"
                      renderSeparator={renderSeparator}
                      renderInput={(props) => (
                        <input
                          {...props}
                          className={`form-control form-square ${touched[inputProps.name] && errors[inputProps.name]
                            ? "is-invalid"
                            : ""
                            }`}
                        />
                      )}
                      inputType="tel"
                    />

                  </>
                )}


                {inputProps.type === "text" && inputProps.name == "mobileCode" && (

                  <div className="mobile-input-wrapper">
                    <div className="form-control">
                      <span className="country-code">+880</span>
                      <Field
                        name={inputProps.name}
                        type={inputProps.type}
                        placeholder={inputProps.placeHolder}
                        className={`form-control ${touched[inputProps.name] && errors[inputProps.name]
                          ? "is-invalid"
                          : ""
                          }`}
                        maxLength={10}
                      // value={phone}
                      // onChange={handleMobileNumber}
                      />
                    </div>
                  </div>
                )}


                {inputProps.type === "tel" && inputProps.name == "mobileCode" && (
                  <div className="mobile-input-wrapper">
                    <div className="form-control">
                      <span className="country-code">+880</span>
                      <Field
                        name={inputProps.name}
                        type={inputProps.type}
                        placeholder={inputProps.placeHolder}
                        className={`form-control ${touched[inputProps.name] && errors[inputProps.name]
                          ? "is-invalid"
                          : ""
                          }`}
                        maxLength={10}
                      // value={phone}
                      // onChange={handleMobileNumber}
                      />
                    </div>
                  </div>
                )}
                {inputProps.type === "file" && (
                  <div className="form-control">
                    <input
                      name={inputProps.name}
                      type={inputProps.type}
                      placeholder={inputProps.placeHolder}
                      id={inputProps.inputId}
                      style={inputProps.style}
                      multiple={inputProps.multiple}
                    />
                  </div>
                )}
                {inputProps.name !== 'otp' && inputProps.name !== 'referalCode' && (
                  <ErrorMessage
                    name={inputProps.name}
                    component="div"
                    className={
                      touched[inputProps.name] && errors[inputProps.name]
                        ? `text-danger ${errorClass}`
                        : `text-danger ${errorClass}`
                    }
                  />
                )}

                {inputProps.type === "react-otp-input" && (
                  <div className="text-center">
                    {inputProps.name === 'otp' && (
                      <ErrorMessage
                        name={inputProps.name}
                        component="div"
                        className={
                          touched[inputProps.name] && errors[inputProps.name]
                            ? `text-danger`
                            : `text-danger`
                        }
                      />
                    )}
                    <div className="mt-5">
                      <ResendOTPTimer onSuccess={() => setFieldValue("otp", "")} resetForm={resetForm} gaClass={inputProps.gaClass} />
                    </div>
                  </div>
                )}
              </div>
            )
          })}
          {/* {errors.Message && (
            <div className={"text-danger"}>{errors.Message}</div>
          )} */}

          <div className={`form-footer ${formFooterClass}`}>
            <Button
              type="submit"
              variant="primary"
              className={`btn-block ${submitBtnClass} ${gaClass}`}
              id={gaClass}
            >
              {ButtonText ? ButtonText : t.common.submit}
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default CommonFormikForm;

import React from "react";
import CommonFormikForm from "../../../utils/form";
import useTranslation from "../../../utils/hooks/use-translation";
import { mobileNoValidationSchema, referCodeValidationSchema } from "../../../utils/validations";
import { sendReferralAPI, verifyRefCodeAPI } from "../../../services";
import useLoader from "../../../utils/hooks/use-loader";
import toast from "react-hot-toast";
import { getCDSPixelId, handleErrorMessages } from "../../../utils/helpers";
import AppModal from "../../modals";

const ReferAFriendForm = ({ getReferralList, type, show = false, onClose = {} }) => {

  const { t } = useTranslation();
  const { toggleLoader } = useLoader();
  console.log("translation", t);
  const initialValues = {
    mobile: "",
  };

  const initialValues2 = {
    code: "",
  };

  //handle submit button action 
  const handleSubmit = async (values, { setFieldError, resetForm }) => {
    // Handle form submission
    console.log(values, "values");
    toggleLoader(true);
    if (type === 'refer_code') {
      verifyRefer(values, setFieldError, resetForm)
    } else {
      sendRefer(values, setFieldError, resetForm)
    }

  };

  //send refer api call
  const sendRefer = async (values, setFieldError, resetForm) => {
    try {
      const hfSessionKey = await getCDSPixelId();
      let reqData = {
        referTo: values.mobile,
        hfSession: hfSessionKey || "",
      };
      const res = await sendReferralAPI(reqData);
      console.log(res, "resresres")
      if (res.data && res.data.succeeded) {
        //GA event added for successfully refer number
        window.dataLayer.push({ event: 'CSBharat_ReferFriend_Submit' })
        toast.success(t.messages.refer_success);
        resetForm();
        getReferralList();
        onClose()
      } else {
        handleErrorMessages(res.data, setFieldError);
      }
    } catch (err) {
      console.log("errrr", err)
    } finally {
      toggleLoader(false);
    }
  }

  //verify refer code api call
  const verifyRefer = async (values, setFieldError, resetForm) => {
    try {
      const hfSessionKey = await getCDSPixelId();
      let reqData = {
        refCode: values.code,
        hfSession: hfSessionKey || "",
      };
      const res = await verifyRefCodeAPI(reqData);
      if (res.data && res.data.succeeded) {
        //GA event added for successfully refer number
        window.dataLayer.push({ event: 'CSBharat_HaveInviteCode_Submit' })
        toast.success(t.messages.refer_code_success);
        resetForm();
        getReferralList();
        onClose()
      } else {
        let message = res.data && res.data.message
        setFieldError('code', message)
      }
    } catch (err) {
    } finally {
      toggleLoader(false);
    }
  }

  const renderInput = [
    { type: 'text', name: 'mobileCode', placeHolder: `${t.auth.mobile_number}*`, inputType: 'number', },
    { type: 'email', name: 'email', placeHolder: t.auth.email_id },
  ];

  const renderInput2 = [
    {
      type: "text",
      name: "code",
      placeHolder: "XXXXXX",
      inputType: "text",
      label: t.common.enter_referal_code
    },
  ];

  let referCodeValidation = referCodeValidationSchema(t)
  let mobileNoValidation = mobileNoValidationSchema(t)

  return (
    <>
      {type === 'refer_code' ? <AppModal
        show={show}
        onClose={onClose}
        bodyClass="px-5 py-9  modal-unique-code">
        <CommonFormikForm
          initialValues={initialValues2}
          onSubmit={handleSubmit}
          renderInputs={renderInput2}
          ButtonText="Submit"
          validationSchema={referCodeValidation}
        />
      </AppModal> : type === 'refer_number' ? <AppModal
        show={show}
        onClose={onClose}
        heading={t.referal.refer_a_friend}
        bodyClass="px-5 py-9  modal-unique-code">
        <CommonFormikForm
          initialValues={initialValues}
          onSubmit={handleSubmit}
          renderInputs={renderInput}
          ButtonText="Refer Now"
          validationSchema={mobileNoValidation}
        />
      </AppModal> : <CommonFormikForm
        formClass={"d-flex align-items-center"}
        initialValues={initialValues}
        onSubmit={handleSubmit}
        renderInputs={renderInput}
        ButtonText="Refer Now"
        submitBtnClass={"refer-now-btn"}
        validationSchema={mobileNoValidation}
      />}
    </>

  );
};

export default ReferAFriendForm;

import React from 'react';
import { Link } from 'react-router-dom';
import useTranslation from '../../utils/hooks/use-translation';
import { useViewport } from '../../utils/context/viewport-context';

function CommonFooter() {
    const { t } = useTranslation();
    const { isMobile } = useViewport();
    return (
        <>
            {/* Claim form footer code  */}
            {isMobile && <div className="row align-items-center mt-4 pt-4  footer-claim-form">
                <div className="col-5 mr-0">
                    <Link to="/terms_conditions" className="border-right pr-3 link">
                        {t.header.terms_and_condiotions}
                    </Link>
                    <Link to="/privacy_policy" className="pl-3 link">
                        {t.header.privacy_policy}
                    </Link>
                </div>
                <div className="col-7 text-right">
                    <p className="mb-0 text-nowrap">
                        {t.common.footer_heading}{' '}
                        <img src="/assets/img/icons/dietary_symbol.svg" alt="" />
                    </p>
                </div>
            </div>}
        </>
    );
}

export default CommonFooter;


import { AxiosInstance } from "../utils/axios-helper/axios-helper";
import { API_END_POINT } from "../utils/constants/api-end-points";
import { version } from "../config";


//get p
export const getProfile = async (data) => {
    return await AxiosInstance.get(`${API_END_POINT.getUserProfile}?userId=${data.userId}`);
};

export const getStates = async (data) => {
    return await AxiosInstance.get(`${version}${API_END_POINT.getStateList}?countryID=${data.countryID}`);
};

export const getCityBasedOnState = async (data) => {
    return await AxiosInstance.get(`${version}${API_END_POINT.getCityList}?stateID=${data.stateID}`);
};


export const updateUserProfile = async (data) => {
    return await AxiosInstance.post(API_END_POINT.updateProfile, data);
};

export const sendReferralAPI = async (data) => {
    return await AxiosInstance.post(`${version}${API_END_POINT.sendReferral}`, data);
}

export const resendReferral = async (data) => {
    return await AxiosInstance.post(`${version}${API_END_POINT.resendReferral}`, data);
}
export const getAllreferralAPI = async (data) => {
    return await AxiosInstance.post(`${version}${API_END_POINT.getReferral}?PageNumber=${data.PageNumber}&Pagesize=${data.Pagesize}`);
}

export const getPendingreferralAPI = async (data) => {
    return await AxiosInstance.post(`${version}${API_END_POINT.pendingReferalList}?PageNumber=${data.PageNumber}&Pagesize=${data.Pagesize}`);
}

export const verifyRefCodeAPI = async (data) => {
    return await AxiosInstance.post(`${version}${API_END_POINT.verifyRefCode}`,data);
}

export const getLeaderBoardLists = async (data) => {
    return await AxiosInstance.get(`${API_END_POINT.getLeaderBoardList}?pageNumber=${data.PageNumber}&pageSize=${data.PageSize}`);
};

export const socialMediaAuth = async (data) => {
    return await AxiosInstance.post(`${API_END_POINT.socialMedia}`, data);
};




// LanguageSwitcher.js
import React, { useState, useEffect } from 'react';
import useTranslation from '../../utils/hooks/use-translation';
import { useLocation } from 'react-router-dom';
import useAuth from '../../utils/hooks/use-auth';
import { LangCDP } from '../../utils/cds/cdsHelper';

function LanguageSwitcher() {
  const { currentLanguage, changeLanguage } = useTranslation();
  const [value, setvalue] = useState(currentLanguage);
  const { pathname } = useLocation();
  const { isLoggedIn, userDetails } = useAuth();
  let pathList = ['/login', '/signup', '/otp_verification', '/referralinvite'];
  let switchLayout = pathList.includes(pathname);
 
  const getClassByname = () => {
    let className = `en_global ${switchLayout || !isLoggedIn ? "login_journey" : ""}`;
    if (value === 'hi') {
      //for hindi
      className = 'hi_global';
    } else if (value === 'bn') {
      //for bangali
      className = 'bn_global';
    } else if (value === 'te') {
      // for tamil
      className = 'te_global';
    }
    return className;
  };

  useEffect(() => {
    // Set the className of the body element
    document.body.className = getClassByname()
    // Clean up the className when the component unmounts
    if (isLoggedIn) {
      document.body.className = '';
    } else if (!isLoggedIn && switchLayout) {
     
      document.body.className = 'login_journey';
    }
 
    // return () => {
    //   document.body.className = '';
    // };
 
  }, [value]);

  const handleLanguageChange = (value) => {
    if (value) {
      const newLanguage = value;
      changeLanguage(newLanguage);
      LangCDP(value)
      setvalue(value);
      // window.location.reload()
    } else {
      setvalue('');
    }
  };

  return (
    <select
      className="form-select form-select-language change_language"
      value={value}
      onChange={(event) => handleLanguageChange(event.target.value)}>
      <option value="EN">En</option>
      <option value="BA">Ba</option>
      {/* <option value="te">Te</option>
      <option value="bn">Bn</option> */}
    </select>
  );
}

export default LanguageSwitcher;

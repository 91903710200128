import { React, useState, useEffect, useRef } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  Container,
  Nav,
  Navbar,
  NavDropdown,
  Image,
  Dropdown,
} from "react-bootstrap";
import Sidebar from "../sidebar";
import LanguageSwitcher from "../common/languageSwitcher";
import useAuth from "../../utils/hooks/use-auth";
import UniqueCode from "../common/appForm/UniqueCode";
import Notifications from "../../pages/Notification";
import { logoutUser } from "../../store/slices/auth-slice";
import { useDispatch } from "react-redux";
import { useViewport } from "../../utils/context/viewport-context";
import { getAllHomepageDetailsALogin } from "../../utils/axios-helper/api-hander";
import { useSelector } from "react-redux";
import useLoader from "../../utils/hooks/use-loader";
import { menuData } from "../../utils/constants";
import useTranslation from "../../utils/hooks/use-translation";
import { gluedinLogout } from "../../services";
import usePopup from "../../utils/hooks/use-popup";
import { setAfterLoginAction } from "../../store/slices/common-slice";
import { base64ToImageSrc } from "../../utils/helpers";

const Header = () => {
  const { openReferFriendPopup } = usePopup();
  const { t } = useTranslation();
  let allMenuOptions = menuData(t);
  let exploreOption =
    allMenuOptions && allMenuOptions.find((el) => el.id === 3);
  const { isMobile } = useViewport();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { toggleLoader } = useLoader();
  const { isLoggedIn, userDetails } = useAuth();

  const [isExpanded, setIsExpanded] = useState(false);
  const navbarRef = useRef();
  const homePageData = useSelector((state) => state?.common?.homepageDetails);
  const notificationCount = homePageData && homePageData.notificationCount;
  const header_type = pathname.startsWith("/reels") && isMobile;
  console.log("pathname", pathname);

  useEffect(() => {
    if (!isMobile) {
      setIsExpanded(false);
    }
    // Add event listener to handle clicks outside the navbar
    document.addEventListener("click", handleClickOutside);
    return () => {
      // Clean up the event listener on component unmount
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isMobile]);

  const handleClickOutside = (event) => {
    if (navbarRef.current && !navbarRef.current.contains(event.target)) {
      setIsExpanded(false);
    }
  };
  const toggleNavbar = () => {
    setIsExpanded(!isExpanded);
  };

  const handleClick = () => {
    // Scroll to the top of the page
    window.scrollTo(0, 0);
    // Redirect to the home page
    navigate("/");
  };

  const logoutUserAction = () => {
    localStorage.clear();
    dispatch(logoutUser());
    dispatch(
      setAfterLoginAction({
        action: "",
        redirectPath: '/',
      })
    );
    gluedinLogout()
    navigate("/");
  };

  const navigateTo = (route) => {
    // Close the sidebar
    setIsExpanded(false);
    // Navigate to the specified route
    navigate(route);
  };

  const handlePopoverToggle = (isOpen) => {
    // isOpen will be true if the popover is open, false if closed
    if (isOpen) {
      getAllHomepageDetailsALogin(dispatch, toggleLoader);
    }
  };

  //handle login 
  const handleLogin = () => {
    navigate("/login")
    dispatch(setAfterLoginAction(
      {
        action: '',
        redirectPath: '/',
      }
    ))
  }
  return (
    <>
      {!header_type && (
        <header className="header">
          <Navbar
            expand="lg"
            variant={"null"}
            bg={"light"}
            expanded={isExpanded}
            ref={navbarRef}
          >
            <Container fluid>
              <Navbar.Toggle aria-controls="sidebar" onClick={toggleNavbar} />
              <Navbar.Brand href="/" onClick={handleClick}>
                <img
                  className="logo"
                  src="/assets/img/icons/header-logo.svg"
                  alt="Coca-Cola"
                />
              </Navbar.Brand>
              {/* Only  for Mobile  */}
              <Nav className="align-items-center navbar-right d-lg-none">
                {isLoggedIn && (
                  <div className="dropdown">
                    <a
                      href={void 0}
                      className="icon-notifi icon-Notification-01"
                      onClick={() => {
                        getAllHomepageDetailsALogin(dispatch, toggleLoader);
                        navigate("/notifications");
                      }}
                    >
                      {notificationCount > 0 && (
                        <span className="notification-count">
                          {notificationCount}
                        </span>
                      )}
                    </a>
                  </div>
                )}
                <LanguageSwitcher />
              </Nav>
              {/* Sidebar menu for mobile  */}
              <Navbar.Collapse
                id="sidebar"
                className="offcanvas-menu d-lg-none sidebar"
              >
                <Sidebar navigateTo={() => navigateTo()} />
              </Navbar.Collapse>
              {isExpanded && (
                <div className="offcanvas-backdrop" onClick={toggleNavbar}></div>
              )}
              {/* Only  for Desktop  */}
              <Navbar.Collapse className="d-none d-lg-block">
                <Nav className="me-auto navbar-left" as="ul">
                  <NavDropdown title={t.header.explore} as="li">
                    {/* {allMenuOptions &&
                  allMenuOptions[2].subMenu.map((el) => {
                    return (
                      <NavDropdown.Item href={el.url} onClick={handleClick}>
                        <span
                          dangerouslySetInnerHTML={{ __html: el.title }}
                          onClick={handleClick}
                        />
                      </NavDropdown.Item>
                    );
                  })} */}
                    {exploreOption &&
                      exploreOption?.subMenu?.map((el) => {
                        return (
                          <NavDropdown.Item
                            href={
                              !isLoggedIn && el?.authentication
                                ? `/login`
                                : el.url
                            }
                            onClick={handleClick}>
                            <span
                              dangerouslySetInnerHTML={{ __html: el.title }}
                              onClick={() => {
                                handleClick();
                                if (el.id === 7) {
                                  console.log('item.id', el.id);
                                  if (isLoggedIn) {
                                    openReferFriendPopup();
                                  } else {
                                    dispatch(
                                      setAfterLoginAction({
                                        action: openReferFriendPopup,
                                        redirectPath: '',
                                      })
                                    );
                                    navigate('/login')
                                  }
                                }
                              }}
                            />
                          </NavDropdown.Item>
                        );
                      })}
                  </NavDropdown>
                  {/* <Nav.Item as="li">
                    <Nav.Link href={isLoggedIn ? "/contest" : "/login"}
                      onClick={() => {
                        dispatch(
                          setAfterLoginAction({
                            action: '',
                            redirectPath: '/contest',
                          })
                        );
                      }}>

                      {t.header.participate_and_win}
                    </Nav.Link>
                  </Nav.Item> */}
                </Nav>
                <Nav className="ml-auto align-items-center navbar-right">
                  
                   {/* according to Bugsheet unique code needs to be disable  */}
                  {/* {isLoggedIn && <UniqueCode type={"input"} />} */}

                  <LanguageSwitcher />

                  {isLoggedIn && (
                    <Dropdown onToggle={handlePopoverToggle}>
                      <Dropdown.Toggle
                        id="dropdown-notification"
                        as="a"
                        className="icon-notifi icon-Notification-01"
                      >
                        {notificationCount > 0 && (
                          <span className="notification-count">
                            {notificationCount}
                          </span>
                        )}
                      </Dropdown.Toggle>
                      <Dropdown.Menu align={"end"} as="div">
                        <Notifications popover={true} />
                      </Dropdown.Menu>
                    </Dropdown>
                  )}



                  {isLoggedIn ? (
                    <>
                      <Dropdown>
                        <Dropdown.Toggle
                          id="dropdown-user"
                          as="a"
                          className="header-profile-pic avatar_icon"
                        >
                          <Image
                           src={
               
                               userDetails && userDetails.avatarURL
                                ? userDetails.avatarURL
                                : userDetails && userDetails.profilePicture
                                  ? base64ToImageSrc(userDetails.profilePicture, 'image/jpeg') // Adjust the content type as per your image type
                                  : '/assets/img/icons/default-user.svg'
                          }
                            alt=""
                          />
                        </Dropdown.Toggle>
                        <Dropdown.Menu align={"end"} as="div">
                          <Dropdown.Item href="/my_profile">
                            <span>{t.header.my_profile}</span>
                            <span className="icon icon-My-Profile-01"></span>
                          </Dropdown.Item>
                          <Dropdown.Item href="/my_wallet">
                            <span>{t.header.my_wallet}</span>
                            <span className="icon icon-My-Wallet-01"></span>
                          </Dropdown.Item>
                          <Dropdown.Item href="#" onClick={logoutUserAction}>
                            {t.header.logout}
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </>
                  ) : (
                    <Dropdown>
                      <Dropdown.Toggle
                        id="dropdown-user"
                        as="a"
                        className="header-profile-pic avatar_icon"
                      >
                        <Image
                 src={
               
                  userDetails && userDetails.avatarURL
                   ? userDetails.avatarURL
                   : userDetails && userDetails.profilePicture
                     ? base64ToImageSrc(userDetails.profilePicture, 'image/jpeg') // Adjust the content type as per your image type
                     : '/assets/img/icons/default-user.svg'
             }
                          alt=""
                        />
                      </Dropdown.Toggle>
                      <Dropdown.Menu align={"end"} as="div">
                        <Dropdown.Item
                          href="#"
                          onClick={handleLogin}
                        >
                          {t.header.login}
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  )}
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </header>
      )}

    </>
  );
};

export default Header;

import { AxiosInstance } from "../utils/axios-helper/axios-helper";
import { API_END_POINT } from "../utils/constants/api-end-points";
import { version } from "../config";

export const getWalletData = async () => {
  return await AxiosInstance.get(`${version}${API_END_POINT.getWalletData}`);
};

export const getRewardsHistory = async () => {
  return await AxiosInstance.get(
    `${version}${API_END_POINT.getRewardsHistory}`
  );
};

import { AxiosInstance } from "../utils/axios-helper/axios-helper";
import { API_END_POINT } from "../utils/constants/api-end-points";
import { version } from "../config";
import gluedin from 'gluedin';


const feedModule = new gluedin.GluedInFeedModule();
const authModule = new gluedin.GluedInAuthModule();

// song drop api integration
export const jioSawanCodeAPI = async (data) => {
  return await AxiosInstance.post(`${version}${API_END_POINT.jioSawan}`,data);
};

//get all the details of home page
export const getHomepageDetailsBeforeLogin = async (data) => {
  return await AxiosInstance.get(`${version}${API_END_POINT.homeDetails}?languageId=${data.languageId}`,);
};

//get all the details of home page
export const getHomepageDetailsAfterLogin = async (data) => {
  return await AxiosInstance.get(`${version}${API_END_POINT.homeDetailsAfterLogin}?languageId=${data.languageId}`,);
};

// song drop api integration
export const songDropAPI = async () => {
  return await AxiosInstance.get(`${version}${API_END_POINT.songDrop}`);
};

//get all the the behind the scene  
export const getAllBehindTheSceneDataAPI = async (data) => {
  return await AxiosInstance.get(`${version}${API_END_POINT.getAllBehindThescneData}?searchString=${data.searchString}&pageNumber=${data.pageNumber}&pageSize=${data.pageSize}`);
};

//get all the the melodies   
export const getAllMelodieDataAPI = async (data) => {
  return await AxiosInstance.get(`${version}${API_END_POINT.getAllMelodies}?searchString=${data.searchString}&pageNumber=${data.pageNumber}&pageSize=${data.pageSize}&seasonId=${data.seasonId}`,);
};

//get all treasures data  
export const getAllUGCDataAPI = async (data) => {
  return await AxiosInstance.get(`${version}${API_END_POINT.getAllUGC}?searchString=${data.searchString}&pageNumber=${data.pageNumber}&pageSize=${data.pageSize}&UGCType=${data.UGCType}`,);
};

//get all treasures data  
export const getAllTreasuresDataAPI = async (data) => {
  return await AxiosInstance.get(`${version}${API_END_POINT.getAllTreasures}?searchString=${data.searchString}&pageNumber=${data.pageNumber}&pageSize=${data.pageSize}`,);
};

//get all season list data  
export const getAllSeasionsList = async (data) => {
  return await AxiosInstance.get(`${version}${API_END_POINT.getAllTheSeasons}?searchString=${data.searchString}&pageNumber=${data.pageNumber}&pageSize=${data.pageSize}`,);
};

//call this api one user view vedio at the nd   
export const sentUserViewedVedio = async (data) => {
  return await AxiosInstance.post(`${version}${API_END_POINT.userViewedVedio}`, data);
};

// like vedio by id 
export const likeVedioById = async (data) => {
  return await AxiosInstance.post(`${version}${API_END_POINT.likeVedios}`, data);
};


// unicode api integration
export const mixCodeApi = async (data) => {
  return await AxiosInstance.post(`${version}${API_END_POINT.uniqueCode}`, data);
};

export const fetchFeed = async (railId,isLoggedIn) => {
  try {
    var curatedContentModuleObj = new gluedin.GluedInCuratedContentModule();
    var curatedContentModuleResponse =
      await curatedContentModuleObj.getRailById(railId);

    if (curatedContentModuleResponse.status === 200) {
      const curatedResponse = curatedContentModuleResponse.data.result;

      if (
        curatedResponse &&
        curatedResponse.itemList &&
        curatedResponse.itemList.length
      ) {
        const feedArray = curatedResponse.itemList.map((contentInfo) => ({
          ...contentInfo.video,
        }));
        let accessToken= ''
        let likedVideos = [];
        if(isLoggedIn){
          try {
            accessToken = authModule.getAccessToken();
          }catch (error){
            //localStorage.clear()
          }
          if (accessToken) {
            let likedVideoIds = feedArray.map((item) => item.videoId);
            if (likedVideoIds && likedVideoIds.length > 0) {
              let likeVideosResponse = await feedModule.Like(likedVideoIds);
              if (
                likeVideosResponse.status === 200 ||
                likeVideosResponse.status === 201
              ) {
                likedVideos = likeVideosResponse.data.result;
              }
            }else {
              likedVideos = []
            }
          }
        }
        
        // Merge curatedResponse and likedVideos into a single object
        const combinedResponse = { ...curatedResponse, likedVideos: likedVideos };
        return combinedResponse;

      } else {
        throw new Error('Failed to fetch feed for rail ID: ' + railId);
      }
    }
  } catch (error) {
    console.error('Error fetching feed:', error);
   
    throw error;
  }
};

export const fetchFeedsForRailIds = async (railIds,isLoggedIn) => {
  try {
    const feedPromises = railIds.map(async (railId) => {
      try {
        return await fetchFeed(railId, isLoggedIn);
      } catch (error) {
        console.error(`Error fetching feed for rail ID ${railId}:`, error);
        // Return a default value or null to indicate failure for this specific rail ID
        return null;
      }
    });

    const feeds = await Promise.all(feedPromises);

    // Filter out null values indicating failed API calls
    const successfulFeeds = feeds.filter(feed => feed !== null);

    return successfulFeeds;
  } catch (error) {
    console.error('Error fetching feeds for rail IDs:', error);
    // Return an empty array or any default value to indicate failure
    return [];
  }
};









import React, { useState, useEffect } from 'react';
import CommonSection from '../components/common/CommonSection';
import SectionHeader from '../components/common/SectionHeader';
import CommonTable from '../components/common/Table';
import { getAllreferralAPI, getLeaderBoardLists } from '../services';

const CreatorLeaderBoard = (props) => {
  const [page, setPage] = useState(1);
  const [pageSize] = useState(5);
  const [referralList, setReferralList] = useState([]);
  const [allreferralData, setAllReferralData] = useState([]);
  const [leaderboarddata, setLeaderboardData] = useState([]);

  const headerContent = {
    title: 'Creator Leaderboard',
    subTitle: 'Lifetime Uploads and Likes Earned',
    // image: '/assets/img/icons/leaderboard-icon.svg',
    linkText:`${props.linkText ? "See All" : '' }`,
    titleClass: 'text-left text-md-center',
    link: '/creator_leaderboard',
  };
 

  useEffect(() => {
    getReferralList(page);
    getLeaderBoardData()
  }, [page, pageSize]);



  const getReferralList = async (page) => {
    try {
      let reqData = {
        PageNumber: page,
        Pagesize: 5,
      };
      const res = await getAllreferralAPI(reqData);
      if (res.status === 200) {
        let data =
          res.data &&
          res.data.data &&
          Array.isArray(res.data.data.data) &&
          res.data.data.data.length
            ? res.data.data.data
            : [];
        setAllReferralData(res.data.data);
        setReferralList(data);
      }
    } catch (err) {}
  };

  const getLeaderBoardData = async () => {
    try {
      let reqData = {
        PageNumber : page,
        PageSize : 5
      };
      const res = await getLeaderBoardLists(reqData);
      if(res.status === 200){
        let data = res.data && res.data.data && Array.isArray(res.data.data.data) && res.data.data.data.length ? res.data.data.data
        : [];
        setLeaderboardData(data)
      }
    } catch (err) {}
  }

  const loadMore = async () => {
    setPage(page + 1);
    try {
      let reqData = {
        PageNumber: page + 1,
        Pagesize: 5,
      };
      const res = await getAllreferralAPI(reqData);
      if (res.status === 200) {
        let data =
          res.data &&
          res.data.data &&
          Array.isArray(res.data.data.data) &&
          res.data.data.data.length
            ? res.data.data.data
            : [];
        setAllReferralData(res.data.data);
        setReferralList([...referralList, ...data]);
      }
    } catch (err) {}
  };

    
    const staticData = [
      { id: 1, name: 'Sonu Sharma', rank: 20, uploads: 21,likes:'5.1K', IsAccept: true },
      { id: 2, name: 'Sonu Sharma', rank: 20, uploads: 21,likes:'5.1K', IsAccept: true },
      { id: 3, name: 'Sonu Sharma', rank: 20, uploads: 21,likes:'5.1K', IsAccept: true },
    
    ];

  const renderReferralsInfo = () => {
    const header = true;
    const headerBackgroundColor = 'black';
    const headerFontColor = 'white';
    const referrals =
      referralList &&
      referralList.length &&
      referralList.map((obj, index) => ({
        ...obj,
        s_no: (page - 1) * pageSize + index + 1,
        status: obj.IsAccept ? 'Active' : 'Pending',
      }));
    const columns = ['Names', 'Rank', 'Uploads', 'Likes'];
    const columnName = ['name', 'rank','uploads', 'likes'];
    const tableClassName = 'custom-table mb-8 text-center profile-table';

    return (
      <>
        <CommonTable
          header={header}
          headerBackgroundColor={headerBackgroundColor}
          headerFontColor={headerFontColor}
          rows={staticData}
          columns={columns}
          tableClassName={tableClassName}
          columnName={columnName}
          loadMore={loadMore}
          totalCount={allreferralData && allreferralData.totalCount}
          setPage={setPage}
          page={page}
          pageSize={pageSize}
          showImage={true}
          showPagination={props.hidePagination}
        />
      </>
    );
  };

  return (
    <>
      <CommonSection
        headerContent={headerContent}
        headerType={3}
        pageHeaderClass={props.pageHeaderClass ? 'd-none' : ''}
        sectionClass='col-md-8'
        containerClass="mt-3 mt-md-15">
        <SectionHeader
          {...headerContent}
          headerClass={props.headerClass ? `${props.headerClass}` : `d-none d-md-block pt-0 pb-6`}
          titleClass={props.titleClass}
          subTitleClass={props.titleClass}
        />
      </CommonSection>
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="col-12 col-md-8">{renderReferralsInfo()}</div>
        </div>
      </div>
    </>
  );
};

export default CreatorLeaderBoard;

import React, { useState, useEffect } from 'react';
import { ListGroup } from 'react-bootstrap';
import {
  getNotificationsAPI,
  deleteNotiById,
  readNotificationByIdAPI,
  deleteAllNotifications,
} from '../services';
import useLoader from '../utils/hooks/use-loader';
import CommonSection from '../components/common/CommonSection';
import SectionHeader from '../components/common/SectionHeader';
import useTranslation from '../utils/hooks/use-translation';

const Notifications = (props) => {
  const dummyNotifications = [
    {
      id: 1,
      notificationTitle: "New message received",
      notificationText: "You have received a new message from John Doe.",
      isRead: false,
    },
    {
      id: 2,
      notificationTitle: "Reminder: Meeting tomorrow",
      notificationText: "Don't forget about the meeting scheduled for tomorrow at 10 AM.",
      isRead: true,
    },
    {
      id: 3,
      notificationTitle: "Project deadline approaching",
      notificationText: "The deadline for Project X is fast approaching. Please ensure all tasks are completed on time.",
      isRead: false,
    },
    // Add more dummy notifications as needed
  ];


  const { t } = useTranslation();
  const { popover = false } = props;
  const { toggleLoader } = useLoader();

  const [notifications, setNotifications] = useState(dummyNotifications);
  const [allData, setAllData] = useState('');
  const [page, setPage] = useState(1);

 
  

  useEffect(() => {
    getNotificationList(1, false);
  }, []);

  const getNotificationList = async (page, isPaginate) => {
    toggleLoader(true);
    try {
      let reqData = {
        PageNumber: page,
        PageSize: 10,
      };
      const res = await getNotificationsAPI(reqData);
      if (res.status && res.data.succeeded) {
        let data =
          res.data &&
          res.data.data &&
          Array.isArray(res.data.data.data) &&
          res.data.data.data.length
            ? res.data.data.data
            : [];
        setAllData(res.data.data);
        if (isPaginate) {
          setNotifications([...notifications, data]);
        } else {
          setNotifications(data);
        }
      }
    } catch (err) {
    } finally {
      toggleLoader(false);
    }
  };

  const loadMore = () => {
    setPage(page + 1);
    getNotificationList(page + 1, true);
  };

  const handleDeleteNotification = async (id) => {
    try {
      let reqData = {
        notificationId: id,
      };
      const res = await deleteNotiById(reqData);
      if (res.status && res.data.succeeded) {
        getNotificationList(page, false);
      }
    } catch (err) {}
  };

  const handleDeleteAllNotification = async () => {
    try {
      const res = await deleteAllNotifications();
      if (res.status && res.data.succeeded) {
        getNotificationList(page, false);
      }
    } catch (err) {}
  };

  const readNotification = async (id) => {
    try {
      let reqData = {
        notificationId: id,
      };
      const res = await readNotificationByIdAPI(reqData);
      if (res.status && res.data.succeeded) {
        getNotificationList(page, false);
      }
    } catch (err) {}
  };

  const renderNotificationList = (list) => {
   if (list && Array.isArray(list) && list.length) {
      return list.map((notification, index) => {
        let style = list.isRead
          ? { backgroundColor: '#FFF', cursor: 'pointer' }
          : { backgroundColor: '#F1F2F1', cursor: 'pointer' };
        return (
          <ListGroup.Item key={index} className="active px-2" style={style}>
            <div className="list-group-media-wrapper pb-3">
              <figure
                className="list-group-media"
                onClick={() => readNotification(notification.id)}>
                <img
                  src={'/assets/img/placeholder/vedio-card1.png'}
                  alt="Generic placeholder"
                />
              </figure>
              <div className="list-group-media-body">
                <div className="d-flex w-100 justify-content-between">
                  <h5 className="list-group-item-title">
                    {notification.notificationTitle}
                  </h5>
                  <span
                    className="icon icon-close icon-Cross-01 ml-5"
                    onClick={() =>
                      handleDeleteNotification(notification.id)
                    }></span>
                </div>
                <p className="list-group-item-disc text-truncate">
                  {notification.notificationText}
                </p>
                <div className="d-flex w-100 justify-content-between mt-2">
                  <span className="text-muted">2 h</span>
                  <strong>05d left</strong>
                </div>
              </div>
            </div>
          </ListGroup.Item>
        );
      });
    }
  };

  const headerContent = {
    title: t.notification.notification_header,
    subTitle: t.notification.notification_subheader,
    linkText: t.common.clear_all,
    titleClass:'text-left',
    action: (() => {
      return handleDeleteAllNotification;
    })(),
  };

  let list = notifications && notifications;

  const renderNotificationContent = () => {
    let totalCount = allData && allData.totalCount;
    let currentNotificationCount =
      notifications && Array.isArray(notifications) ? notifications.length : 0;
    return (
      <CommonSection
        headerContent={headerContent}
        headerType={3}
        sectionClass="col-md-10 col-lg-8 px-0"
        >
        <SectionHeader
          {...headerContent}
          headerClass={`d-none d-md-block pt-0 pb-12`}
          titleClass={`s-title-small`}
          subTitleClass={`s-sub-title-small`}
        />
        {renderNotificationList(list)}
        {currentNotificationCount < totalCount && (
          <div
            className="btn-link mt-3"
            onClick={loadMore}
            style={{ textAlign: 'center' }}>
            {t.common.load_more}
          </div>
        )}
      </CommonSection>
    );
  };

  const renderNoNotificationFoundContent = () => {
    return (
      <div className="xy-center block-middle no-notification">
        <span className="icon-notifi icon-Notification-01"></span>
        <h1 className="title">{t.notification.no_notification}</h1>
      </div>
    );
  };

  return (
    <>
      {popover ? (
        <ListGroup>
          {list && Array.isArray(list) && list.length !== 0 ? (
            <div>
              <div className="d-flex justify-content-between p-md-4">
                <h1 className="title">Notifications</h1>
                <div className="btn-link" onClick={handleDeleteAllNotification}>
                  {t.common.clear_all}
                </div>
              </div>
              {renderNotificationList(list)}
            </div>
          ) : (
            <h4 className="title text-nowrap p-4">
              {t.notification.notification_not_found}
            </h4>
          )}
        </ListGroup>
      ) : list && Array.isArray(list) && list.length !== 0 ? (
        <ListGroup>{renderNotificationContent(list)}</ListGroup>
      ) : (
        renderNoNotificationFoundContent()
      )}
    </>
  );
};

export default Notifications;

import React, { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import useLoader from '../../utils/hooks/use-loader';
import { getProfile, getAvatarList, gluedinLogin } from '../../services';
import { useDispatch } from 'react-redux';
import { setUser } from '../../store/slices/auth-slice';
import { setAvatarList } from '../../store/slices/common-slice';
import { useViewport } from '../../utils/context/viewport-context';
import useAuth from '../../utils/hooks/use-auth';

function PublicLayout() {
  const { pathname, hash } = useLocation();
  const { toggleLoader } = useLoader();
  const dispatch = useDispatch();
  const { isMobile } = useViewport();
  const { isLoggedIn, userDetails } = useAuth();
  let pathList = ['/login', '/signup', '/otp_verification', '/referalinvite'];
  let switchLayout = pathList.includes(pathname);
  console.log("pathname", pathname)
  const path_type = pathname.startsWith('/reels')

  console.log("")

  const isHeaderFooterNotVisible = [
    '/faq',
    '/terms_conditions',
    '/privacy_policy',
    '/my_profile/1',
    '/see_all/vedio_card/coke_studio_treasures',
    '/see_all/vedio_card/coke_studio_heads',
    '/see_all/vedio_card/behind_the_scene',
    '/see_all/vedio_card/melodies_rewind',
  ].includes(pathname);
  const onlyFooter =
    pathname === '/artist' || pathname.startsWith('/artist_details');

  // handling all the hash routing
  useEffect(() => {
    if (hash) {
      setTimeout(() => {
        const el = document.getElementById(hash?.replace('#', '')?.trim());
        el?.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'nearest',
        });
      }, 200);
    }
  }, [hash]);

  useEffect(() => {
 
    if (isLoggedIn) {
      document.body.className = '';
    } else if (!isLoggedIn && switchLayout) {
     
      document.body.className = 'login_journey';
    } else if(!isLoggedIn && !switchLayout) {
      document.body.className = '';
    }
 
    // if (pathList.includes(pathname) && !isLoggedIn) {
    //   // Set the className of the body element
    //   document.body.className = 'login_journey';
    //   // Clean up the className when the component unmounts
    //   // return () => {
    //   //   document.body.className = 'login_journey';
    //   // };
    // }
   
   
  }, [switchLayout]);

  useEffect(() => {
    getAvatarListDetails();
    if (isLoggedIn) {
      getUserDetails();
    }
    if (!hash) {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
  }, [pathname]);

  const getAvatarListDetails = async () => {
    toggleLoader(true);
    try {
      const res = await getAvatarList();
      if (res.data && res.data.succeeded) {
        let data =
          res.data &&
          res.data.data &&
          Array.isArray(res.data.data) &&
          res.data.data;
        dispatch(setAvatarList(data));
      }
    } catch (err) {
    } finally {
      toggleLoader(false);
    }
  };

  const getUserDetails = async () => {
    toggleLoader(true);
    try {
      let reqData = {
        userId: userDetails && userDetails.id,
      };
      const res = await getProfile(reqData);
      if (res.status === 200) {
        let data = res.data && res.data;
        dispatch(setUser(data));
        let formData = {
          email: `${userDetails && userDetails.id}@gmail.com`,
          password: userDetails && userDetails.id,
        }
        gluedinLogin(formData)
      }
    } catch (err) {
    } finally {
      toggleLoader(false);
    }
  };

  if (switchLayout) {
    return (
      <>
        <main role="main">
          <Outlet />
        </main>
      </>
    );
  } else {
    return (
      <>
        {(isMobile && isHeaderFooterNotVisible) ||
        pathname === '/connection_loss' ? (
          <main role="main">
            <Outlet />
          </main>
        ) : isMobile && onlyFooter ? (
          <>
            <main role="main">
              <Outlet />
            </main>
            <Footer />
          </>
        ) : (
          <>
            <Header />
            <main role='main' className={path_type && 'reel-page'}>
              <Outlet />
            </main>
            <Footer />
          </>
        )}
      </>
    );
  }
}

export default PublicLayout;

export const REGEX = {
  name: /^[A-Za-z]+(?: [A-Za-z]+)*$/, // allow only alphabets and single space in between
  nameWithSpace : /^ *[A-Za-z]+(?: +[A-Za-z]+)* *$/ , // allows space at begging and end also and middle
  mobile: /^[0-9]{10}$/, // allow only 10 digits start with 6-9
  // mobile: /^\+880\d{10}$/,
  Code: /^[A-Za-z0-9]{8}$/, // allow alphanumeric of 10 char only
  otp: /^[0-9]{6}$/, // allow 6 digit number only
  PinCode: /^[1-9][0-9]{5}$/, // allow 6 digit number only
  // email: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, // general email check
  email: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3}$/,
  Address: /^[a-zA-Z0-9\s,.]*$/,
  Passport:/^[A-PR-WY][1-9]\d\s?\d{4}[1-9]$/,
  Govid:/^[A-Z]{5}[0-9]{4}[A-Z]{1}$/,
  mobileWithCountryCode: /^\+880\d{10}$/, 
};

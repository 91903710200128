import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  Navigate
} from 'react-router-dom';
import { lazy, Suspense } from 'react';
import FullScreenLoader from '../components/loader';
import useAuth from '../utils/hooks/use-auth';
import { useLocation } from 'react-router-dom';
import CollegeMinicert from '../pages/CollegeMinicert';
import CollegeMinicertLeaderBoard from '../pages/CollegeMinicertLeaderBoard';
import CreatorLeaderBoard from '../pages/CreatorLeaderBoard';
import { useSelector } from 'react-redux';


// Lazy load components with React.lazy
const HomePage = lazy(() => import('../pages/Home'));
const App = lazy(() => import('../App'));
const Page404 = lazy(() => import('../pages/404'));
const ConnectionLoss = lazy(() => import('../pages/ConnectionLoss'));
const Login = lazy(() => import('../features/auth/Login'));
const SignUp = lazy(() => import('../features/auth/SignUp'));
const ReferralInvite = lazy(() => import('../features/auth/ReferralInvite'))
const OtpVerification = lazy(() => import('../features/auth/OtpVerification'));
const MyProfile = lazy(() => import('../pages/MyProfile'));
const MyWallet = lazy(() => import('../pages/Wallet'));
const Notification = lazy(() => import('../pages/Notification'));
const Faq = lazy(() => import('../pages/Faq'));
const Contest = lazy(() => import('../pages/Contest'));
const TermsCondition = lazy(() => import('../pages/TermsConditions'));
const PrivacyPolicy = lazy(() => import('../pages/PrivacyPolicy'));
const AuthAccess = lazy(() => import('../pages/AuthAccess'));
const Artist = lazy(() => import('../pages/Artist'));
const ArtistDetails = lazy(() => import('../pages/ArtistDetail'));
const SeeAll = lazy(() => import('../pages/SeeAll'));
const Sitemap = lazy(() => import('../pages/Sitemap'));
const ClaimForm = lazy(() => import('../components/common/appForm/ClaimForm'));
const Referral = lazy(() => import('../pages/ReferaFriend'));
const SendReminder = lazy(() => import('../pages/SendReminder'));
const SiteMap = lazy(() => import('../pages/Sitemap'));
const Leaderboard = lazy(() => import('../pages/LeaderBoard'))
const Popup = lazy(() => import('../pages/popup'));
const UGCVedios = lazy(() => import('../pages/UgcVedios'));
const Reels = lazy(() => import('../pages/Reels'));
const UgcUpload = lazy(() => import('../pages/UgcUpload'))

// Define a fallback for lazy loading
const loaderComponent = (
  <div>
    <FullScreenLoader />
  </div>
);

// Create a wrapper component for lazy loaded components
function LazyRoute({ component: Component }) {
  return (
    <Suspense fallback={loaderComponent}>
      <Component />
    </Suspense>
  );
}

function ProtectedRoute({ component: Component }) {
  const { isLoggedIn } = useAuth();
  if (!isLoggedIn) {
    // Redirect to login if not authenticated
    //window.location.assign('/login')
    return <Navigate to="/login" />;
  }
  return <Component />;
}

function PublicOnlyRoute({ component: Component }) {
  const afterLoginParams = useSelector(
    (state) => state?.common?.afterLoginAction
  );

  const { isLoggedIn } = useAuth();
  const { state, pathname } = useLocation();
  if (isLoggedIn) {
    if (afterLoginParams && afterLoginParams.redirectPath) {
      return <Navigate to={afterLoginParams.redirectPath} />;

    } else if (afterLoginParams && afterLoginParams.action) {
      afterLoginParams.action();
    }
  }

  if (isLoggedIn) {
    // Redirect to home page if authenticated
    return <Navigate to="/" />;
  }
  if ((pathname === '/signup' || pathname === '/otp_verification') && (!(state && state.mobile))) {
    return <Navigate to="/login" />;
  }

  return <Component />;
}



const renderRoute = ({ element, ...props }) => {
  // Check if the route requires authentication
  if (props.requiresAuth) {
    return <ProtectedRoute component={() => element} />;
  }
  return <LazyRoute component={() => element} />;
};


export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route
      path="/"
      element={<LazyRoute component={App} />}
      errorElement={<LazyRoute component={Page404} />}
    >
      {/* without login  */}
      <Route path="/" element={<LazyRoute component={HomePage} />} />
      <Route path="/connection_loss" element={<LazyRoute component={ConnectionLoss} />} />
      <Route
        path="/login"
        element={<PublicOnlyRoute component={() => <LazyRoute component={Login} />} />}
      />
      <Route
        path="/signup"
        element={<PublicOnlyRoute component={() => <LazyRoute component={SignUp} />} />}
      />
      <Route
        path="/referralinvite"
        element={<PublicOnlyRoute component={() => <LazyRoute component={ReferralInvite} />} />}
      />
      <Route
        path="/auth_access/:key"
        element={<PublicOnlyRoute component={() => <LazyRoute component={AuthAccess} />} />}
      />
      <Route
        path="/otp_verification"
        element={<PublicOnlyRoute component={() => <LazyRoute component={OtpVerification} />} />}
      />

      <Route
        path="/artist"
        element={renderRoute({ element: <Artist />, requiresAuth: false })}
      />
      <Route
        path="/see_all/:cardtype/:section"
        element={renderRoute({ element: <SeeAll />, requiresAuth: false })}
      />
      <Route
        path="/artist_details/:artistId"
        element={renderRoute({ element: <ArtistDetails />, requiresAuth: false })}
      />

      {/* without login  and with login both can access */}

      <Route path="/faq" element={<LazyRoute component={Faq} />} />
      <Route path="/sitemap" element={<LazyRoute component={Sitemap} />} />
      <Route
        path="/terms_conditions"
        element={<LazyRoute component={TermsCondition} />}
      />
      <Route
        path="/privacy_policy"
        element={<LazyRoute component={PrivacyPolicy} />}
      />

      <Route
        path="/popup"
        element={<LazyRoute component={Popup} />}
      />

      {/* <Route
        path="/ugc/:cardtype/:section"
        element={<LazyRoute component={UGCVedios} />}
      /> */}

      <Route
        path="/site_map"
        element={<LazyRoute component={SiteMap} />}
      />

      <Route
        path="/reels/:reel_id"
        element={<LazyRoute component={Reels} />}
      />

      {/* with login  */}
      {/* ugc  */}
      <Route
        path="/ugc/:cardtype/:section"
        element={renderRoute({ element: <UGCVedios />, requiresAuth: false })}
      />

      <Route
        path="/ugc/upload"
        element={renderRoute({ element: <UgcUpload />, requiresAuth: true })}
      />

      <Route
        path="/notifications"
        element={renderRoute({ element: <Notification />, requiresAuth: true })}
      />

      {/* <Route
        path="/contest"
        element={renderRoute({ element: <Contest />, requiresAuth: true })}
      /> */}

      <Route
        path="/my_profile"
        element={renderRoute({ element: <MyProfile />, requiresAuth: true })}
      />
      <Route
        path="/college_minicert"
        element={renderRoute({ element: <CollegeMinicert />, requiresAuth: true })}
      />
      <Route
        path="/my_profile/:id"
        element={renderRoute({ element: <MyProfile />, requiresAuth: true })}
      />
      <Route
        path="/my_wallet"
        element={renderRoute({ element: <MyWallet />, requiresAuth: true })}
      />
      <Route
        path="/claim_form/:id"
        element={renderRoute({ element: <ClaimForm />, requiresAuth: true })}
      />
      <Route
        path="/referral"
        element={renderRoute({ element: <Referral />, requiresAuth: true })}
      />
      <Route
        path="/send-reminder"
        element={renderRoute({ element: <SendReminder />, requiresAuth: true })}
      />

      <Route
        path="/leaderboard"
        element={renderRoute({ element: <Leaderboard />, requiresAuth: true })}
      />
      <Route
        path="/college_minicert_leaderboard"
        element={renderRoute({ element: <CollegeMinicertLeaderBoard />, requiresAuth: true })}
      />
      <Route
        path="/creator_leaderboard"
        element={renderRoute({ element: <CreatorLeaderBoard />, requiresAuth: true })}
      />

    </Route>
  )
);

import React, { useEffect, useRef, useState } from 'react';
import { Button, Dropdown, Form } from 'react-bootstrap';
import { useSelector } from 'react-redux/es/hooks/useSelector';
import useAuth from '../../../utils/hooks/use-auth';
import Dropzone from '../../dropzone';
import { base64ToImageSrc } from '../../../utils/helpers';

const EDIT_PROFILE_OPTION = [
  {
    option: 'Choose avatar',
    value: 'chooseAvatar',
  },
  {
    option: 'Choose from gallery',
    value: 'chooseFromGallery',
  },
];

function ProfileImageSelector({ selected = '', setFieldValue }) {
  const { userDetails } = useAuth();
  const fileInputRef = useRef(null);
  const avatarList = useSelector((state) => state?.common?.avatarList);
  const avatarDataList = Object.values(avatarList);
  const [open, setOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState({});
  const [showAvatars, setShowAvatars] = useState(false);
  const [showWebCam, setShowWebCam] = useState(false);
  const [showChooseFromGallery, setShowChooseFromGallery] = useState(false);

  const isDisplayEditIcon = true;

  useEffect(() => {
    if (selected) {
      setOpen(false);
    }
  }, [selected]);

  useEffect(() => {
    if (selectedOption == 'chooseAvatar') {
      setShowAvatars(true);
    }
  }, [selectedOption]);

  const handleProfileSelected = (name, value, type, avatarObj) => {
    if (name && value) {
      const tempImg = new Image();
      tempImg.src = value;
      tempImg.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        canvas.width = 51;
        canvas.height = 51;
        ctx.drawImage(tempImg, 0, 0, 53, 53);

        if (type === 'icon') {
          setFieldValue(name, value);
          setFieldValue('profileId', avatarObj.id);
        } else {
          const resizedImage = canvas.toDataURL('image/jpeg');
          setFieldValue(name, resizedImage);
        }
      };
    }
  };

  const handleIsSelected = (value) => {
    if (value) {
      setSelectedOption(value);
      setOpen(false);
      setShowWebCam(false);
      if (value === 'takeAPicture') {
        setShowWebCam(true);
      }
      if (value === 'chooseFromGallery') {
        setShowChooseFromGallery(true);
      }
    }
  };



  return (
    <>
      <Dropdown
        show={open}
        onToggle={setOpen}
        drop="down-centered"
        bsPrefix="dropdown">
        <Dropdown.Toggle
          as="div"
          className="avatar-wrapper signup-avatar-wrapper dropdown-arrow-disable px-0"
          style={{ display: 'inline-block' }}>
          {/* <div className="avatar-container"></div> */}
          <img
            className="avatar-img"
            id="profile-image-drop-down"
            as="img"
            src={
              selected
                ? selected
                : userDetails && userDetails.avatarURL
                  ? userDetails.avatarURL
                  : userDetails && userDetails.profilePicture
                    ? base64ToImageSrc(userDetails.profilePicture, 'image/jpeg') // Adjust the content type as per your image type
                    : '/assets/img/icons/default-user.svg'
            }
            alt="add-icon"
          />


          {isDisplayEditIcon && (
            <img
              className="avatar-edit"
              src="/assets/img/icons/camera-icon.svg"
              alt="add-icon"
            />
          )}
        </Dropdown.Toggle>

        {/* edit profile options list  */}
        <Dropdown.Menu className="flex-row">
          <ul>
            {EDIT_PROFILE_OPTION &&
              open &&
              EDIT_PROFILE_OPTION.map((o, i) => {
                return (
                  <li
                    className="dropdown-item"
                    onClick={() => handleIsSelected(o?.value)}
                    key={i}>
                    {o?.option}
                  </li>

                )
              }
              )}
          </ul>
        </Dropdown.Menu>
      </Dropdown>




      {selectedOption === 'chooseAvatar' && showAvatars && avatarDataList.length > 0 && (
        <div className="position-absolute avatar-select-wrapper">
          {avatarDataList && avatarDataList.map((a) => (
            <img
              className="avatar-img"
              src={a.imageURL}
              alt="avatar"
              onClick={() => {
                handleProfileSelected('profileImage', a.imageURL, 'icon', a)
                setShowAvatars(false)
                setSelectedOption({})
              }
              }
              key={a.id}
            />
          ))}
        </div>
      )
      }

      {selectedOption === "chooseFromGallery" && (
        <Dropzone
          handleProfileSelected={(name, value) => handleProfileSelected(name, value, '', '')}
          selected={selected}
          isVisible={selectedOption}
          setSelectedOption={() => setSelectedOption('')}
          onClose={() => {
            setSelectedOption('')
            setShowChooseFromGallery(false)
          }}
        />
      )}
    </>
  );
}

export default ProfileImageSelector;

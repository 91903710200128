import React, { useEffect, useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import { addressValidationSchema } from "../../../utils/validations";
import {
  getAddressByUserId,
  addUpdateAddress,
  deleteAddress,
  setAsDefaultAddress,
  getCityStateByPinCode,
  getStates,
  getCityBasedOnState,
} from "../../../services";
import useLoader from "../../../utils/hooks/use-loader";
import toast from "react-hot-toast";
import AppModal from "../../modals";
import { useDispatch } from "react-redux";
import { setDefaultAddress } from "../../../store/slices/common-slice";
import useTranslation from "../../../utils/hooks/use-translation";
import { getCDSPixelId, showApiErrors } from "../../../utils/helpers";
import { returnMobileNumber } from "../../../utils/helpers";
import { Link } from "react-router-dom";
import CommonFooter from "../../footer/CommonFooter";
import Input, { getCountryCallingCode } from 'react-phone-number-input/input'
import PhoneInput from "react-phone-number-input/input";
import { parsePhoneNumber } from "react-phone-number-input";

const AddressForm = ({ source }) => {
  const { t } = useTranslation();
  let addressValidation = addressValidationSchema(t);
  const dispatch = useDispatch();
  const [openAddressModel, setAddressModel] = useState(false);
  const [addressList, setAddressList] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState("");
  const { toggleLoader } = useLoader();
  const initialValues = {
    AddressOne: "",
    AddressTwo: "",
    Near_Landmark: "",
    State: "",
    City: "",
    Set_as_Default: "",
    mobileCode: "",
  };
  const [intialData, setInitialData] = useState(initialValues);
  const [stateData, setStateData] = useState([])
  const [cityData, setCityData] = useState([])
  const [phone, setPhone] = useState("")




  // list of form inputs to show
  const renderAddressInput = [
    {
      type: "text",
      name: "AddressOne",
      placeHolder: `${t.common.address_line1}*`,
    },
    {
      type: "text",
      name: "AddressTwo",
      placeHolder: t.common.address_line2,
    },
    {
      type: "text",
      name: "Near_Landmark",
      placeHolder: t.common.nearest_landmark,
    },
    {
      type: "tel",
      name: "mobileCode",
      placeHolder: t.common.alternate_mobile_number,
      inputType: 'number',
    },
    {
      //type: 'text',
      type: "select",
      name: "State",
      placeHolder: `${t.common.state}*`,
      //disabled: true,
      options: stateData ? stateData : [],
      handleChange: (stateId) => {
        handleCityData(stateId)
      }
    },
    {
      //type: 'text',
      type: "select",
      name: "City",
      placeHolder: `${t.common.city}*`,
      //disabled: true,
      options: cityData ? cityData : []

    },
    {
      type: "checkbox",
      name: "Set_as_Default",
      placeHolder: t.common.set_as_default,
      inputType: "checkBox",
      heading: t.common.set_as_default,
    },
  ];

  //Initiall call and load address list
  useEffect(() => {
    getAllAddressByUserId();
  }, []);

  useEffect(() => {
    handleStateData();
  }, []);

  useEffect(() => {
    if (intialData?.State) {
      handleCityData(intialData?.State)
    }
    if (intialData?.mobileCode) {
      setPhone(intialData?.mobileCode)
    }
  }, [intialData])





  //get all home page details
  const getAllAddressByUserId = async () => {
    toggleLoader(true);
    try {
      const res = await getAddressByUserId();
      if (res.status === 200 && res.data.succeeded) {
        let resdata = res.data && res.data.data;
        const defaultAddress =
          resdata &&
          Array.isArray(resdata) &&
          resdata.length !== 0 &&
          resdata.find((obj) => obj.isDefault);
        setAddressList(resdata);
        dispatch(setDefaultAddress(defaultAddress));
      }
    } catch (err) {
    } finally {
      toggleLoader(false);
    }
  };

  //handle address submit
  const handleSubmit = async (values) => {
    const hfSessionKey = await getCDSPixelId();
    try {
      let reqData = {
        id: values.id || 0,
        address1: values.AddressOne,
        address2: values.AddressTwo,
        NearestLandmark: values.Near_Landmark,
        cityId: values.City,
        stateId: values.State,
        isDefault: addressList?.length == 0 ? true : values?.Set_as_Default || false,
        isActive: true,
        nearestLandmark: values.Near_Landmark,
        shippingMobile: values?.mobileCode,
        hfSession: hfSessionKey || "",
      };
      const res = await addUpdateAddress(reqData);
      if (res.data && res.data.succeeded) {
        getAllAddressByUserId();
        onClose();
        setPhone("")
      } else {
        showApiErrors(res);
      }
    } catch (err) {
      let errorRes = err.response && err.response.data;
      toast.error(errorRes.message,{id: "addUpdateAddress Error"});
    } finally {
      toggleLoader(false);
    }
  };

  //handles state data
  const handleStateData = async () => {
    const reqData = {
      countryID: 1
    }
    try {
      const res = await getStates(reqData)
      if (res.data && res.data.succeeded) {
        let data = res.data && res.data.data;
        setStateData(data)
      }
    } catch (err) {
      toast.error(err,{id: "getStates Error"})
    }
  }

  const handleCityData = async (stateId) => {

    const reqData = {
      stateID: stateId
    }
    try {
      const res = await getCityBasedOnState(reqData)
      if (res.data && res.data.succeeded) {
        let data = res.data && res.data.data;
        setCityData(data)
      }

    } catch (err) {
      toast.error(err,{id: "getCityBasedOnState Error"})
    }
  }

  const handleMobileNumber = (e) => {
    const number = e.target.value;
    setPhone(number)
  }

  //render address form fields
  const renderAddressForm = () => {
    return (
      <Formik
        initialValues={intialData}
        validationSchema={addressValidation}
        onSubmit={handleSubmit}
      >
        {({ values, setFieldValue, touched, errors }) => (
          <Form autoComplete="off">
            {console.error("errors", errors)}
            <>
              <div
                className="px-2 form-address"
                style={{ borderRadius: "1rem" }}
              >
                {renderAddressInput &&
                  renderAddressInput?.map((field, indexing) => {
                    const isChecked = values && values?.Set_as_Default;
                    return (
                      <>
                        {
                          <div key={indexing} className="form-group">
                            {field.type === "checkbox" ? (
                              <>
                                {/* {addressList.length !== 0 && ( */}
                                  <div className={"mt-4"}>
                                    <Field
                                      id={"check-box"}
                                      type="checkbox"
                                      name={`Set_as_Default`}
                                      className={`form-check-input ml-0 form-check ${touched[field.name] && errors[field.name]
                                        ? "is-invalid"
                                        : ""
                                        }`}
                                      checked={ addressList.length == 0 ? true : isChecked}
                                      placeHolder={field.placeHolder}
                                    />
                                    <label htmlFor={"check-box"}>
                                      {"Set as Default"}
                                    </label>
                                  </div>

                                {/* )} */}
                              </>
                            ) : field.type === "select" ? (
                              <Field
                                as="select"
                                name={field.name}
                                className={`form-select form-custom-select bg-grey ${touched[field.name] && errors[field.name]
                                  ? "is-invalid"
                                  : ""
                                  }`}
                                placeHolder={field.placeHolder}
                                value={values[field.name]}
                                disabled={field.disabled ? true : false}
                                onChange={(e) => {
                                  setFieldValue(field.name, e.target.value);
                                  if (field?.handleChange) {
                                    field.handleChange(e.target.value)

                                  }

                                }}
                              >
                                <option value="">{field.placeHolder}</option>
                                {field.options.map((el, optionIndex) => {
                                  return (
                                    <option key={optionIndex} value={el.id}>
                                      {el.name}
                                    </option>
                                  )
                                })}


                              </Field>
                            ) : (
                              <>
                                {field.label && (
                                  <label htmlFor={field.name}>
                                    {field.label}
                                  </label>
                                )}
                                {field.type === "tel" && field.name == "mobileCode" && (
                                  <div className="mobile-input-wrapper">
                                    <div className="form-control">
                                      <span className="country-code">+880</span>
                                      <Field
                                        name={field.name}
                                        type={field.type}
                                        placeholder={field.placeHolder}
                                        className={`form-control bg-grey ${touched[field.name] && errors[field.name]
                                          ? "is-invalid"
                                          : ""
                                          }`}
                                        maxLength={10}
                                      />
                                    </div>
                                  </div>

                                )}
                                {field.name !== "mobileCode" && (
                                  <Field
                                    type={field.type}
                                    name={field.name}
                                    className={`form-control bg-grey ${touched[field.name] && errors[field.name]
                                      ? "is-invalid"
                                      : ""
                                      }`}
                                    disabled={field.disabled}
                                    placeHolder={field.placeHolder}
                                  />

                                )}
                              </>
                            )}
                            <ErrorMessage
                              name={field.name}
                              className="text-danger"
                              component="div"
                            />
                          </div>
                        }
                      </>
                    );
                  })}
                <button type="submit" className="btn btn-primary btn-block">
                  {t.common.save_address}
                </button>
              </div>
            </>
          </Form>
        )}
      </Formik>
    );
  };

  //handle delete address api call
  const deleteAddressApi = async (id) => {
    toggleLoader(true);
    try {
        let reqData = { id: id};
      const res = await deleteAddress(reqData);
      if (res.status === 200 && res.data.succeeded) {
        getAllAddressByUserId();
      } else {
        toast.error(res.data && res.data.message,{id: "deleteAddress Error"});
      }
    } catch (err) {
    } finally {
      toggleLoader(false);
    }
  };

  //handle checkbox change and set as default action api call
  const handleCheckBoxChange = async (e, id) => {
    const hfSessionKey = await getCDSPixelId(); 
    toggleLoader(true);
    try {
      let reqData = { id: id, hfSession: hfSessionKey || "", };
      const res = await setAsDefaultAddress(reqData);
      if (res.status === 200 && res.data.succeeded) {
        getAllAddressByUserId();
      } else {
        toast.error(res.data && res.data.message,{id: "setAsDefaultAddress Error"});
      }
    } catch (err) {
    } finally {
      toggleLoader(false);
    }
  };



  //render address list which is already added
  const renderAddress = () => {
    if (addressList && addressList.length !== 0) {
      return addressList.map((el, i) => {
        const isChecked = el && el?.isDefault;

        const formattedAddress = [
          el.address1,
          el.address2,
          el.nearestLandmark,
          el.cityName,
          el.stateName
        ].filter((value) => value).join(', ');

        
        return (
          <div
            className={source === 'profile' ? "bg-light address-font-style profile-address mt-5 mt-md-8" : 'bg-light  py-6 px-5 mb-4 mt-4 claim-address'}
            style={{ borderRadius: "1rem" }}
            index={i}
          >
            <div className="d-flex align-items-top justify-content-between">
              <h3 className="address-font-style">
                {formattedAddress}
              </h3>
              <img
                src={"/assets/img/icons/edit-icon.svg"}
                alt={""}
                onClick={() => {
                  const data = {
                    AddressOne: el ? el.address1 : "",
                    AddressTwo: el ? el.address2 : "",
                    Near_Landmark: el ? el.nearestLandmark : "",
                    StateName: el ? el.stateName : "",
                    CityName: el ? el.cityName : "",
                    Set_as_Default: el ? el.isDefault : "",
                    City: el ? el.cityId : "",
                    State: el ? el.stateId : "",
                    pincodeId: el ? el.pinCodeId : "",
                    id: el ? el.id : "",
                    mobileCode: el.shippingMobile,
                  };
                  setSelectedAddress(el);
                  setInitialData(data);
                  setAddressModel(true);
                }}

              />

            </div>
            <div className="text-dark mobile-no-info pl-0 mt-3">
              {el.shippingMobile && `+880-${el.shippingMobile}`}
            </div>
            <div className="d-flex align-items-center justify-content-between mt-4 mt-md-8 pb-2 checkbox-address">
              <div>
                <Field
                  id={`Set_as_Default${i}`}
                  type="radio"
                  name={`Set_as_Default`}
                  className={"form-check-input ml-0 form-check"}
                  onChange={(e) => {
                    handleCheckBoxChange(e, el.id);
                  }}
                  checked={isChecked}
                />
                <label
                  className={"default-address-label"}
                  htmlFor={`Set_as_Default${i}`}
                >
                  {t.common.set_as_default}
                </label>
              </div>
              <img
                src={"/assets/img/icons/delete-icon.svg"}
                alt={""}
                onClick={() => {
                  let id = el.id;
                  deleteAddressApi(id);
                }}
              />
            </div>
            {source === 'profile' && i < addressList.length - 1 && (
              <div className="address-divider mt-3"></div>
            )}
          </div>
        );
      });
    }
  };

  //handle address model close
  const onClose = () => {
    setAddressModel(false);
    setInitialData(initialValues);
    setSelectedAddress("");
    setPhone("")
  };

  return (
    <>
      <div className="d-flex justify-content-between align-items-center">
        <h5 className=" address-tag">{t.common.address}</h5>
        <button
          type="button"
          className="form-add-btn address-add-btn border-0 px-0"
          onClick={() => {
            setAddressModel(true);
          }}
          disabled={addressList && addressList.length === 3}
        >
          + {t.common.add}
        </button>
      </div>
      {renderAddress()}
      {openAddressModel && (
        <AppModal
          heading={
            selectedAddress ? t.common.update_address : t.common.add_address
          }
          show={openAddressModel}
          headerClass={`px-5 pt-6`}
          onClose={onClose}
          bodyClass="py-6"
          gaClass='modal-add-address'
        >
          {renderAddressForm()}
          {/* <CommonFooter/> */}
          <div className="row align-items-center mt-9 mt-md-4">
            <div className="col-5 mr-0">
              <Link to="/terms_conditions" className="border-right text-dark pr-1">
                {t.header.terms_and_condiotions}
              </Link>
              <Link to="/privacy_policy" className="pl-1 text-dark">
                {t.header.privacy_policy}
              </Link>
            </div>
            <div className="col-7 text-right">
              <p className="mb-0 footer-small-text">
                {t.common.footer_heading}{' '}
                <img src="/assets/img/icons/dietary_symbol.svg" alt="" />
              </p>
            </div>
          </div>
        </AppModal>
      )}
    </>
  );
};

export default AddressForm;
import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import usePopup from '../../utils/hooks/use-popup';
import useAuth from '../../utils/hooks/use-auth';
import { useDispatch, useSelector } from 'react-redux';
import useTranslation from '../../utils/hooks/use-translation';
import { useViewport } from '../../utils/context/viewport-context';
import PointsBox from '../common/PointsBox';
import { ClickCDP } from '../../utils/cds/cdsHelper';
import { setAfterLoginAction } from '../../store/slices/common-slice';

function Footer() {

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { openUniqueCodePopup } = usePopup();
  const { isLoggedIn } = useAuth();
  const homePageData = useSelector((state) => state?.common?.homepageDetails);
  const { pathname } = useLocation();
  const { isMobile } = useViewport();
  const footer_type = pathname.startsWith("/claim_form") || pathname.startsWith("/reels") && isMobile;
  const linkClassname =
    'd-flex justify-content-center align-items-center flex-column';

  const handleGaEvent = (GAClass, cdpSubType) => {
    if (GAClass) {
      window.dataLayer.push({ event: GAClass });
    } if (cdpSubType) {
      ClickCDP(cdpSubType)
    }
  }
  return (
    <>
    
      {!footer_type && <footer className="bg-dark footer">
        <div className="container-fluid">
          <div className="d-block d-lg-none">
            <div className="row ">
              <div className="col home">
                <Link
                  to="/"
                  className={`link ${linkClassname} ${pathname === '/' ? 'active' : ''
                    }`}

                >
                  <img
                    src="/assets/img/footer/home-icon.svg"
                    alt=" Logo"
                    className="footer-icon"
                  />
                  <span>{t.header.home}</span>
                </Link>
              </div>
                 {/* according to Bugsheet contest section for now needs to be disable  */}
              {/* <div className="col contest">
                <Link
                  to="/contest"
                  className={`link ${linkClassname} ${pathname === '/contest' ? 'active' : ''
                    }`}
                    onClick={() => {
                      if (!isLoggedIn) {
                        dispatch(
                          setAfterLoginAction({
                            action: '',
                            redirectPath: '/contest',
                          })
                        );
                      }
                    }}>
                  <img
                    src="/assets/img/footer/contest-icon.svg"
                    alt=" Logo"
                    className="footer-icon"
                  />
                  <span>{t.common.contest}</span>
                </Link>
              </div> */}
                          {/* according to Bugsheet unique code needs to be disable  */}
              {/* <div className="col  d-flex align-items-center unique_code">
                <Link
                  to="#"
                  className="link d-flex justify-content-center align-items-center flex-column  "
                  onClick={() => {
                    isLoggedIn
                      ? openUniqueCodePopup()
                      : navigate('/login');
                    if (!isLoggedIn) {
                      dispatch(
                        setAfterLoginAction({
                          action: () => openUniqueCodePopup(),
                          redirectPath: '',
                        })
                      );
                    }
                  }}>
                  <img
                    src="/assets/img/footer/enter-code.svg"
                    alt=" Logo"
                    className="footer-img"
                  />
                  <span>{t.common.code}</span>
                </Link>
              </div> */}
              <div className="col wallet">
                <Link
                  // to={isLoggedIn ? '/my_wallet' : '/auth_access/my_wallet'}
                  to={isLoggedIn ? '/my_wallet' : '/login'}
                  onClick={() => {
                    if (!isLoggedIn) {
                      dispatch(
                        setAfterLoginAction({
                          action: '',
                          redirectPath: '/my_wallet',
                        })
                      );
                    }
                  }}
                  className={`link ${linkClassname} ${pathname === '/my_wallet' ? 'active' : ''
                    }`}>
                  <img
                    src="/assets/img/footer/wallet-icon.svg"
                    alt=" Logo"
                    className="footer-icon"
                  />
                  <small className="count">
                    {homePageData && homePageData.coinCount}
                  </small>
                  <span>{t.common.wallet}</span>
                </Link>
              </div>
              <div className="col profile">
                <Link
                  // to={isLoggedIn ? '/my_profile' : '/auth_access/my_profile'}
                  to={isLoggedIn ? '/my_profile' : '/login'}
                  onClick={() => {
                    if (!isLoggedIn) {
                      dispatch(
                        setAfterLoginAction({
                          action: '',
                          redirectPath: '/my_profile',
                        })
                      );
                    }
                  }}
                  className={`link ${linkClassname} ${pathname === '/my_profile' ? 'active' : ''
                    }`}>
                  <img
                    src="/assets/img/footer/user-icon.svg"
                    alt=" Logo"
                    className="footer-icon"
                  />
                  <span>{t.common.user}</span>
                </Link>
              </div>
            </div>
            <div className="row align-items-center mt-2">
              <div className="col-5 mr-0">
                <Link to="/terms_conditions" className="border-right pr-3 link">
                  {t.header.terms_and_condiotions}
                </Link>
                <Link to="/privacy_policy" className="pl-3 link">
                  {t.header.privacy_policy}
                </Link>
              </div>
              <div className="col-7 text-right">
                <p className="mb-0 text-nowrap">
                  {t.common.footer_heading}{' '}
                  <img src="/assets/img/icons/dietary_symbol.svg" alt="" />
                </p>
              </div>
            </div>
          </div>
          {/* desktop footer part  */}
          <div className="desk-footer d-lg-block d-none ">
            <div className="row">
              <div className="col-12">
                <div className="border-bottom pb-7 mb-12">
                  <img
                    src="/assets/img/icons/header-logo.svg"
                    alt="Logo"
                    className="logo"
                  />
                </div>
              </div>
              <div className="col-6">
                <Link to="#" className="text-link">
                  {t.common.need_help}
                </Link>
              </div>
              <div className="col-6 justify-content-end d-flex">
                <div>
                  <ul className="list-social d-flex justify-content-end">
                    <li className="list-item">
                      <Link
                        to={'https://www.youtube.com/@CokeStudioBangla'}
                        className="list-link"
                        target={'_blank'}
                        onClick={() => handleGaEvent('https://www.youtube.com/@CokeStudioBangla', 'Follow_YT')}
                      >
                        <span className="icon icon-Youtube-01"></span>
                      </Link>
                    </li>
                    <li className="list-item">
                      <Link
                        to={'https://www.facebook.com/CSBanglaOfficial'}
                        target={'_blank'}
                        className="list-link"
                        onClick={() => handleGaEvent('https://www.facebook.com/CSBanglaOfficial', 'Follow_FB')}
                      >
                        <span className="icon icon-FB-01"></span>
                      </Link>
                    </li>
                    <li className="list-item">
                      <Link
                        to={'https://open.spotify.com/artist/2McRTljYwvg7qFBhfk4UaD'}
                        target={'_blank'}
                        className="list-link"
                        onClick={() => handleGaEvent('https://open.spotify.com/artist/2McRTljYwvg7qFBhfk4UaD', 'Follow_Spotify')}

                      >
                        <span className="icon icon-spotify"></span>
                      </Link>
                    </li>
                    <li className="list-item">
                      <Link
                        to={'https://www.instagram.com/cokestudiobanglaofficial/'}
                        target={'_blank'}
                        className="list-link"
                        onClick={() => handleGaEvent('https://www.instagram.com/cokestudiobanglaofficial/', 'Follow_Insta')}
                      >
                        <span className="icon icon-insta-01">
                        </span>
                      </Link>
                    </li>
                  </ul>
                  <div className='footer-pointbox'>
                    <span className='text-light d-inline mr-1'>Follow Coke® Studio & Earn</span>
                    <div className='d-inline-block ml-1'><PointsBox coin={50} /></div>
                  </div>
                </div>
              </div>

              <div className="col-3 d-flex justify-content-between">
                <ul className="pl-0">
                  <li className="py-2">
                    <Link to="/faq" className="link">
                      {t.common.faq}
                    </Link>
                  </li>
                  <li className="py-2">
                    <Link to="#" className="link">
                      {t.common.sitemap}
                    </Link>
                  </li>
                  {/* <li className="py-2">
                  <Link to="/contact_us" className="link">
                    Contact Us
                  </Link>
                </li> */}
                </ul>
                <ul className="pl-0">
                  <li className="py-2">
                    <Link to="/terms_conditions" className="link">
                      {t.common.terms_of_use}
                    </Link>
                  </li>
                  <li className="py-2">
                    <Link to="/privacy_policy" className="link">
                      {t.header.privacy_policy}
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="col-12">
                <div className="d-flex justify-content-between align-items-center footer-border border-top pt-7 mt-12">
                  <div className="col-3 pl-0">
                    {/* <Link
                      to="https://cokestudio.coke2home.com/tamil"
                      className="link"
                      target="_blank">
                      {/* <img src="/assets/img/footer/coke-tamil.svg" alt="Logo" /> 
                    </Link>
                    <Link
                      to="https://cokestudio.coke2home.com/bangla"
                      className="ml-4"
                      target="_blank">
                      <img src="/assets/img/footer/coke-bangla.svg" alt="Logo" />
                    </Link> */}
                  </div>
                  <div className="col-9 d-flex justify-content-end pr-0">
                    <div>
                      <p className="text-light text-right">
                        &copy; {t.common.footer_subheading}
                      </p>
                      {/* <p className="text-light text-right">
                        {t.common.footer_heading}
                        <img
                          className="dietary-icon"
                          src="/assets/img/icons/dietary_symbol.svg"
                          alt=""
                        />
                      </p> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


      </footer>}
    </>
  );
}

export default Footer;

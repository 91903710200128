import { useViewport } from "../context/viewport-context";

export const bannerData = [];

export const latesteTuneData = [
    {
        image: '/assets/img/latestunes/img01.webp',
        altText: 'img01',
    },
    {
        image: '/assets/img/latestunes/img01.webp',
        altText: 'img02',
    },
    {
        image: '/assets/img/latestunes/img01.webp',
        altText: 'img03',
    },
    {
        image: '/assets/img/latestunes/img01.webp',
        altText: 'img04',
    },
];

export const behindTheSceneData = [
    {
        image: '/assets/img/behindthescene/img01.webp',
        altText: 'img01',
    },
    {
        image: '/assets/img/behindthescene/img01.webp',
        altText: 'img02',
    },
    {
        image: '/assets/img/behindthescene/img01.webp',
        altText: 'img03',
    },
    {
        image: '/assets/img/latestunes/img01.webp',
        altText: 'img04',
    },
];
export const liveStreamingData = [
    {
        image: '/assets/img/livestreaming/img01.webp',
        altText: 'img01',
    },
];
export const melodiesRewindData = [
    {
        image: '/assets/img/seasonalhits/img01.webp',
        altText: 'img02',
    },
    {
        image: '/assets/img/seasonalhits/img02.webp',
        altText: 'img02',
    },
    {
        image: '/assets/img/seasonalhits/img03.webp',
        altText: 'img03',
    },
];

export const rewardPooldata = [
    {
        image: 'assets/img/rewards/img01.webp',
        title: 'Win a Ticket to Tomorrowland',
        text: 'Stand a chance to win an all expenses paid trip to Europe and experience Tomorrowland in person',
        points: '200',
        pointsCount: '1',
        pointTitle: 'By Using',
        buttonText: 'Collect Now',
    },
    {
        image: 'assets/img/rewards/img02.webp',
        title: 'Win Music Fest Ticket3',
        text: 'Stand a chance to win a ticket to experience Music Fest.',
        points: '202',
        pointsCount: '2',
        pointTitle: 'By Using 2',
        buttonText: 'Collect Now',
    },
    {
        image: 'assets/img/rewards/img03.webp',
        title: 'Win Music Fest Ticket',
        text: 'Stand a chance to win a ticket to experience Music Fest.',
        points: '2003',
        pointsCount: '3',
        pointTitle: 'By Using 3',
        buttonText: 'Collect Now',
    },
    {
        image: 'assets/img/rewards/img01.webp',
        title: 'Win Music Fest Ticket 4',
        text: 'Stand a chance to win a ticket to experience Music Fest.',
        points: '2004',
        pointsCount: '4',
        pointTitle: 'By Using 4',
        buttonText: 'Collect Now',
    },
];

export const walletdata = [
    {
        title: 'Harmony',
        points: '200',
        totalPoints: '1000',
        cardClassName: 'Harmony',
    },
    {
        title: 'Bass',
        points: '202',
        totalPoints: '1001',
        cardClassName: 'Bass',
    },
    {
        title: 'Aldo',
        points: '2003',
        totalPoints: '1002',
        cardClassName: 'Aldo',
    },
    {
        title: 'Echo',
        points: '2004',
        totalPoints: '1003',
        cardClassName: 'Echo',
    },
];

export const treasureData = [
    {
        image: '/assets/img/treasures/img01.webp',
        title: 'Mass Merch',
        likes: '3',
        views: '5',
    },
    {
        image: '/assets/img/treasures/img01.webp',
        title: 'Class Merch',
        likes: '5',
        views: '8',
    },
    {
        image: '/assets/img/treasures/img01.webp',
        title: 'Mass Merch',
        likes: '9',
        views: '3',
    },
    {
        image: '/assets/img/treasures/img01.webp',
        title: 'Class Merch',
        likes: '38',
        views: '500',
    },
];

export const headsData = [
    {
        image: '/assets/img/studioheads/img01.webp',
        title: 'Mass Merch',
        likes: '3',
        views: '5',
    },
    {
        image: '/assets/img/studioheads/img02.webp',
        title: 'Class Merch',
        likes: '5',
        views: '8',
    },
    {
        image: '/assets/img/studioheads/img03.webp',
        title: 'Mass Merch',
        likes: '9',
        views: '3',
    },
    {
        image: '/assets/img/studioheads/img03.webp',
        title: 'Class Merch',
        likes: '38',
        views: '500',
    },
];

export const artistList = [
    {
        id: 1,
        name: 'Afroto',
        title: 'Learn more about your favorite artist',
        description:
            "Jon Batiste is one of history's most brilliant, prolific, and accomplished musicians. Batiste studied and received both a B.A. and M.F.A. at the world-renowned Juilliard School in New York City. In 2020, he won an Academy Award for Best Original Score for the Disney/Pixar film SOUL, an honor he shared with fellow composers Trent Reznor and Atticus Ross. He is the second black composer in history, after legendary jazz musician Herbie Hancock, to win an Academy Award for composition. Batiste's latest studio album, WE ARE, was released in March 2021 to overwhelming critical acclaim.",
        linkText: 'Explore',
        image: '/assets/img/artist.png',
        popularity: 120,
        views: 1000,
        likes: 500,
    },
    {
        id: 2,
        name: 'Camilo',
        title: 'Learn more about your favorite artist',
        description:
            "Jon Batiste is one of history's most brilliant, prolific, and accomplished musicians.",
        linkText: 'Explore',
        image: '/assets/img/artist.png',
        popularity: 100,
        views: 4000,
        likes: 60,
    },
    {
        id: 3,
        name: 'Cat Burns',
        title: 'Learn more about your favorite artist',
        description:
            "Jon Batiste is one of history's most brilliant, prolific, and accomplished musicians.",
        linkText: 'Explore',
        image: '/assets/img/artist.png',
        popularity: 30,
        views: 2000,
        likes: 60,
    },
    {
        id: 4,
        name: 'Afroto',
        title: 'Learn more about your favorite artist',
        description:
            "Jon Batiste is one of history's most brilliant, prolific, and accomplished musicians.",
        linkText: 'Explore',
        image: '/assets/img/artist.png',
        popularity: 120,
        views: 1000,
        likes: 500,
    },
    {
        id: 5,
        name: 'Camilo',
        title: 'Learn more about your favorite artist',
        description:
            "Jon Batiste is one of history's most brilliant, prolific, and accomplished musicians.",
        linkText: 'Explore',
        image: '/assets/img/artist.png',
        popularity: 120,
        views: 1000,
        likes: 500,
    },
    {
        id: 6,
        name: 'Cat Burns',
        title: 'Learn more about your favorite artist',
        description:
            "Jon Batiste is one of history's most brilliant, prolific, and accomplished musicians.",
        linkText: 'Explore',
        image: '/assets/img/artist.png',
        popularity: 120,
        views: 1000,
        likes: 500,
    },
];

export const karaokeData = [
    {
        id: 1,
        image: '/assets/img/karaokeon/img01.webp',
        title: 'Get Your Karaoke On',
        description:
            'Use the exclusive Coke® Studio Karaoke to record yourself singing alongto your favorites, share with your friends and brag away!',
        linkText: 'Let’s Start',
    },
];

export const menuData = (t) => {
    let data = [
        {
            id: 1,
            image: '/assets/img/icons/home.svg',
            title: t.header.home,
            url: '/',
            isAction: false,
        },
        //according to bugsheet need to disable contest page
        // {
        //     id: 2,
        //     image: '/assets/img/icons/participate.svg',
        //     title: t.header.participate_and_win,
        //     url: '/contest',
        //     isAction: false,
        //     authentication: true,
        // },
        {
            id: 3,
            image: '/assets/img/icons/explore.svg',
            title: t.header.explore,
            url: '#',
            subMenu: [
                {
                    id: 1,
                    title: "What's New",
                    url: '#whats_new',
                    isAction: false,
                    authentication: false,
                },
                {
                    id: 2,
                    title: "Spotlight",
                    url: '/see_all/vedio_card/coke_studio_spot_light',
                    isAction: false,
                    authentication: true,
                },
                {
                    id: 3,
                    title: "Behind the Scenes",
                    url: '/see_all/vedio_card/behind_the_scene',
                    isAction: false,
                    authentication: true,
                },
                {
                    id: 4,
                    title: "Melodies Rewind",
                    url: '/see_all/vedio_card/melodies_rewind',
                    isAction: false,
                    authentication: true,
                },
                {
                    id: 5,
                    title: "Artist",
                    url: "/artist",
                    isAction: false,
                    authentication: true,
                },
                {
                    id: 6,
                    title: "Around the World",
                    url: "#around_the_world",
                    isAction: false,
                    authentication: true,
                },
                {
                    id: 7,
                    title: "Refer A Friend",
                    // url: "/referral",
                    isAction: true,
                    authentication: true,
                },
                // {
                //   id: 8,
                //   title: t.header.coke_international,
                //   url: "https://www.coca-cola.com/in/en",
                // },
                // {
                //   id: 9,
                //   title: t.header.karaok,
                //   url: "/#karaoke",
                // },
                // {
                //   id: 10,
                //   title: t.header.live_stream,
                //   url: "/#live_streams",
                // },
                // {
                //   id: 11,
                //   title: t.header.ar,
                //   url: "/",
                // },
                // {
                //   id: 12,
                //   title: t.header.refer_a_friend,
                //   url: "/referral",
                // }
            ],
        },
    ];

    return data;
};

export const menuData2 = (t) => {
    let data = [
        // {
        //   id: 1,
        //   image: '/assets/img/icons/sitemap.svg',
        //   title: t.header.sitemap,
        //   url: '/site_map',
        // },
        {
            id: 2,
            image: '/assets/img/icons/faq.svg',
            title: t.header.faq,
            url: 'faq',
        },
        {
            id: 3,
            image: '/assets/img/icons/tnc.svg',
            title: t.header.terms_and_condiotions,
            url: 'terms_conditions',
        },
        {
            id: 4,
            image: '/assets/img/icons/privacyPolicy.svg',
            title: t.header.privacy_policy,
            url: 'privacy_policy',
        },
    ];
    return data;
};


export const staticBanner = (isMobile) => [
    {
        "id": 1,
        "sectionName": "banner",
        "title": "banner",
        "desc": "banner",
        "iconURL": "",
        "imageURL": null,
        "sectionDetailResponses": [
            {
                "displayType": 1,
                "title": "Magic",
                "desc": "",
                "imageURL": "assets/img/banners/" + (isMobile ? "mobile_top_banner.webp" : "topbanner.svg") + "?v=" + Math.random(),

                "displayOrder": 1,
                "videoLink": "",
                "redirectURL": null
            },
            
            {
                "displayType": 1,
                "title": "Magic",
                "desc": "",
                "imageURL": "assets/img/banners/" + (isMobile ? "mobile_top_banner.webp" : "topbanner.svg") + "?v=" + Math.random(),
                "displayOrder": 1,
                "videoLink": "",
                "redirectURL": null
            },
            {
                "displayType": 1,
                "title": "Magic",
                "desc": "",
                "imageURL": "assets/img/banners/" + (isMobile ? "mobile_top_banner.webp" : "topbanner.svg") + "?v=" + Math.random(),
                "displayOrder": 1,
                "videoLink": "",
                "redirectURL": null
            },
            {
                "displayType": 1,
                "title": "Magic",
                "desc": "",
                "imageURL": "assets/img/banners/" + (isMobile ? "mobile_top_banner.webp" : "topbanner.svg") + "?v=" + Math.random(),
                "displayOrder": 1,
                "videoLink": "",
                "redirectURL": null
            },
        ],
    }

]

export const promoBanner = (isMobile)=> [{
    "id": 2,
    "sectionName": "promo",
    "title": "",
    "desc": "",
    "iconURL": "",
    "imageURL": null,
    "sectionDetailResponses": [
        {
            "displayType": 1,
            "title": "Promo",
            "desc": "Promo",
            "imageURL": "assets/img/banners/" + (isMobile ? "mobile_promo_banner.webp" : "promo_banner.svg") ,
            "displayOrder": 1,
            "videoLink": "",
            "redirectURL": "/bharat/contest"
        }
    ],
    "rewardsPools": [],
    "videoResponses": [],
    "globalResponses": null,
    "behindSceenesResponses": [],
    "melodiesResponses": [],
    "ugcVideoResponse": [],
    "treasuryVideoResponse": []
}
]

export const whatsNewBanner = (isMobile) =>  [{
    "id": 3,
    "sectionName": "whatsNewBanner",
    "title": "",
    "desc": "",
    "iconURL": "",
    "imageURL": null,
    "sectionDetailResponses": [
        {
            "displayType": 1,
            "title": "Promo",
            "desc": "Promo",
            "imageURL": "assets/img/banners/" + (isMobile ? "WN-banner.webp" : "WN-banner.webp") ,
            "displayOrder": 1,
            "videoLink": "",
            "redirectURL": "/bharat/contest"
        }
    ],
    "rewardsPools": [],
    "videoResponses": [],
    "globalResponses": null,
    "behindSceenesResponses": [],
    "melodiesResponses": [],
    "ugcVideoResponse": [],
    "treasuryVideoResponse": []
}
]
export const garageBannner = [{
    "id": 3,
    "sectionName": "garageBanner",
    "title": "",
    "desc": "",
    "iconURL": "",
    "imageURL": null,
    "sectionDetailResponses": [
        {
            "displayType": 1,
            "title": "Promo",
            "desc": "Promo",
            "imageURL": "assets/img/banners/garageBanner.webp",
            "displayOrder": 1,
            "videoLink": "",
            "redirectURL": "/bharat/contest"
        }
    ],
    "rewardsPools": [],
    "videoResponses": [],
    "globalResponses": null,
    "behindSceenesResponses": [],
    "melodiesResponses": [],
    "ugcVideoResponse": [],
    "treasuryVideoResponse": []
}
]

export const coversTabsData =  [{
    "id": 4,
    "sectionName": "coversTabsData",
    "title": "",
    "desc": "",
    "iconURL": "",
    "imageURL": null,
    "sectionDetailResponses": [
        {
            "displayType": 1,
            "title": "Promo",
            "desc": "Promo",
            "imageURL": "assets/img/covers_image2.svg",
            "displayOrder": 1,
            "videoLink": "",
            "redirectURL": "/bharat/contest"
        },
        {
            "displayType": 1,
            "title": "Promo",
            "desc": "Promo",
            "imageURL": "assets/img/covers_image1.svg",
            "displayOrder": 1,
            "videoLink": "",
            "redirectURL": "/bharat/contest"
        },
        {
            "displayType": 1,
            "title": "Promo",
            "desc": "Promo",
            "imageURL": "assets/img/covers_image2.svg",
            "displayOrder": 1,
            "videoLink": "",
            "redirectURL": "/bharat/contest"
        },
        {
            "displayType": 1,
            "title": "Promo",
            "desc": "Promo",
            "imageURL": "assets/img/covers_image1.svg",
            "displayOrder": 1,
            "videoLink": "",
            "redirectURL": "/bharat/contest"
        },
    ],
    "rewardsPools": [],
    "videoResponses": [],
    "globalResponses": null,
    "behindSceenesResponses": [],
    "melodiesResponses": [],
    "ugcVideoResponse": [],
    "treasuryVideoResponse": []
  }
  ]
export const spotLightData = {
    "railId": "24a026e2-6305-4956-bb3b-4ab2115390e8",
    "railName": "Melodies Rewind",
    "itemCount": 9,
    "type": "video",
    "position": 10,
    "contentType": "video",
    "thumbnailUrl": "",
    "placement": "discover",
    "itemList": [
        {
            "id": "8c3c44f9-053b-412c-8428-daf03307b5ed",
            "ordering": 0,
            "displayName": "Maria<br/><span>Arijit Datta X Mansa Jimmy X Osho Jain X Shillong Chamber Choir X Bombay Brass</span>",
            "firstName": "CSB",
            "lastName": "",
            "likeCount": 9,
            "playCount": "",
            "followers": 0,
            "description": "A nostalgic melody from the evergreen loved song ‘O Maria’ finally sees the story completed between Johnny, Maria & Maria’s Father.\nThe song features Osho Jain, who has won hearts with his unique song",
            "thumbnail": "https://img.youtube.com/vi/HZkkw7Qf2wA/0.jpg",
            "url": "https://img.youtube.com/vi/HZkkw7Qf2wA/0.jpg",
            "assetId": "8c3c44f9-053b-412c-8428-daf03307b5ed",
            "video": {
                "s3Url": "https://www.youtube.com/embed/HZkkw7Qf2wA",
                "hashtags": [],
                "taggedUsers": [],
                "description": "A nostalgic melody from the evergreen loved song ‘O Maria’ finally sees the story completed between Johnny, Maria & Maria’s Father.\nThe song features Osho Jain, who has won hearts with his unique song",
                "videoId": "8c3c44f9-053b-412c-8428-daf03307b5ed",
                "likeCount": 9,
                "socialType": "youtube",
                "title": "Maria<br/><span>Arijit Datta X Mansa Jimmy X Osho Jain X Shillong Chamber Choir X Bombay Brass</span>",
                "soundName": null,
                "products": [],
                "videoOwnerName": "Osho",
                "shareCount": 8,
                "shoppable": false,
                "contentType": "video",
                "thumbnailUrl": "https://img.youtube.com/vi/HZkkw7Qf2wA/0.jpg",
                "localisedDescription": {
                    "hi": "",
                    "en": "A nostalgic melody from the evergreen loved song ‘O Maria’ finally sees the story completed between Johnny, Maria & Maria’s Father.\nThe song features Osho Jain, who has won hearts with his unique song"
                },
                "createdTimestamp": 1706277584819,
                "viewsCount": 448,
                "contentExpiry": 0,
                "userId": "25b46803-c82b-496a-9a91-5946b6f19ed5",
                "commentCount": 0,
                "labels": [],
                "topicId": "8c3c44f9-053b-412c-8428-daf03307b5ed",
                "contentUrls": [
                    {
                        "urls": [
                            "https://www.youtube.com/embed/HZkkw7Qf2wA"
                        ],
                        "width": 500,
                        "type": "video",
                        "height": 500
                    }
                ],
                "deepLink": null,
                "thumbnails": {
                    "desktop": "https://d35f5vastl4boc.cloudfront.net/image/artist_bharat_maria_desktop.webp",
                    "seeall": "https://d35f5vastl4boc.cloudfront.net/image/maria_seeall.webp",
                    "mobile": "https://d35f5vastl4boc.cloudfront.net/image/artist_bharat_maria_mobile.webp",
                    "tile": "https://d35f5vastl4boc.cloudfront.net/image/maria_landingpage.webp"
                },
                "user": {
                    "localisedFullName": {
                        "en": "Osho"
                    },
                    "profileExpiry": 0,
                    "fullName": "CokeStudioBharat",
                    "followersCount": 0,
                    "userName": "CSB",
                    "userId": "25b46803-c82b-496a-9a91-5946b6f19ed5",
                    "email": "cokestudiobharat@hww.com"
                },
                "artistsDetail": [
                    {
                        "fullName": "Melodies",
                        "userId": "f2d42b60-af38-4ab0-9199-48d0814d42ae"
                    },
                    {
                        "fullName": "Arijit",
                        "userId": "d61d1ada-8848-48e4-a71b-5052ba40d5cc"
                    },
                    {
                        "fullName": "Mansa",
                        "userId": "0337d7f5-0102-4ae4-8c57-65fbe1ca5ade"
                    },
                    {
                        "fullName": "Osho",
                        "userId": "3a8d4779-6560-4b72-bea9-2607b44af281"
                    },
                    {
                        "fullName": "Shillong Chamber Choir",
                        "userId": "7e06325b-8767-4c21-a792-baef7f3f6ecb"
                    },
                    {
                        "fullName": "Bombay Brass",
                        "userId": "38ad9dce-aa99-4b8b-a527-24be89e9971f"
                    }
                ],
                "status": "ACTIVE",
                "localisedTitle": {
                    "hi": "",
                    "en": "Maria<br/><span>Arijit Datta X Mansa Jimmy X Osho Jain X Shillong Chamber Choir X Bombay Brass</span>"
                },
                "_id": "8c3c44f9-053b-412c-8428-daf03307b5ed"
            },
            "contentType": "video",
            "bannerUrl": "",
            "pristine_image": "",
            "topicId": "8c3c44f9-053b-412c-8428-daf03307b5ed",
            "commentCount": 0,
            "userId": "25b46803-c82b-496a-9a91-5946b6f19ed5",
            "taggedUsers": [],
            "hashtags": [],
            "products": []
        },
        {
            "id": "ec444cd3-66c4-4466-b632-de4f6c029886",
            "ordering": 1,
            "displayName": "Khalasi <br/><span>ADITYA GADHVI X ACHINT</span>",
            "firstName": "Melodies",
            "lastName": "",
            "likeCount": 70,
            "playCount": "",
            "followers": 0,
            "description": "Khalasi ADITYA GADHVI X ACHINT",
            "thumbnail": "https://img.youtube.com/vi/t7wSjy9Lv-o/0.jpg",
            "url": "https://img.youtube.com/vi/t7wSjy9Lv-o/0.jpg",
            "assetId": "ec444cd3-66c4-4466-b632-de4f6c029886",
            "video": {
                "s3Url": "https://www.youtube.com/embed/t7wSjy9Lv-o",
                "hashtags": [],
                "taggedUsers": [],
                "description": "Khalasi ADITYA GADHVI X ACHINT",
                "videoId": "ec444cd3-66c4-4466-b632-de4f6c029886",
                "likeCount": 70,
                "socialType": "youtube",
                "title": "Khalasi <br/><span>ADITYA GADHVI X ACHINT</span>",
                "soundName": null,
                "products": [],
                "videoOwnerName": "Melodies",
                "shareCount": 2,
                "shoppable": false,
                "contentType": "video",
                "thumbnailUrl": "https://img.youtube.com/vi/t7wSjy9Lv-o/0.jpg",
                "localisedDescription": {
                    "hi": "",
                    "en": "Khalasi ADITYA GADHVI X ACHINT"
                },
                "createdTimestamp": 1707747625662,
                "viewsCount": 469,
                "contentExpiry": 0,
                "userId": "f2d42b60-af38-4ab0-9199-48d0814d42ae",
                "commentCount": 0,
                "labels": [
                    "Season 1"
                ],
                "topicId": "ec444cd3-66c4-4466-b632-de4f6c029886",
                "contentUrls": [
                    {
                        "urls": [
                            "https://www.youtube.com/embed/t7wSjy9Lv-o"
                        ],
                        "width": 500,
                        "type": "video",
                        "height": 500
                    }
                ],
                "deepLink": null,
                "thumbnails": {
                    "desktop": "https://d35f5vastl4boc.cloudfront.net/image/artist_bharat_khalasi_desktop.webp",
                    "seeall": "https://d35f5vastl4boc.cloudfront.net/image/khalasi_seeall.webp",
                    "mobile": "https://d35f5vastl4boc.cloudfront.net/image/artist_bharat_khalasi_mobile.webp",
                    "tile": "https://d35f5vastl4boc.cloudfront.net/image/khalasi_landingpage.webp"
                },
                "user": {
                    "localisedFullName": {
                        "hi": "Melodies",
                        "en": "Melodies"
                    },
                    "profileExpiry": 0,
                    "fullName": "Melodies",
                    "followersCount": 0,
                    "userName": "Melodies",
                    "userId": "f2d42b60-af38-4ab0-9199-48d0814d42ae",
                    "profileImageUrl": "https://d35f5vastl4boc.cloudfront.net/profileImage/udja_seeall.webp",
                    "email": "melodies.hww.com"
                },
                "artistsDetail": [
                    {
                        "fullName": "Melodies",
                        "userId": "f2d42b60-af38-4ab0-9199-48d0814d42ae"
                    },
                    {
                        "fullName": "Aditya Gadhvi",
                        "userId": "08b74d99-1d37-4d8d-be68-cf1fb5cf16aa"
                    },
                    {
                        "fullName": "Achint",
                        "userId": "350af303-ba41-4762-9bb6-2995ad239878"
                    }
                ],
                "status": "ACTIVE",
                "localisedTitle": {
                    "hi": "",
                    "en": "Khalasi <br/><span>ADITYA GADHVI X ACHINT</span>"
                },
                "_id": "ec444cd3-66c4-4466-b632-de4f6c029886"
            },
            "contentType": "video",
            "bannerUrl": "",
            "pristine_image": "",
            "topicId": "ec444cd3-66c4-4466-b632-de4f6c029886",
            "commentCount": 0,
            "userId": "f2d42b60-af38-4ab0-9199-48d0814d42ae",
            "taggedUsers": [],
            "hashtags": [],
            "products": []
        },
        {
            "id": "813a04cb-b1ba-490e-a495-ddc2d34ddf14",
            "ordering": 2,
            "displayName": "Geejaga Hakki<br/><span>Sanjith Hegde X Charan Raj</span>",
            "firstName": "CSB",
            "lastName": "",
            "likeCount": 3,
            "playCount": "",
            "followers": 0,
            "description": "In a world where deception and false narratives are pervasive, speaking the truth takes immense courage as it holds so much power. Raja Harishchandra, who appears in various texts and folklore as the ",
            "thumbnail": "https://img.youtube.com/vi/xj6FfNmzCHY/0.jpg",
            "url": "https://img.youtube.com/vi/xj6FfNmzCHY/0.jpg",
            "assetId": "813a04cb-b1ba-490e-a495-ddc2d34ddf14",
            "video": {
                "s3Url": "https://www.youtube.com/embed/xj6FfNmzCHY",
                "hashtags": [],
                "taggedUsers": [],
                "description": "In a world where deception and false narratives are pervasive, speaking the truth takes immense courage as it holds so much power. Raja Harishchandra, who appears in various texts and folklore as the ",
                "videoId": "813a04cb-b1ba-490e-a495-ddc2d34ddf14",
                "likeCount": 3,
                "socialType": "youtube",
                "title": "Geejaga Hakki<br/><span>Sanjith Hegde X Charan Raj</span>",
                "soundName": null,
                "products": [],
                "videoOwnerName": "sanjith",
                "shareCount": 2,
                "shoppable": false,
                "contentType": "video",
                "thumbnailUrl": "https://img.youtube.com/vi/xj6FfNmzCHY/0.jpg",
                "localisedDescription": {
                    "hi": "",
                    "en": "In a world where deception and false narratives are pervasive, speaking the truth takes immense courage as it holds so much power. Raja Harishchandra, who appears in various texts and folklore as the "
                },
                "createdTimestamp": 1706274328499,
                "viewsCount": 105,
                "contentExpiry": 0,
                "userId": "25b46803-c82b-496a-9a91-5946b6f19ed5",
                "commentCount": 0,
                "labels": [],
                "topicId": "813a04cb-b1ba-490e-a495-ddc2d34ddf14",
                "contentUrls": [
                    {
                        "urls": [
                            "https://www.youtube.com/embed/xj6FfNmzCHY"
                        ],
                        "width": 500,
                        "type": "video",
                        "height": 500
                    }
                ],
                "deepLink": null,
                "thumbnails": {
                    "desktop": "https://d35f5vastl4boc.cloudfront.net/image/artist_bharat_geejaga_desktop.webp",
                    "seeall": "https://d35f5vastl4boc.cloudfront.net/image/geejaga_seeall.webp",
                    "mobile": "https://d35f5vastl4boc.cloudfront.net/image/artist_bharat_geejaga_mobile.webp",
                    "tile": "https://d35f5vastl4boc.cloudfront.net/image/geejaga_landingpage.webp"
                },
                "user": {
                    "localisedFullName": {
                        "en": "Sanjith Hegde"
                    },
                    "profileExpiry": 0,
                    "fullName": "CokeStudioBharat",
                    "followersCount": 0,
                    "userName": "CSB",
                    "userId": "25b46803-c82b-496a-9a91-5946b6f19ed5",
                    "email": "cokestudiobharat@hww.com"
                },
                "artistsDetail": [
                    {
                        "fullName": "Melodies",
                        "userId": "f2d42b60-af38-4ab0-9199-48d0814d42ae"
                    },
                    {
                        "fullName": "Sanjith Hegde",
                        "userId": "e31557ba-979a-4f60-b7cd-0e1c3ff96793"
                    },
                    {
                        "fullName": "Charan Raj",
                        "userId": "e28e8aee-8d43-4e6b-b081-be3768010841"
                    }
                ],
                "status": "ACTIVE",
                "localisedTitle": {
                    "hi": "",
                    "en": "Geejaga Hakki<br/><span>Sanjith Hegde X Charan Raj</span>"
                },
                "_id": "813a04cb-b1ba-490e-a495-ddc2d34ddf14"
            },
            "contentType": "video",
            "bannerUrl": "",
            "pristine_image": "",
            "topicId": "813a04cb-b1ba-490e-a495-ddc2d34ddf14",
            "commentCount": 0,
            "userId": "25b46803-c82b-496a-9a91-5946b6f19ed5",
            "taggedUsers": [],
            "hashtags": [],
            "products": []
        },
        {
            "id": "636bf57f-6775-40c7-bbf5-ea89f45b36ad",
            "ordering": 3,
            "displayName": "Kya Karie Korimol<br/><span>Aashima Mahajan X Alif X Noor Mohammad</span>",
            "firstName": "CSB",
            "lastName": "",
            "likeCount": 5,
            "playCount": "",
            "followers": 0,
            "description": "A father-daughter relationship envelopes immense compassion, protection and love. They own a special connection that grows stronger with time and never fades away. #KyaKarieKorimol unveils such a beau",
            "thumbnail": "https://img.youtube.com/vi/ZwR-SyuhN6I/0.jpg",
            "url": "https://img.youtube.com/vi/ZwR-SyuhN6I/0.jpg",
            "assetId": "636bf57f-6775-40c7-bbf5-ea89f45b36ad",
            "video": {
                "s3Url": "https://www.youtube.com/embed/ZwR-SyuhN6I",
                "hashtags": [
                    {
                        "hashtagId": "14bc91fd-f92a-4589-a223-e704a8cdcd01kyakariekorimol4027ec7b-944c-4f27-9a51-749ac79187a4",
                        "_id": "14bc91fd-f92a-4589-a223-e704a8cdcd01kyakariekorimol4027ec7b-944c-4f27-9a51-749ac79187a4",
                        "title": "KyaKarieKorimol",
                        "lang": "en",
                        "isChallenge": 0
                    }
                ],
                "taggedUsers": [],
                "description": "A father-daughter relationship envelopes immense compassion, protection and love. They own a special connection that grows stronger with time and never fades away. #KyaKarieKorimol unveils such a beau",
                "videoId": "636bf57f-6775-40c7-bbf5-ea89f45b36ad",
                "likeCount": 5,
                "socialType": "youtube",
                "title": "Kya Karie Korimol<br/><span>Aashima Mahajan X Alif X Noor Mohammad</span>",
                "soundName": null,
                "products": [],
                "videoOwnerName": "Alif",
                "shareCount": 8,
                "shoppable": false,
                "contentType": "video",
                "thumbnailUrl": "https://img.youtube.com/vi/ZwR-SyuhN6I/0.jpg",
                "localisedDescription": {
                    "hi": "",
                    "en": "A father-daughter relationship envelopes immense compassion, protection and love. They own a special connection that grows stronger with time and never fades away. #KyaKarieKorimol unveils such a beau"
                },
                "createdTimestamp": 1706277491202,
                "viewsCount": 595,
                "contentExpiry": 0,
                "userId": "25b46803-c82b-496a-9a91-5946b6f19ed5",
                "commentCount": 0,
                "labels": [],
                "topicId": "636bf57f-6775-40c7-bbf5-ea89f45b36ad",
                "contentUrls": [
                    {
                        "urls": [
                            "https://www.youtube.com/embed/ZwR-SyuhN6I"
                        ],
                        "width": 500,
                        "type": "video",
                        "height": 500
                    }
                ],
                "deepLink": null,
                "thumbnails": {
                    "desktop": "https://d35f5vastl4boc.cloudfront.net/image/artist_bharat_korimol_desktop.webp",
                    "seeall": "https://d35f5vastl4boc.cloudfront.net/image/korimol_seeall.webp",
                    "mobile": "https://d35f5vastl4boc.cloudfront.net/image/artist_bharat_korimol_mobile.webp",
                    "tile": "https://d35f5vastl4boc.cloudfront.net/image/korimol_landingpage.webp"
                },
                "user": {
                    "localisedFullName": {
                        "en": "Alif"
                    },
                    "profileExpiry": 0,
                    "fullName": "CokeStudioBharat",
                    "followersCount": 0,
                    "userName": "CSB",
                    "userId": "25b46803-c82b-496a-9a91-5946b6f19ed5",
                    "email": "cokestudiobharat@hww.com"
                },
                "artistsDetail": [
                    {
                        "fullName": "Melodies",
                        "userId": "f2d42b60-af38-4ab0-9199-48d0814d42ae"
                    },
                    {
                        "fullName": "Aashima Mahajan",
                        "userId": "0c067a4e-d3a3-48d1-922b-7712eadc11c6"
                    },
                    {
                        "fullName": "Alif",
                        "userId": "132bab97-ca39-4486-bce6-d9c965848e43"
                    },
                    {
                        "fullName": "Noor Mohammad",
                        "userId": "1b1cdcb3-5196-40dc-9a98-6fd90696a008"
                    }
                ],
                "status": "ACTIVE",
                "localisedTitle": {
                    "hi": "",
                    "en": "Kya Karie Korimol<br/><span>Aashima Mahajan X Alif X Noor Mohammad</span>"
                },
                "_id": "636bf57f-6775-40c7-bbf5-ea89f45b36ad"
            },
            "contentType": "video",
            "bannerUrl": "",
            "pristine_image": "",
            "topicId": "636bf57f-6775-40c7-bbf5-ea89f45b36ad",
            "commentCount": 0,
            "userId": "25b46803-c82b-496a-9a91-5946b6f19ed5",
            "taggedUsers": [],
            "hashtags": [
                {
                    "hashtagId": "14bc91fd-f92a-4589-a223-e704a8cdcd01kyakariekorimol4027ec7b-944c-4f27-9a51-749ac79187a4",
                    "_id": "14bc91fd-f92a-4589-a223-e704a8cdcd01kyakariekorimol4027ec7b-944c-4f27-9a51-749ac79187a4",
                    "title": "KyaKarieKorimol",
                    "lang": "en",
                    "isChallenge": 0
                }
            ],
            "products": []
        },
        {
            "id": "92b6e421-d4ce-4991-99b0-261a1333a8ec",
            "ordering": 4,
            "displayName": "Das Main Ki Pyaar Vichon Khatteya <br/><span>Tajdar Junaid X Deveshi Sahgal X Kanwar Grewal X Amira Gill</span>",
            "firstName": "CSB",
            "lastName": "",
            "likeCount": 0,
            "playCount": "",
            "followers": 0,
            "description": "Embracing the change that comes with moving out of your comfort zone. 'Das Main Ki Pyaar Vichon Khatteya' is an ode to families, friends, & lovers who had to move away from their home to make a life o",
            "thumbnail": "https://img.youtube.com/vi/QhtJi58ZIRg/0.jpg",
            "url": "https://img.youtube.com/vi/QhtJi58ZIRg/0.jpg",
            "assetId": "92b6e421-d4ce-4991-99b0-261a1333a8ec",
            "video": {
                "s3Url": "https://www.youtube.com/embed/QhtJi58ZIRg",
                "hashtags": [],
                "taggedUsers": [],
                "description": "Embracing the change that comes with moving out of your comfort zone. 'Das Main Ki Pyaar Vichon Khatteya' is an ode to families, friends, & lovers who had to move away from their home to make a life o",
                "videoId": "92b6e421-d4ce-4991-99b0-261a1333a8ec",
                "likeCount": 0,
                "socialType": "youtube",
                "title": "Das Main Ki Pyaar Vichon Khatteya <br/><span>Tajdar Junaid X Deveshi Sahgal X Kanwar Grewal X Amira Gill</span>",
                "soundName": null,
                "products": [],
                "videoOwnerName": "Amira Gill",
                "shareCount": 1,
                "shoppable": false,
                "contentType": "video",
                "thumbnailUrl": "https://img.youtube.com/vi/QhtJi58ZIRg/0.jpg",
                "localisedDescription": {
                    "hi": "",
                    "en": "Embracing the change that comes with moving out of your comfort zone. 'Das Main Ki Pyaar Vichon Khatteya' is an ode to families, friends, & lovers who had to move away from their home to make a life o"
                },
                "createdTimestamp": 1706274227662,
                "viewsCount": 52,
                "contentExpiry": 0,
                "userId": "25b46803-c82b-496a-9a91-5946b6f19ed5",
                "commentCount": 0,
                "labels": [],
                "topicId": "92b6e421-d4ce-4991-99b0-261a1333a8ec",
                "contentUrls": [
                    {
                        "urls": [
                            "https://www.youtube.com/embed/QhtJi58ZIRg"
                        ],
                        "width": 500,
                        "type": "video",
                        "height": 500
                    }
                ],
                "deepLink": null,
                "thumbnails": {
                    "desktop": "https://d35f5vastl4boc.cloudfront.net/image/artist_bharat_dmkpvk_desktop.webp",
                    "seeall": "https://d35f5vastl4boc.cloudfront.net/image/dasmain_seeall.webp",
                    "mobile": "https://d35f5vastl4boc.cloudfront.net/image/artist_bharat_dmkpvk_mobile.webp",
                    "tile": "https://d35f5vastl4boc.cloudfront.net/image/dasmain_landingpage.webp"
                },
                "user": {
                    "localisedFullName": {
                        "en": "Amira Gill"
                    },
                    "profileExpiry": 0,
                    "fullName": "CokeStudioBharat",
                    "followersCount": 0,
                    "userName": "CSB",
                    "userId": "25b46803-c82b-496a-9a91-5946b6f19ed5",
                    "email": "cokestudiobharat@hww.com"
                },
                "artistsDetail": [
                    {
                        "fullName": "Melodies",
                        "userId": "f2d42b60-af38-4ab0-9199-48d0814d42ae"
                    },
                    {
                        "fullName": "Tajdar Junaid",
                        "userId": "439f9817-78b5-4eef-829f-c808b5d5f5c0"
                    },
                    {
                        "fullName": "Deveshi Sahgal",
                        "userId": "b2a51d35-92cd-4946-a571-f062cddee0ca"
                    },
                    {
                        "fullName": "Kanwar Grewal",
                        "userId": "08bf4f2c-d7a4-4f32-881b-42d846ead798"
                    },
                    {
                        "fullName": "Amira Gill",
                        "userId": "c2e7efe3-63e1-4f71-a9e0-5e0c73082f40"
                    }
                ],
                "status": "ACTIVE",
                "localisedTitle": {
                    "hi": "",
                    "en": "Das Main Ki Pyaar Vichon Khatteya <br/><span>Tajdar Junaid X Deveshi Sahgal X Kanwar Grewal X Amira Gill</span>"
                },
                "_id": "92b6e421-d4ce-4991-99b0-261a1333a8ec"
            },
            "contentType": "video",
            "bannerUrl": "",
            "pristine_image": "",
            "topicId": "92b6e421-d4ce-4991-99b0-261a1333a8ec",
            "commentCount": 0,
            "userId": "25b46803-c82b-496a-9a91-5946b6f19ed5",
            "taggedUsers": [],
            "hashtags": [],
            "products": []
        },
        {
            "id": "78cc1808-1682-4210-8772-4cd890c536e3",
            "ordering": 5,
            "displayName": "Taqdeer<br/><span>Prabh Deep X Rashmeet Kaur X Donn Bhat X Sakur Khan Sufi</span>",
            "firstName": "CSB",
            "lastName": "",
            "likeCount": 0,
            "playCount": "",
            "followers": 0,
            "description": "Taqdeer as we call it - destiny, is a beautiful connotation that has us enveloped in a myriad of emotions expressed through the conversational poetic rhyme scheme of Bait Baazi reflecting through ever",
            "thumbnail": "https://img.youtube.com/vi/_Zo38OLmbRQ/0.jpg",
            "url": "https://img.youtube.com/vi/_Zo38OLmbRQ/0.jpg",
            "assetId": "78cc1808-1682-4210-8772-4cd890c536e3",
            "video": {
                "s3Url": "https://www.youtube.com/embed/_Zo38OLmbRQ",
                "hashtags": [],
                "taggedUsers": [],
                "description": "Taqdeer as we call it - destiny, is a beautiful connotation that has us enveloped in a myriad of emotions expressed through the conversational poetic rhyme scheme of Bait Baazi reflecting through ever",
                "videoId": "78cc1808-1682-4210-8772-4cd890c536e3",
                "likeCount": 0,
                "socialType": "youtube",
                "title": "Taqdeer<br/><span>Prabh Deep X Rashmeet Kaur X Donn Bhat X Sakur Khan Sufi</span>",
                "soundName": null,
                "products": [],
                "videoOwnerName": "Donn",
                "shareCount": 0,
                "shoppable": false,
                "contentType": "video",
                "thumbnailUrl": "https://img.youtube.com/vi/_Zo38OLmbRQ/0.jpg",
                "localisedDescription": {
                    "hi": "",
                    "en": "Taqdeer as we call it - destiny, is a beautiful connotation that has us enveloped in a myriad of emotions expressed through the conversational poetic rhyme scheme of Bait Baazi reflecting through ever"
                },
                "createdTimestamp": 1706277671878,
                "viewsCount": 18,
                "contentExpiry": 0,
                "userId": "25b46803-c82b-496a-9a91-5946b6f19ed5",
                "commentCount": 0,
                "labels": [],
                "topicId": "78cc1808-1682-4210-8772-4cd890c536e3",
                "contentUrls": [
                    {
                        "urls": [
                            "https://www.youtube.com/embed/_Zo38OLmbRQ"
                        ],
                        "width": 500,
                        "type": "video",
                        "height": 500
                    }
                ],
                "deepLink": null,
                "thumbnails": {
                    "desktop": "https://d35f5vastl4boc.cloudfront.net/image/artist_bharat_taqdeer_desktop.webp",
                    "seeall": "https://d35f5vastl4boc.cloudfront.net/image/taqdeer_seeall.webp",
                    "mobile": "https://d35f5vastl4boc.cloudfront.net/image/artist_bharat_taqdeer_mobile.webp",
                    "tile": "https://d35f5vastl4boc.cloudfront.net/image/taqdeer_landingpage.webp"
                },
                "user": {
                    "localisedFullName": {
                        "en": "Donn Bhatt"
                    },
                    "profileExpiry": 0,
                    "fullName": "CokeStudioBharat",
                    "followersCount": 0,
                    "userName": "CSB",
                    "userId": "25b46803-c82b-496a-9a91-5946b6f19ed5",
                    "email": "cokestudiobharat@hww.com"
                },
                "artistsDetail": [
                    {
                        "fullName": "Melodies",
                        "userId": "f2d42b60-af38-4ab0-9199-48d0814d42ae"
                    },
                    {
                        "fullName": "Prabh Deep",
                        "userId": "87deea85-d52b-4840-9dca-e728604af6d6"
                    },
                    {
                        "fullName": "Rashmeet Kaur",
                        "userId": "e5a13e2d-b770-496c-9ff2-0f4229e861c8"
                    },
                    {
                        "fullName": "Donn Bhatt",
                        "userId": "c8e76d72-ee54-4b11-b7c6-b9fa5ffff4a8"
                    },
                    {
                        "fullName": "Sakur Khan",
                        "userId": "05ef21d5-ece5-4561-a940-1419b53fb21c"
                    }
                ],
                "status": "ACTIVE",
                "localisedTitle": {
                    "hi": "",
                    "en": "Taqdeer<br/><span>Prabh Deep X Rashmeet Kaur X Donn Bhat X Sakur Khan Sufi</span>"
                },
                "_id": "78cc1808-1682-4210-8772-4cd890c536e3"
            },
            "contentType": "video",
            "bannerUrl": "",
            "pristine_image": "",
            "topicId": "78cc1808-1682-4210-8772-4cd890c536e3",
            "commentCount": 0,
            "userId": "25b46803-c82b-496a-9a91-5946b6f19ed5",
            "taggedUsers": [],
            "hashtags": [],
            "products": []
        },
        {
            "id": "bb2dc57a-ac32-4811-a9e9-48c1219eb33a",
            "ordering": 6,
            "displayName": "Holi Re Rasiya<br/><span>Maithili  Thakur X Seedhe Maut X Ravi Kishan X  Mahan</span>",
            "firstName": "CSB",
            "lastName": "",
            "likeCount": 1,
            "playCount": "",
            "followers": 0,
            "description": "A modern take on an ever-loved brajbhashi delight, ",
            "thumbnail": "https://img.youtube.com/vi/5QFL3JzEgrc/0.jpg",
            "url": "https://img.youtube.com/vi/5QFL3JzEgrc/0.jpg",
            "assetId": "bb2dc57a-ac32-4811-a9e9-48c1219eb33a",
            "video": {
                "s3Url": "https://www.youtube.com/embed/5QFL3JzEgrc",
                "hashtags": [],
                "taggedUsers": [],
                "description": "A modern take on an ever-loved brajbhashi delight, ",
                "videoId": "bb2dc57a-ac32-4811-a9e9-48c1219eb33a",
                "likeCount": 1,
                "socialType": "youtube",
                "title": "Holi Re Rasiya<br/><span>Maithili  Thakur X Seedhe Maut X Ravi Kishan X  Mahan</span>",
                "soundName": null,
                "products": [],
                "videoOwnerName": "CSB",
                "shareCount": 0,
                "shoppable": false,
                "contentType": "video",
                "thumbnailUrl": "https://img.youtube.com/vi/5QFL3JzEgrc/0.jpg",
                "localisedDescription": {
                    "hi": "",
                    "en": "A modern take on an ever-loved brajbhashi delight, "
                },
                "createdTimestamp": 1706768090542,
                "viewsCount": 59,
                "contentExpiry": 0,
                "userId": "25b46803-c82b-496a-9a91-5946b6f19ed5",
                "commentCount": 0,
                "labels": [
                    "Global",
                    "Season 1"
                ],
                "topicId": "bb2dc57a-ac32-4811-a9e9-48c1219eb33a",
                "contentUrls": [
                    {
                        "urls": [
                            "https://www.youtube.com/embed/5QFL3JzEgrc"
                        ],
                        "width": 500,
                        "type": "video",
                        "height": 500
                    }
                ],
                "deepLink": null,
                "thumbnails": {
                    "desktop": "https://d35f5vastl4boc.cloudfront.net/image/artist_bharat_rasiya_desktop.webp",
                    "seeall": "https://d35f5vastl4boc.cloudfront.net/image/holire1_seeall.webp",
                    "mobile": "https://d35f5vastl4boc.cloudfront.net/image/artist_bharat_rasiya_mobile.webp",
                    "tile": "https://d35f5vastl4boc.cloudfront.net/image/holire_landingpage.webp"
                },
                "user": {
                    "localisedFullName": {
                        "hi": "",
                        "en": "Maithili Thakur"
                    },
                    "profileExpiry": 0,
                    "fullName": "CokeStudioBharat",
                    "followersCount": 0,
                    "userName": "CSB",
                    "userId": "25b46803-c82b-496a-9a91-5946b6f19ed5",
                    "email": "cokestudiobharat@hww.com"
                },
                "artistsDetail": [
                    {
                        "fullName": "Melodies",
                        "userId": "f2d42b60-af38-4ab0-9199-48d0814d42ae"
                    },
                    {
                        "fullName": "Seedhe Maut",
                        "userId": "a93fe798-1612-4351-a645-0394daf92fa4"
                    },
                    {
                        "fullName": "Ravi Kishan",
                        "userId": "373ce915-2ad7-4d75-9c83-c85c0eeba2ed"
                    },
                    {
                        "fullName": "Mahan",
                        "userId": "f7f6c930-be0c-465e-8c3e-e956f8f1ebf1"
                    },
                    {
                        "fullName": "Maithili Thakur",
                        "userId": "f863b8c4-d72f-44f4-81ab-2038ada5adcb"
                    }
                ],
                "status": "ACTIVE",
                "localisedTitle": {
                    "hi": "",
                    "en": "Holi Re Rasiya<br/><span>Maithili  Thakur X Seedhe Maut X Ravi Kishan X  Mahan</span>"
                },
                "_id": "bb2dc57a-ac32-4811-a9e9-48c1219eb33a"
            },
            "contentType": "video",
            "bannerUrl": "",
            "pristine_image": "",
            "topicId": "bb2dc57a-ac32-4811-a9e9-48c1219eb33a",
            "commentCount": 0,
            "userId": "25b46803-c82b-496a-9a91-5946b6f19ed5",
            "taggedUsers": [],
            "hashtags": [],
            "products": []
        },
        {
            "id": "00175503-d5a0-4939-8b9b-182251ac05a6",
            "ordering": 7,
            "displayName": "Udja<br/><span>Burrah X Jasleen X Oaff X Savera</span>",
            "firstName": "CSB",
            "lastName": "",
            "likeCount": 0,
            "playCount": "",
            "followers": 0,
            "description": "The bitter-sweet journey of two people who part ways with the promise of a better future - 'Udja' is an ode to hope and the energy of new beginnings. Join us as we celebrate this journey.\n",
            "thumbnail": "https://img.youtube.com/vi/0Ehp0lTRHuM/0.jpg",
            "url": "https://img.youtube.com/vi/0Ehp0lTRHuM/0.jpg",
            "assetId": "00175503-d5a0-4939-8b9b-182251ac05a6",
            "video": {
                "s3Url": "https://www.youtube.com/embed/0Ehp0lTRHuM",
                "hashtags": [],
                "taggedUsers": [],
                "description": "The bitter-sweet journey of two people who part ways with the promise of a better future - 'Udja' is an ode to hope and the energy of new beginnings. Join us as we celebrate this journey.\n",
                "videoId": "00175503-d5a0-4939-8b9b-182251ac05a6",
                "likeCount": 0,
                "socialType": "youtube",
                "title": "Udja<br/><span>Burrah X Jasleen X Oaff X Savera</span>",
                "soundName": null,
                "products": [],
                "videoOwnerName": "Burrah",
                "shareCount": 1,
                "shoppable": false,
                "contentType": "video",
                "thumbnailUrl": "https://img.youtube.com/vi/0Ehp0lTRHuM/0.jpg",
                "localisedDescription": {
                    "hi": "",
                    "en": "The bitter-sweet journey of two people who part ways with the promise of a better future - 'Udja' is an ode to hope and the energy of new beginnings. Join us as we celebrate this journey.\n"
                },
                "createdTimestamp": 1706277753987,
                "viewsCount": 13,
                "contentExpiry": 0,
                "userId": "25b46803-c82b-496a-9a91-5946b6f19ed5",
                "commentCount": 0,
                "labels": [],
                "topicId": "00175503-d5a0-4939-8b9b-182251ac05a6",
                "contentUrls": [
                    {
                        "urls": [
                            "https://www.youtube.com/embed/0Ehp0lTRHuM"
                        ],
                        "width": 500,
                        "type": "video",
                        "height": 500
                    }
                ],
                "deepLink": null,
                "thumbnails": {
                    "desktop": "https://d35f5vastl4boc.cloudfront.net/image/artist_bharat_udja_desktop.webp",
                    "seeall": "https://d35f5vastl4boc.cloudfront.net/image/udja_seeall.webp",
                    "mobile": "https://d35f5vastl4boc.cloudfront.net/image/artist_bharat_udja_mobile.webp",
                    "tile": "https://d35f5vastl4boc.cloudfront.net/image/udja_landingpage.webp"
                },
                "user": {
                    "localisedFullName": {
                        "en": "Burrah"
                    },
                    "profileExpiry": 0,
                    "fullName": "CokeStudioBharat",
                    "followersCount": 0,
                    "userName": "CSB",
                    "userId": "25b46803-c82b-496a-9a91-5946b6f19ed5",
                    "email": "cokestudiobharat@hww.com"
                },
                "artistsDetail": [
                    {
                        "fullName": "Melodies",
                        "userId": "f2d42b60-af38-4ab0-9199-48d0814d42ae"
                    },
                    {
                        "fullName": "Burrah",
                        "userId": "617bc63d-89c8-41dc-8005-67ee470826cd"
                    },
                    {
                        "fullName": "Jasleen Royal",
                        "userId": "80a615a0-a431-49d5-962b-9b98b9142f13"
                    },
                    {
                        "fullName": "Oaff",
                        "userId": "7f22a1ca-098f-4e41-8da2-5df583ac69ce"
                    },
                    {
                        "fullName": "Savera",
                        "userId": "659a2973-03ef-40ef-80d5-4b17e965f0ff"
                    }
                ],
                "status": "ACTIVE",
                "localisedTitle": {
                    "hi": "",
                    "en": "Udja<br/><span>Burrah X Jasleen X Oaff X Savera</span>"
                },
                "_id": "00175503-d5a0-4939-8b9b-182251ac05a6"
            },
            "contentType": "video",
            "bannerUrl": "",
            "pristine_image": "",
            "topicId": "00175503-d5a0-4939-8b9b-182251ac05a6",
            "commentCount": 0,
            "userId": "25b46803-c82b-496a-9a91-5946b6f19ed5",
            "taggedUsers": [],
            "hashtags": [],
            "products": []
        },
        {
            "id": "dcbd1c64-4f92-4cc4-b1ba-2674ec7650af",
            "ordering": 8,
            "displayName": "Udja in 360 <br/><span>OAFF x Savera x Burrah x Jasleen </span>",
            "firstName": "Melodies",
            "lastName": "",
            "likeCount": 1,
            "playCount": "",
            "followers": 0,
            "description": "Udja in 360",
            "thumbnail": "https://img.youtube.com/vi/v9fYeM_dGeM/0.jpg",
            "url": "https://img.youtube.com/vi/v9fYeM_dGeM/0.jpg",
            "assetId": "dcbd1c64-4f92-4cc4-b1ba-2674ec7650af",
            "video": {
                "s3Url": "https://www.youtube.com/embed/v9fYeM_dGeM",
                "hashtags": [],
                "taggedUsers": [],
                "description": "Udja in 360",
                "videoId": "dcbd1c64-4f92-4cc4-b1ba-2674ec7650af",
                "likeCount": 1,
                "socialType": "youtube",
                "title": "Udja in 360 <br/><span>OAFF x Savera x Burrah x Jasleen </span>",
                "soundName": null,
                "products": [],
                "videoOwnerName": "Melodies",
                "shareCount": 1,
                "shoppable": false,
                "contentType": "video",
                "thumbnailUrl": "https://img.youtube.com/vi/v9fYeM_dGeM/0.jpg",
                "localisedDescription": {
                    "hi": "",
                    "en": "Udja in 360"
                },
                "createdTimestamp": 1707804641962,
                "viewsCount": 41,
                "contentExpiry": 0,
                "userId": "f2d42b60-af38-4ab0-9199-48d0814d42ae",
                "commentCount": 0,
                "labels": [
                    "Season 1"
                ],
                "topicId": "dcbd1c64-4f92-4cc4-b1ba-2674ec7650af",
                "contentUrls": [
                    {
                        "urls": [
                            "https://www.youtube.com/embed/v9fYeM_dGeM"
                        ],
                        "width": 500,
                        "type": "video",
                        "height": 500
                    }
                ],
                "deepLink": null,
                "thumbnails": {
                    "desktop": "https://d35f5vastl4boc.cloudfront.net/image/artist_bharat_udja_desktop.webp",
                    "seeall": "https://d35f5vastl4boc.cloudfront.net/image/udja_seeall.webp",
                    "mobile": "https://d35f5vastl4boc.cloudfront.net/image/artist_bharat_udja_mobile.webp",
                    "tile": "https://d35f5vastl4boc.cloudfront.net/image/udja_landingpage.webp"
                },
                "user": {
                    "localisedFullName": {
                        "hi": "Melodies",
                        "en": "Melodies"
                    },
                    "profileExpiry": 0,
                    "fullName": "Melodies",
                    "followersCount": 0,
                    "userName": "Melodies",
                    "userId": "f2d42b60-af38-4ab0-9199-48d0814d42ae",
                    "profileImageUrl": "https://d35f5vastl4boc.cloudfront.net/profileImage/udja_seeall.webp",
                    "email": "melodies.hww.com"
                },
                "artistsDetail": [
                    {
                        "fullName": "Oaff",
                        "userId": "7f22a1ca-098f-4e41-8da2-5df583ac69ce"
                    },
                    {
                        "fullName": "Burrah",
                        "userId": "617bc63d-89c8-41dc-8005-67ee470826cd"
                    },
                    {
                        "fullName": "Jasleen Royal",
                        "userId": "80a615a0-a431-49d5-962b-9b98b9142f13"
                    },
                    {
                        "fullName": "Savera",
                        "userId": "659a2973-03ef-40ef-80d5-4b17e965f0ff"
                    }
                ],
                "status": "ACTIVE",
                "localisedTitle": {
                    "hi": "",
                    "en": "Udja in 360 <br/><span>OAFF x Savera x Burrah x Jasleen </span>"
                },
                "_id": "dcbd1c64-4f92-4cc4-b1ba-2674ec7650af"
            },
            "contentType": "video",
            "bannerUrl": "",
            "pristine_image": "",
            "topicId": "dcbd1c64-4f92-4cc4-b1ba-2674ec7650af",
            "commentCount": 0,
            "userId": "f2d42b60-af38-4ab0-9199-48d0814d42ae",
            "taggedUsers": [],
            "hashtags": [],
            "products": []
        }
    ],
    "likedVideos": []
}

export const staticBehindTheScene = {
    "railId": "f126fa2e-567c-41b0-8421-a38fef2bb025",
    "railName": "Behind the Scenes",
    "itemCount": 14,
    "type": "video",
    "position": 9,
    "contentType": "video",
    "thumbnailUrl": "",
    "placement": "discover",
    "itemList": [
        {
            "id": "0dd4c8bb-a26e-480d-ade4-45ee6a2e79a5",
            "ordering": 0,
            "displayName": "Khalasi <br/> <span>Aditya Gadhvi X Achint</span>",
            "firstName": "CSB",
            "lastName": "",
            "likeCount": 9,
            "playCount": "",
            "followers": 0,
            "description": "Khalasi  - Aditya Gadhvi X Achint",
            "thumbnail": "https://d35f5vastl4boc.cloudfront.net/destination/video_1706607913918_thumb.0000001.jpg",
            "url": "https://d35f5vastl4boc.cloudfront.net/destination/video_1706607913918_thumb.0000001.jpg",
            "assetId": "0dd4c8bb-a26e-480d-ade4-45ee6a2e79a5",
            "video": {
                "s3Url": "https://d35f5vastl4boc.cloudfront.net/video/videofile/video_1706607913918.mp4",
                "hashtags": [],
                "description": "Khalasi  - Aditya Gadhvi X Achint",
                "videoId": "0dd4c8bb-a26e-480d-ade4-45ee6a2e79a5",
                "likeCount": 9,
                "socialType": "gluedin",
                "title": "Khalasi <br/> <span>Aditya Gadhvi X Achint</span>",
                "soundName": null,
                "videoOwnerName": "aditya",
                "products": [],
                "shareCount": 6,
                "shoppable": false,
                "contentType": "video",
                "thumbnailUrl": "https://d35f5vastl4boc.cloudfront.net/destination/video_1706607913918_thumb.0000001.jpg",
                "localisedDescription": {
                    "hi": "",
                    "en": "Khalasi  - Aditya Gadhvi X Achint"
                },
                "createdTimestamp": 1706607914449,
                "viewsCount": 2044,
                "contentExpiry": 0,
                "userId": "25b46803-c82b-496a-9a91-5946b6f19ed5",
                "commentCount": 0,
                "labels": [],
                "topicId": "0dd4c8bb-a26e-480d-ade4-45ee6a2e79a5",
                "contentUrls": [
                    {
                        "duration": 180,
                        "urls": [
                            "https://d35f5vastl4boc.cloudfront.net/destination/video_1706607913918_cs_prod_512.MP4",
                            "https://d35f5vastl4boc.cloudfront.net/destination/video_1706607913918_cs_prod_1024.MP4"
                        ],
                        "width": 1920,
                        "type": "video",
                        "factor": 1.78,
                        "height": 1080
                    }
                ],
                "deepLink": null,
                "thumbnails": {
                    "desktop": "https://d35f5vastl4boc.cloudfront.net/image/BTS_bharat_khalasi1_desktop.webp",
                    "seeall": "https://d35f5vastl4boc.cloudfront.net/image/BTS_bharat_khalasi1_seeall.webp",
                    "mobile": "https://d35f5vastl4boc.cloudfront.net/image/BTS_bharat_khalasi1_mobile.webp"
                },
                "user": {
                    "localisedFullName": {
                        "en": "Aditya Gadhvi"
                    },
                    "profileExpiry": 0,
                    "fullName": "CokeStudioBharat",
                    "followersCount": 0,
                    "userName": "CSB",
                    "userId": "25b46803-c82b-496a-9a91-5946b6f19ed5",
                    "email": "cokestudiobharat@hww.com"
                },
                "artistsDetail": [
                    {
                        "fullName": "CokeStudioBharat",
                        "userId": "25b46803-c82b-496a-9a91-5946b6f19ed5"
                    },
                    {
                        "fullName": "Aditya Gadhvi",
                        "userId": "08b74d99-1d37-4d8d-be68-cf1fb5cf16aa"
                    },
                    {
                        "fullName": "Achint",
                        "userId": "350af303-ba41-4762-9bb6-2995ad239878"
                    }
                ],
                "status": "ACTIVE",
                "localisedTitle": {
                    "hi": "",
                    "en": "Khalasi <br/> <span>Aditya Gadhvi X Achint</span>"
                },
                "_id": "0dd4c8bb-a26e-480d-ade4-45ee6a2e79a5"
            },
            "contentType": "video",
            "bannerUrl": "",
            "pristine_image": "",
            "topicId": "0dd4c8bb-a26e-480d-ade4-45ee6a2e79a5",
            "commentCount": 0,
            "userId": "25b46803-c82b-496a-9a91-5946b6f19ed5",
            "hashtags": [],
            "products": []
        },
        {
            "id": "b0fdfc02-49b5-4b0c-89a9-16c834669b09",
            "ordering": 1,
            "displayName": "Taqdeer 1<br/> <span>Prabh Deep X Rashmeet Kaur X Donn Bhat X Sakur Khan Sufi</span>",
            "firstName": "CSB",
            "lastName": "",
            "likeCount": 4,
            "playCount": "",
            "followers": 0,
            "description": "Taqdeer - PRABH DEEP X RASHMEET KAUR X DONN BHAT X SAKUR KHAN SUFI",
            "thumbnail": "https://d35f5vastl4boc.cloudfront.net/destination/video_1706601330098_thumb.0000001.jpg",
            "url": "https://d35f5vastl4boc.cloudfront.net/destination/video_1706601330098_thumb.0000001.jpg",
            "assetId": "b0fdfc02-49b5-4b0c-89a9-16c834669b09",
            "video": {
                "s3Url": "https://d35f5vastl4boc.cloudfront.net/video/videofile/video_1706601330098.mp4",
                "hashtags": [],
                "description": "Taqdeer - PRABH DEEP X RASHMEET KAUR X DONN BHAT X SAKUR KHAN SUFI",
                "videoId": "b0fdfc02-49b5-4b0c-89a9-16c834669b09",
                "likeCount": 4,
                "socialType": "gluedin",
                "title": "Taqdeer 1<br/> <span>Prabh Deep X Rashmeet Kaur X Donn Bhat X Sakur Khan Sufi</span>",
                "soundName": null,
                "videoOwnerName": "prabh",
                "products": [],
                "shareCount": 5,
                "shoppable": false,
                "contentType": "video",
                "thumbnailUrl": "https://d35f5vastl4boc.cloudfront.net/destination/video_1706601330098_thumb.0000001.jpg",
                "localisedDescription": {
                    "hi": "",
                    "en": "Taqdeer - PRABH DEEP X RASHMEET KAUR X DONN BHAT X SAKUR KHAN SUFI"
                },
                "createdTimestamp": 1706601330358,
                "viewsCount": 236,
                "contentExpiry": 0,
                "userId": "25b46803-c82b-496a-9a91-5946b6f19ed5",
                "commentCount": 0,
                "labels": [],
                "topicId": "b0fdfc02-49b5-4b0c-89a9-16c834669b09",
                "contentUrls": [
                    {
                        "duration": 14,
                        "urls": [
                            "https://d35f5vastl4boc.cloudfront.net/destination/video_1706601330098_cs_prod_512.MP4",
                            "https://d35f5vastl4boc.cloudfront.net/destination/video_1706601330098_cs_prod_1024.MP4"
                        ],
                        "width": 1080,
                        "type": "video",
                        "factor": 1,
                        "height": 1080
                    }
                ],
                "deepLink": null,
                "thumbnails": {
                    "desktop": "https://d35f5vastl4boc.cloudfront.net/image/BTS_bharat_taqdeer1_desktop.webp",
                    "seeall": "https://d35f5vastl4boc.cloudfront.net/image/BTS_bharat_taqdeer1_seeall.webp",
                    "mobile": "https://d35f5vastl4boc.cloudfront.net/image/BTS_bharat_taqdeer1_mobile.webp"
                },
                "user": {
                    "localisedFullName": {
                        "en": "Prabh Deep"
                    },
                    "profileExpiry": 0,
                    "fullName": "CokeStudioBharat",
                    "followersCount": 0,
                    "userName": "CSB",
                    "userId": "25b46803-c82b-496a-9a91-5946b6f19ed5",
                    "email": "cokestudiobharat@hww.com"
                },
                "artistsDetail": [
                    {
                        "fullName": "CokeStudioBharat",
                        "userId": "25b46803-c82b-496a-9a91-5946b6f19ed5"
                    },
                    {
                        "fullName": "Prabh Deep",
                        "userId": "87deea85-d52b-4840-9dca-e728604af6d6"
                    },
                    {
                        "fullName": "Rashmeet Kaur",
                        "userId": "e5a13e2d-b770-496c-9ff2-0f4229e861c8"
                    },
                    {
                        "fullName": "Donn Bhatt",
                        "userId": "c8e76d72-ee54-4b11-b7c6-b9fa5ffff4a8"
                    },
                    {
                        "fullName": "Sakur Khan",
                        "userId": "05ef21d5-ece5-4561-a940-1419b53fb21c"
                    }
                ],
                "status": "ACTIVE",
                "localisedTitle": {
                    "hi": "",
                    "en": "Taqdeer 1<br/> <span>Prabh Deep X Rashmeet Kaur X Donn Bhat X Sakur Khan Sufi</span>"
                },
                "_id": "b0fdfc02-49b5-4b0c-89a9-16c834669b09"
            },
            "contentType": "video",
            "bannerUrl": "",
            "pristine_image": "",
            "topicId": "b0fdfc02-49b5-4b0c-89a9-16c834669b09",
            "commentCount": 0,
            "userId": "25b46803-c82b-496a-9a91-5946b6f19ed5",
            "hashtags": [],
            "products": []
        },
        {
            "id": "492c48c3-2374-42c3-bd1c-0f60a3279a83",
            "ordering": 2,
            "displayName": "Taqdeer 2<br/> <span>Prabh Deep X Rashmeet Kaur X Donn Bhat X Sakur Khan Sufi</span>",
            "firstName": "CSB",
            "lastName": "",
            "likeCount": 3,
            "playCount": "",
            "followers": 0,
            "description": "Taqdeer - PRABH DEEP X RASHMEET KAUR X DONN BHAT X SAKUR KHAN SUFI",
            "thumbnail": "https://d35f5vastl4boc.cloudfront.net/destination/video_1706597202212_thumb.0000001.jpg",
            "url": "https://d35f5vastl4boc.cloudfront.net/destination/video_1706597202212_thumb.0000001.jpg",
            "assetId": "492c48c3-2374-42c3-bd1c-0f60a3279a83",
            "video": {
                "s3Url": "https://d35f5vastl4boc.cloudfront.net/video/videofile/video_1706597202212.mp4",
                "hashtags": [],
                "description": "Taqdeer - PRABH DEEP X RASHMEET KAUR X DONN BHAT X SAKUR KHAN SUFI",
                "videoId": "492c48c3-2374-42c3-bd1c-0f60a3279a83",
                "likeCount": 3,
                "socialType": "gluedin",
                "title": "Taqdeer 2<br/> <span>Prabh Deep X Rashmeet Kaur X Donn Bhat X Sakur Khan Sufi</span>",
                "soundName": null,
                "videoOwnerName": "prabh",
                "products": [],
                "shareCount": 0,
                "shoppable": false,
                "contentType": "video",
                "thumbnailUrl": "https://d35f5vastl4boc.cloudfront.net/destination/video_1706597202212_thumb.0000001.jpg",
                "localisedDescription": {
                    "hi": "",
                    "en": "Taqdeer - PRABH DEEP X RASHMEET KAUR X DONN BHAT X SAKUR KHAN SUFI"
                },
                "createdTimestamp": 1706597202747,
                "viewsCount": 135,
                "contentExpiry": 0,
                "userId": "25b46803-c82b-496a-9a91-5946b6f19ed5",
                "commentCount": 0,
                "labels": [],
                "topicId": "492c48c3-2374-42c3-bd1c-0f60a3279a83",
                "contentUrls": [
                    {
                        "duration": 289,
                        "urls": [
                            "https://d35f5vastl4boc.cloudfront.net/destination/video_1706597202212_cs_prod_512.MP4",
                            "https://d35f5vastl4boc.cloudfront.net/destination/video_1706597202212_cs_prod_1024.MP4"
                        ],
                        "width": 1920,
                        "type": "video",
                        "factor": 1.78,
                        "height": 1080
                    }
                ],
                "deepLink": null,
                "thumbnails": {
                    "desktop": "https://d35f5vastl4boc.cloudfront.net/image/BTS_bharat_taqdeer3_desktop.webp",
                    "seeall": "https://d35f5vastl4boc.cloudfront.net/image/BTS_bharat_taqdeer3_seeall.webp",
                    "mobile": "https://d35f5vastl4boc.cloudfront.net/image/BTS_bharat_taqdeer3_mobile.webp"
                },
                "user": {
                    "localisedFullName": {
                        "en": "Prabh Deep"
                    },
                    "profileExpiry": 0,
                    "fullName": "CokeStudioBharat",
                    "followersCount": 0,
                    "userName": "CSB",
                    "userId": "25b46803-c82b-496a-9a91-5946b6f19ed5",
                    "email": "cokestudiobharat@hww.com"
                },
                "artistsDetail": [
                    {
                        "fullName": "CokeStudioBharat",
                        "userId": "25b46803-c82b-496a-9a91-5946b6f19ed5"
                    },
                    {
                        "fullName": "Prabh Deep",
                        "userId": "87deea85-d52b-4840-9dca-e728604af6d6"
                    },
                    {
                        "fullName": "Rashmeet Kaur",
                        "userId": "e5a13e2d-b770-496c-9ff2-0f4229e861c8"
                    },
                    {
                        "fullName": "Donn Bhatt",
                        "userId": "c8e76d72-ee54-4b11-b7c6-b9fa5ffff4a8"
                    },
                    {
                        "fullName": "Sakur Khan",
                        "userId": "05ef21d5-ece5-4561-a940-1419b53fb21c"
                    }
                ],
                "status": "ACTIVE",
                "localisedTitle": {
                    "hi": "",
                    "en": "Taqdeer 2<br/> <span>Prabh Deep X Rashmeet Kaur X Donn Bhat X Sakur Khan Sufi</span>"
                },
                "_id": "492c48c3-2374-42c3-bd1c-0f60a3279a83"
            },
            "contentType": "video",
            "bannerUrl": "",
            "pristine_image": "",
            "topicId": "492c48c3-2374-42c3-bd1c-0f60a3279a83",
            "commentCount": 0,
            "userId": "25b46803-c82b-496a-9a91-5946b6f19ed5",
            "hashtags": [],
            "products": []
        },
        {
            "id": "d975990b-db62-4a69-913c-ebb8165f91eb",
            "ordering": 3,
            "displayName": "Taqdeer 3<br/> <span>Prabh Deep X Rashmeet Kaur X Donn Bhat X Sakur Khan Sufi</span>",
            "firstName": "CSB",
            "lastName": "",
            "likeCount": 1,
            "playCount": "",
            "followers": 0,
            "description": "Taqdeer - PRABH DEEP X RASHMEET KAUR X DONN BHAT X SAKUR KHAN SUFI",
            "thumbnail": "https://d35f5vastl4boc.cloudfront.net/destination/video_1706601370301_thumb.0000001.jpg",
            "url": "https://d35f5vastl4boc.cloudfront.net/destination/video_1706601370301_thumb.0000001.jpg",
            "assetId": "d975990b-db62-4a69-913c-ebb8165f91eb",
            "video": {
                "s3Url": "https://d35f5vastl4boc.cloudfront.net/video/videofile/video_1706601370301.mp4",
                "hashtags": [],
                "description": "Taqdeer - PRABH DEEP X RASHMEET KAUR X DONN BHAT X SAKUR KHAN SUFI",
                "videoId": "d975990b-db62-4a69-913c-ebb8165f91eb",
                "likeCount": 1,
                "socialType": "gluedin",
                "title": "Taqdeer 3<br/> <span>Prabh Deep X Rashmeet Kaur X Donn Bhat X Sakur Khan Sufi</span>",
                "soundName": null,
                "videoOwnerName": "prabh",
                "products": [],
                "shareCount": 1,
                "shoppable": false,
                "contentType": "video",
                "thumbnailUrl": "https://d35f5vastl4boc.cloudfront.net/destination/video_1706601370301_thumb.0000001.jpg",
                "localisedDescription": {
                    "hi": "",
                    "en": "Taqdeer - PRABH DEEP X RASHMEET KAUR X DONN BHAT X SAKUR KHAN SUFI"
                },
                "createdTimestamp": 1706601370712,
                "viewsCount": 12,
                "contentExpiry": 0,
                "userId": "25b46803-c82b-496a-9a91-5946b6f19ed5",
                "commentCount": 0,
                "labels": [],
                "topicId": "d975990b-db62-4a69-913c-ebb8165f91eb",
                "contentUrls": [
                    {
                        "duration": 28,
                        "urls": [
                            "https://d35f5vastl4boc.cloudfront.net/destination/video_1706601370301_cs_prod_512.MP4",
                            "https://d35f5vastl4boc.cloudfront.net/destination/video_1706601370301_cs_prod_1024.MP4"
                        ],
                        "width": 1080,
                        "type": "video",
                        "factor": 1,
                        "height": 1080
                    }
                ],
                "deepLink": null,
                "thumbnails": {
                    "desktop": "https://d35f5vastl4boc.cloudfront.net/image/BTS_bharat_taqdeer2_desktop.webp",
                    "seeall": "https://d35f5vastl4boc.cloudfront.net/image/BTS_bharat_taqdeer2_seeall.webp",
                    "mobile": "https://d35f5vastl4boc.cloudfront.net/image/BTS_bharat_taqdeer2_mobile.webp"
                },
                "user": {
                    "localisedFullName": {
                        "en": "Prabh Deep"
                    },
                    "profileExpiry": 0,
                    "fullName": "CokeStudioBharat",
                    "followersCount": 0,
                    "userName": "CSB",
                    "userId": "25b46803-c82b-496a-9a91-5946b6f19ed5",
                    "email": "cokestudiobharat@hww.com"
                },
                "artistsDetail": [
                    {
                        "fullName": "CokeStudioBharat",
                        "userId": "25b46803-c82b-496a-9a91-5946b6f19ed5"
                    }
                ],
                "status": "ACTIVE",
                "localisedTitle": {
                    "hi": "",
                    "en": "Taqdeer 3<br/> <span>Prabh Deep X Rashmeet Kaur X Donn Bhat X Sakur Khan Sufi</span>"
                },
                "_id": "d975990b-db62-4a69-913c-ebb8165f91eb"
            },
            "contentType": "video",
            "bannerUrl": "",
            "pristine_image": "",
            "topicId": "d975990b-db62-4a69-913c-ebb8165f91eb",
            "commentCount": 0,
            "userId": "25b46803-c82b-496a-9a91-5946b6f19ed5",
            "hashtags": [],
            "products": []
        },
        {
            "id": "6ed6a4c3-fa9b-46f5-af8b-8424e0c8ee9d",
            "ordering": 4,
            "displayName": "Taqdeer 4<br/> <span>Prabh Deep X Rashmeet Kaur X Donn Bhat X Sakur Khan Sufi</span>",
            "firstName": "CSB",
            "lastName": "",
            "likeCount": 2,
            "playCount": "",
            "followers": 0,
            "description": "Taqdeer - PRABH DEEP X RASHMEET KAUR X DONN BHAT X SAKUR KHAN SUFI",
            "thumbnail": "https://d35f5vastl4boc.cloudfront.net/destination/video_1706601410811_thumb.0000001.jpg",
            "url": "https://d35f5vastl4boc.cloudfront.net/destination/video_1706601410811_thumb.0000001.jpg",
            "assetId": "6ed6a4c3-fa9b-46f5-af8b-8424e0c8ee9d",
            "video": {
                "s3Url": "https://d35f5vastl4boc.cloudfront.net/video/videofile/video_1706601410811.mp4",
                "hashtags": [],
                "description": "Taqdeer - PRABH DEEP X RASHMEET KAUR X DONN BHAT X SAKUR KHAN SUFI",
                "videoId": "6ed6a4c3-fa9b-46f5-af8b-8424e0c8ee9d",
                "likeCount": 2,
                "socialType": "gluedin",
                "title": "Taqdeer 4<br/> <span>Prabh Deep X Rashmeet Kaur X Donn Bhat X Sakur Khan Sufi</span>",
                "soundName": null,
                "videoOwnerName": "prabh",
                "products": [],
                "shareCount": 1,
                "shoppable": false,
                "contentType": "video",
                "thumbnailUrl": "https://d35f5vastl4boc.cloudfront.net/destination/video_1706601410811_thumb.0000001.jpg",
                "localisedDescription": {
                    "hi": "",
                    "en": "Taqdeer - PRABH DEEP X RASHMEET KAUR X DONN BHAT X SAKUR KHAN SUFI"
                },
                "createdTimestamp": 1706601411240,
                "viewsCount": 17,
                "contentExpiry": 0,
                "userId": "25b46803-c82b-496a-9a91-5946b6f19ed5",
                "commentCount": 0,
                "labels": [],
                "topicId": "6ed6a4c3-fa9b-46f5-af8b-8424e0c8ee9d",
                "contentUrls": [
                    {
                        "duration": 84,
                        "urls": [
                            "https://d35f5vastl4boc.cloudfront.net/destination/video_1706601410811_cs_prod_512.MP4",
                            "https://d35f5vastl4boc.cloudfront.net/destination/video_1706601410811_cs_prod_1024.MP4"
                        ],
                        "width": 1080,
                        "type": "video",
                        "factor": 1,
                        "height": 1080
                    }
                ],
                "deepLink": null,
                "thumbnails": {
                    "desktop": "https://d35f5vastl4boc.cloudfront.net/image/BTS_bharat_taqdeer3_desktop.webp",
                    "seeall": "https://d35f5vastl4boc.cloudfront.net/image/BTS_bharat_taqdeer3_seeall.webp",
                    "mobile": "https://d35f5vastl4boc.cloudfront.net/image/BTS_bharat_taqdeer3_mobile.webp"
                },
                "user": {
                    "localisedFullName": {
                        "en": "Prabh Deep"
                    },
                    "profileExpiry": 0,
                    "fullName": "CokeStudioBharat",
                    "followersCount": 0,
                    "userName": "CSB",
                    "userId": "25b46803-c82b-496a-9a91-5946b6f19ed5",
                    "email": "cokestudiobharat@hww.com"
                },
                "artistsDetail": [
                    {
                        "fullName": "CokeStudioBharat",
                        "userId": "25b46803-c82b-496a-9a91-5946b6f19ed5"
                    },
                    {
                        "fullName": "Prabh Deep",
                        "userId": "87deea85-d52b-4840-9dca-e728604af6d6"
                    },
                    {
                        "fullName": "Rashmeet Kaur",
                        "userId": "e5a13e2d-b770-496c-9ff2-0f4229e861c8"
                    },
                    {
                        "fullName": "Donn Bhatt",
                        "userId": "c8e76d72-ee54-4b11-b7c6-b9fa5ffff4a8"
                    },
                    {
                        "fullName": "Sakur Khan",
                        "userId": "05ef21d5-ece5-4561-a940-1419b53fb21c"
                    }
                ],
                "status": "ACTIVE",
                "localisedTitle": {
                    "hi": "",
                    "en": "Taqdeer 4<br/> <span>Prabh Deep X Rashmeet Kaur X Donn Bhat X Sakur Khan Sufi</span>"
                },
                "_id": "6ed6a4c3-fa9b-46f5-af8b-8424e0c8ee9d"
            },
            "contentType": "video",
            "bannerUrl": "",
            "pristine_image": "",
            "topicId": "6ed6a4c3-fa9b-46f5-af8b-8424e0c8ee9d",
            "commentCount": 0,
            "userId": "25b46803-c82b-496a-9a91-5946b6f19ed5",
            "hashtags": [],
            "products": []
        },
        {
            "id": "2eb75cb3-cd1a-4e23-afc5-ef045d3d1ccc",
            "ordering": 5,
            "displayName": "Kya karie korimol 1<br/> <span>Aashima Mahajan X Alif X Noor Mohammad</span>",
            "firstName": "CSB",
            "lastName": "",
            "likeCount": 3,
            "playCount": "",
            "followers": 0,
            "description": "Kya karie korimol - AASHIMA MAHAJAN X ALIF X NOOR MOHAMMAD",
            "thumbnail": "https://d35f5vastl4boc.cloudfront.net/destination/video_1706601207034_thumb.0000001.jpg",
            "url": "https://d35f5vastl4boc.cloudfront.net/destination/video_1706601207034_thumb.0000001.jpg",
            "assetId": "2eb75cb3-cd1a-4e23-afc5-ef045d3d1ccc",
            "video": {
                "s3Url": "https://d35f5vastl4boc.cloudfront.net/video/videofile/video_1706601207034.mp4",
                "hashtags": [],
                "description": "Kya karie korimol - AASHIMA MAHAJAN X ALIF X NOOR MOHAMMAD",
                "videoId": "2eb75cb3-cd1a-4e23-afc5-ef045d3d1ccc",
                "likeCount": 3,
                "socialType": "gluedin",
                "title": "Kya karie korimol 1<br/> <span>Aashima Mahajan X Alif X Noor Mohammad</span>",
                "soundName": null,
                "videoOwnerName": "Aashima",
                "products": [],
                "shareCount": 2,
                "shoppable": false,
                "contentType": "video",
                "thumbnailUrl": "https://d35f5vastl4boc.cloudfront.net/destination/video_1706601207034_thumb.0000001.jpg",
                "localisedDescription": {
                    "hi": "",
                    "en": "Kya karie korimol - AASHIMA MAHAJAN X ALIF X NOOR MOHAMMAD"
                },
                "createdTimestamp": 1706601207314,
                "viewsCount": 17,
                "contentExpiry": 0,
                "userId": "25b46803-c82b-496a-9a91-5946b6f19ed5",
                "commentCount": 0,
                "labels": [],
                "topicId": "2eb75cb3-cd1a-4e23-afc5-ef045d3d1ccc",
                "contentUrls": [
                    {
                        "duration": 26,
                        "urls": [
                            "https://d35f5vastl4boc.cloudfront.net/destination/video_1706601207034_cs_prod_512.MP4",
                            "https://d35f5vastl4boc.cloudfront.net/destination/video_1706601207034_cs_prod_1024.MP4"
                        ],
                        "width": 1080,
                        "type": "video",
                        "factor": 1,
                        "height": 1080
                    }
                ],
                "deepLink": null,
                "thumbnails": {
                    "desktop": "https://d35f5vastl4boc.cloudfront.net/image/BTS_bharat_korimol1_desktop.webp",
                    "seeall": "https://d35f5vastl4boc.cloudfront.net/image/BTS_bharat_korimol1_seeall.webp",
                    "mobile": "https://d35f5vastl4boc.cloudfront.net/image/BTS_bharat_korimol1_mobile.webp"
                },
                "user": {
                    "localisedFullName": {
                        "en": "Aashima Mahajan"
                    },
                    "profileExpiry": 0,
                    "fullName": "CokeStudioBharat",
                    "followersCount": 0,
                    "userName": "CSB",
                    "userId": "25b46803-c82b-496a-9a91-5946b6f19ed5",
                    "email": "cokestudiobharat@hww.com"
                },
                "artistsDetail": [
                    {
                        "fullName": "CokeStudioBharat",
                        "userId": "25b46803-c82b-496a-9a91-5946b6f19ed5"
                    },
                    {
                        "fullName": "Aashima Mahajan",
                        "userId": "0c067a4e-d3a3-48d1-922b-7712eadc11c6"
                    },
                    {
                        "fullName": "Alif",
                        "userId": "132bab97-ca39-4486-bce6-d9c965848e43"
                    },
                    {
                        "fullName": "Noor Mohammad",
                        "userId": "1b1cdcb3-5196-40dc-9a98-6fd90696a008"
                    }
                ],
                "status": "ACTIVE",
                "localisedTitle": {
                    "hi": "",
                    "en": "Kya karie korimol 1<br/> <span>Aashima Mahajan X Alif X Noor Mohammad</span>"
                },
                "_id": "2eb75cb3-cd1a-4e23-afc5-ef045d3d1ccc"
            },
            "contentType": "video",
            "bannerUrl": "",
            "pristine_image": "",
            "topicId": "2eb75cb3-cd1a-4e23-afc5-ef045d3d1ccc",
            "commentCount": 0,
            "userId": "25b46803-c82b-496a-9a91-5946b6f19ed5",
            "hashtags": [],
            "products": []
        },
        {
            "id": "7d25db8a-c86e-4491-92b6-0a54192efefa",
            "ordering": 6,
            "displayName": "Kya karie korimol 2<br/> <span>Aashima Mahajan X Alif X Noor Mohammad</span>",
            "firstName": "CSB",
            "lastName": "",
            "likeCount": 2,
            "playCount": "",
            "followers": 0,
            "description": "Kya karie korimol - AASHIMA MAHAJAN X ALIF X NOOR MOHAMMAD",
            "thumbnail": "https://d35f5vastl4boc.cloudfront.net/destination/video_1706601248043_thumb.0000001.jpg",
            "url": "https://d35f5vastl4boc.cloudfront.net/destination/video_1706601248043_thumb.0000001.jpg",
            "assetId": "7d25db8a-c86e-4491-92b6-0a54192efefa",
            "video": {
                "s3Url": "https://d35f5vastl4boc.cloudfront.net/video/videofile/video_1706601248043.mp4",
                "hashtags": [],
                "description": "Kya karie korimol - AASHIMA MAHAJAN X ALIF X NOOR MOHAMMAD",
                "videoId": "7d25db8a-c86e-4491-92b6-0a54192efefa",
                "likeCount": 2,
                "socialType": "gluedin",
                "title": "Kya karie korimol 2<br/> <span>Aashima Mahajan X Alif X Noor Mohammad</span>",
                "soundName": null,
                "videoOwnerName": "Aashima",
                "products": [],
                "shareCount": 1,
                "shoppable": false,
                "contentType": "video",
                "thumbnailUrl": "https://d35f5vastl4boc.cloudfront.net/destination/video_1706601248043_thumb.0000001.jpg",
                "localisedDescription": {
                    "hi": "",
                    "en": "Kya karie korimol - AASHIMA MAHAJAN X ALIF X NOOR MOHAMMAD"
                },
                "createdTimestamp": 1706601248444,
                "viewsCount": 27,
                "contentExpiry": 0,
                "userId": "25b46803-c82b-496a-9a91-5946b6f19ed5",
                "commentCount": 0,
                "labels": [],
                "topicId": "7d25db8a-c86e-4491-92b6-0a54192efefa",
                "contentUrls": [
                    {
                        "duration": 25,
                        "urls": [
                            "https://d35f5vastl4boc.cloudfront.net/destination/video_1706601248043_cs_prod_512.MP4",
                            "https://d35f5vastl4boc.cloudfront.net/destination/video_1706601248043_cs_prod_1024.MP4"
                        ],
                        "width": 1044,
                        "type": "video",
                        "factor": 1,
                        "height": 1044
                    }
                ],
                "deepLink": null,
                "thumbnails": {
                    "desktop": "https://d35f5vastl4boc.cloudfront.net/image/BTS_bharat_korimol2_desktop.webp",
                    "seeall": "https://d35f5vastl4boc.cloudfront.net/image/BTS_bharat_korimol2_seeall.webp",
                    "mobile": "https://d35f5vastl4boc.cloudfront.net/image/BTS_bharat_korimol2_mobile.webp"
                },
                "user": {
                    "localisedFullName": {
                        "en": "Aashima Mahajan"
                    },
                    "profileExpiry": 0,
                    "fullName": "CokeStudioBharat",
                    "followersCount": 0,
                    "userName": "CSB",
                    "userId": "25b46803-c82b-496a-9a91-5946b6f19ed5",
                    "email": "cokestudiobharat@hww.com"
                },
                "artistsDetail": [
                    {
                        "fullName": "CokeStudioBharat",
                        "userId": "25b46803-c82b-496a-9a91-5946b6f19ed5"
                    },
                    {
                        "fullName": "Aashima Mahajan",
                        "userId": "0c067a4e-d3a3-48d1-922b-7712eadc11c6"
                    },
                    {
                        "fullName": "Alif",
                        "userId": "132bab97-ca39-4486-bce6-d9c965848e43"
                    },
                    {
                        "fullName": "Noor Mohammad",
                        "userId": "1b1cdcb3-5196-40dc-9a98-6fd90696a008"
                    }
                ],
                "status": "ACTIVE",
                "localisedTitle": {
                    "hi": "",
                    "en": "Kya karie korimol 2<br/> <span>Aashima Mahajan X Alif X Noor Mohammad</span>"
                },
                "_id": "7d25db8a-c86e-4491-92b6-0a54192efefa"
            },
            "contentType": "video",
            "bannerUrl": "",
            "pristine_image": "",
            "topicId": "7d25db8a-c86e-4491-92b6-0a54192efefa",
            "commentCount": 0,
            "userId": "25b46803-c82b-496a-9a91-5946b6f19ed5",
            "hashtags": [],
            "products": []
        },
        {
            "id": "617f6b3a-7a68-43f1-a6b8-6e5c6953bbe2",
            "ordering": 7,
            "displayName": "Kya karie korimol 3<br/> <span>Aashima Mahajan X Alif X Noor Mohammad</span>",
            "firstName": "CSB",
            "lastName": "",
            "likeCount": 3,
            "playCount": "",
            "followers": 0,
            "description": "Kya karie korimol - AASHIMA MAHAJAN X ALIF X NOOR MOHAMMAD",
            "thumbnail": "https://d35f5vastl4boc.cloudfront.net/destination/video_1706601285237_thumb.0000001.jpg",
            "url": "https://d35f5vastl4boc.cloudfront.net/destination/video_1706601285237_thumb.0000001.jpg",
            "assetId": "617f6b3a-7a68-43f1-a6b8-6e5c6953bbe2",
            "video": {
                "s3Url": "https://d35f5vastl4boc.cloudfront.net/video/videofile/video_1706601285237.mp4",
                "hashtags": [],
                "description": "Kya karie korimol - AASHIMA MAHAJAN X ALIF X NOOR MOHAMMAD",
                "videoId": "617f6b3a-7a68-43f1-a6b8-6e5c6953bbe2",
                "likeCount": 3,
                "socialType": "gluedin",
                "title": "Kya karie korimol 3<br/> <span>Aashima Mahajan X Alif X Noor Mohammad</span>",
                "soundName": null,
                "videoOwnerName": "Aashima",
                "products": [],
                "shareCount": 0,
                "shoppable": false,
                "contentType": "video",
                "thumbnailUrl": "https://d35f5vastl4boc.cloudfront.net/destination/video_1706601285237_thumb.0000001.jpg",
                "localisedDescription": {
                    "hi": "",
                    "en": "Kya karie korimol - AASHIMA MAHAJAN X ALIF X NOOR MOHAMMAD"
                },
                "createdTimestamp": 1706601285558,
                "viewsCount": 279,
                "contentExpiry": 0,
                "userId": "25b46803-c82b-496a-9a91-5946b6f19ed5",
                "commentCount": 0,
                "labels": [],
                "topicId": "617f6b3a-7a68-43f1-a6b8-6e5c6953bbe2",
                "contentUrls": [
                    {
                        "duration": 49,
                        "urls": [
                            "https://d35f5vastl4boc.cloudfront.net/destination/video_1706601285237_cs_prod_512.MP4",
                            "https://d35f5vastl4boc.cloudfront.net/destination/video_1706601285237_cs_prod_1024.MP4"
                        ],
                        "width": 1080,
                        "type": "video",
                        "factor": 1,
                        "height": 1080
                    }
                ],
                "deepLink": null,
                "thumbnails": {
                    "desktop": "https://d35f5vastl4boc.cloudfront.net/image/BTS_bharat_korimol3_desktop.webp",
                    "seeall": "https://d35f5vastl4boc.cloudfront.net/image/BTS_bharat_korimol3_seeall.webp",
                    "mobile": "https://d35f5vastl4boc.cloudfront.net/image/BTS_bharat_korimol3_mobile.webp"
                },
                "user": {
                    "localisedFullName": {
                        "en": "Aashima Mahajan"
                    },
                    "profileExpiry": 0,
                    "fullName": "CokeStudioBharat",
                    "followersCount": 0,
                    "userName": "CSB",
                    "userId": "25b46803-c82b-496a-9a91-5946b6f19ed5",
                    "email": "cokestudiobharat@hww.com"
                },
                "artistsDetail": [
                    {
                        "fullName": "CokeStudioBharat",
                        "userId": "25b46803-c82b-496a-9a91-5946b6f19ed5"
                    },
                    {
                        "fullName": "Aashima Mahajan",
                        "userId": "0c067a4e-d3a3-48d1-922b-7712eadc11c6"
                    },
                    {
                        "fullName": "Alif",
                        "userId": "132bab97-ca39-4486-bce6-d9c965848e43"
                    },
                    {
                        "fullName": "Noor Mohammad",
                        "userId": "1b1cdcb3-5196-40dc-9a98-6fd90696a008"
                    }
                ],
                "status": "ACTIVE",
                "localisedTitle": {
                    "hi": "",
                    "en": "Kya karie korimol 3<br/> <span>Aashima Mahajan X Alif X Noor Mohammad</span>"
                },
                "_id": "617f6b3a-7a68-43f1-a6b8-6e5c6953bbe2"
            },
            "contentType": "video",
            "bannerUrl": "",
            "pristine_image": "",
            "topicId": "617f6b3a-7a68-43f1-a6b8-6e5c6953bbe2",
            "commentCount": 0,
            "userId": "25b46803-c82b-496a-9a91-5946b6f19ed5",
            "hashtags": [],
            "products": []
        },
        {
            "id": "9d07855e-19e7-4298-9181-2c6eea0dfdea",
            "ordering": 8,
            "displayName": "Holi re rasiya 1<br/> <span>Maithili Thakur X Seedhe Maut X Ravi Kishan X Mahan</span>",
            "firstName": "CSB",
            "lastName": "",
            "likeCount": 1,
            "playCount": "",
            "followers": 0,
            "description": "MAITHILI THAKUR X SEEDHE MAUT X RAVI KISHAN X MAHAN",
            "thumbnail": "https://d35f5vastl4boc.cloudfront.net/destination/video_1706601109590_thumb.0000001.jpg",
            "url": "https://d35f5vastl4boc.cloudfront.net/destination/video_1706601109590_thumb.0000001.jpg",
            "assetId": "9d07855e-19e7-4298-9181-2c6eea0dfdea",
            "video": {
                "s3Url": "https://d35f5vastl4boc.cloudfront.net/video/videofile/video_1706601109590.mp4",
                "hashtags": [],
                "description": "MAITHILI THAKUR X SEEDHE MAUT X RAVI KISHAN X MAHAN",
                "videoId": "9d07855e-19e7-4298-9181-2c6eea0dfdea",
                "likeCount": 1,
                "socialType": "gluedin",
                "title": "Holi re rasiya 1<br/> <span>Maithili Thakur X Seedhe Maut X Ravi Kishan X Mahan</span>",
                "soundName": null,
                "videoOwnerName": "maithili",
                "products": [],
                "shareCount": 0,
                "shoppable": false,
                "contentType": "video",
                "thumbnailUrl": "https://d35f5vastl4boc.cloudfront.net/destination/video_1706601109590_thumb.0000001.jpg",
                "localisedDescription": {
                    "hi": "",
                    "en": "MAITHILI THAKUR X SEEDHE MAUT X RAVI KISHAN X MAHAN"
                },
                "createdTimestamp": 1706601110155,
                "viewsCount": 13,
                "contentExpiry": 0,
                "userId": "25b46803-c82b-496a-9a91-5946b6f19ed5",
                "commentCount": 0,
                "labels": [],
                "topicId": "9d07855e-19e7-4298-9181-2c6eea0dfdea",
                "contentUrls": [
                    {
                        "duration": 107,
                        "urls": [
                            "https://d35f5vastl4boc.cloudfront.net/destination/video_1706601109590_cs_prod_512.MP4",
                            "https://d35f5vastl4boc.cloudfront.net/destination/video_1706601109590_cs_prod_1024.MP4"
                        ],
                        "width": 1080,
                        "type": "video",
                        "factor": 1,
                        "height": 1080
                    }
                ],
                "deepLink": null,
                "thumbnails": {
                    "desktop": "https://d35f5vastl4boc.cloudfront.net/image/BTS_bharat_holi_re_rasiya2_desktop.webp",
                    "seeall": "https://d35f5vastl4boc.cloudfront.net/image/BTS_bharat_holi_re_rasiya2_seeall.webp",
                    "mobile": "https://d35f5vastl4boc.cloudfront.net/image/BTS_bharat_holi_re_rasiya2_mobile.webp"
                },
                "user": {
                    "localisedFullName": {
                        "en": "Maithili Thakur"
                    },
                    "profileExpiry": 0,
                    "fullName": "CokeStudioBharat",
                    "followersCount": 0,
                    "userName": "CSB",
                    "userId": "25b46803-c82b-496a-9a91-5946b6f19ed5",
                    "email": "cokestudiobharat@hww.com"
                },
                "artistsDetail": [
                    {
                        "fullName": "CokeStudioBharat",
                        "userId": "25b46803-c82b-496a-9a91-5946b6f19ed5"
                    },
                    {
                        "fullName": "Maithili Thakur",
                        "userId": "f863b8c4-d72f-44f4-81ab-2038ada5adcb"
                    },
                    {
                        "fullName": "Seedhe Maut",
                        "userId": "a93fe798-1612-4351-a645-0394daf92fa4"
                    },
                    {
                        "fullName": "Ravi Kishan",
                        "userId": "373ce915-2ad7-4d75-9c83-c85c0eeba2ed"
                    },
                    {
                        "fullName": "Mahan",
                        "userId": "f7f6c930-be0c-465e-8c3e-e956f8f1ebf1"
                    }
                ],
                "status": "ACTIVE",
                "localisedTitle": {
                    "hi": "",
                    "en": "Holi re rasiya 1<br/> <span>Maithili Thakur X Seedhe Maut X Ravi Kishan X Mahan</span>"
                },
                "_id": "9d07855e-19e7-4298-9181-2c6eea0dfdea"
            },
            "contentType": "video",
            "bannerUrl": "",
            "pristine_image": "",
            "topicId": "9d07855e-19e7-4298-9181-2c6eea0dfdea",
            "commentCount": 0,
            "userId": "25b46803-c82b-496a-9a91-5946b6f19ed5",
            "hashtags": [],
            "products": []
        },
        {
            "id": "b495f2f6-df78-4610-b053-e80304e5aecc",
            "ordering": 9,
            "displayName": "Holi re rasiya 2<br/> <span>Maithili Thakur X Seedhe Maut X Ravi Kishan X Mahan</span>",
            "firstName": "CSB",
            "lastName": "",
            "likeCount": 0,
            "playCount": "",
            "followers": 0,
            "description": "MAITHILI THAKUR X SEEDHE MAUT X RAVI KISHAN X MAHAN",
            "thumbnail": "https://d35f5vastl4boc.cloudfront.net/destination/video_1706601154669_thumb.0000001.jpg",
            "url": "https://d35f5vastl4boc.cloudfront.net/destination/video_1706601154669_thumb.0000001.jpg",
            "assetId": "b495f2f6-df78-4610-b053-e80304e5aecc",
            "video": {
                "s3Url": "https://d35f5vastl4boc.cloudfront.net/video/videofile/video_1706601154669.mp4",
                "hashtags": [],
                "description": "MAITHILI THAKUR X SEEDHE MAUT X RAVI KISHAN X MAHAN",
                "videoId": "b495f2f6-df78-4610-b053-e80304e5aecc",
                "likeCount": 0,
                "socialType": "gluedin",
                "title": "Holi re rasiya 2<br/> <span>Maithili Thakur X Seedhe Maut X Ravi Kishan X Mahan</span>",
                "soundName": null,
                "videoOwnerName": "maithili",
                "products": [],
                "shareCount": 0,
                "shoppable": false,
                "contentType": "video",
                "thumbnailUrl": "https://d35f5vastl4boc.cloudfront.net/destination/video_1706601154669_thumb.0000001.jpg",
                "localisedDescription": {
                    "hi": "",
                    "en": "MAITHILI THAKUR X SEEDHE MAUT X RAVI KISHAN X MAHAN"
                },
                "createdTimestamp": 1706601155067,
                "viewsCount": 8,
                "contentExpiry": 0,
                "userId": "25b46803-c82b-496a-9a91-5946b6f19ed5",
                "commentCount": 0,
                "labels": [],
                "topicId": "b495f2f6-df78-4610-b053-e80304e5aecc",
                "contentUrls": [
                    {
                        "duration": 28,
                        "urls": [
                            "https://d35f5vastl4boc.cloudfront.net/destination/video_1706601154669_cs_prod_512.MP4",
                            "https://d35f5vastl4boc.cloudfront.net/destination/video_1706601154669_cs_prod_1024.MP4"
                        ],
                        "width": 1080,
                        "type": "video",
                        "factor": 1,
                        "height": 1080
                    }
                ],
                "deepLink": null,
                "thumbnails": {
                    "desktop": "https://d35f5vastl4boc.cloudfront.net/image/BTS_bharat_holi_re_rasiya1_desktop.webp",
                    "seeall": "https://d35f5vastl4boc.cloudfront.net/image/BTS_bharat_holi_re_rasiya1_seeall.webp",
                    "mobile": "https://d35f5vastl4boc.cloudfront.net/image/BTS_bharat_holi_re_rasiya1_mobile.webp"
                },
                "user": {
                    "localisedFullName": {
                        "en": "Maithili Thakur"
                    },
                    "profileExpiry": 0,
                    "fullName": "CokeStudioBharat",
                    "followersCount": 0,
                    "userName": "CSB",
                    "userId": "25b46803-c82b-496a-9a91-5946b6f19ed5",
                    "email": "cokestudiobharat@hww.com"
                },
                "artistsDetail": [
                    {
                        "fullName": "CokeStudioBharat",
                        "userId": "25b46803-c82b-496a-9a91-5946b6f19ed5"
                    },
                    {
                        "fullName": "Maithili Thakur",
                        "userId": "f863b8c4-d72f-44f4-81ab-2038ada5adcb"
                    },
                    {
                        "fullName": "Seedhe Maut",
                        "userId": "a93fe798-1612-4351-a645-0394daf92fa4"
                    },
                    {
                        "fullName": "Ravi Kishan",
                        "userId": "373ce915-2ad7-4d75-9c83-c85c0eeba2ed"
                    },
                    {
                        "fullName": "Mahan",
                        "userId": "f7f6c930-be0c-465e-8c3e-e956f8f1ebf1"
                    }
                ],
                "status": "ACTIVE",
                "localisedTitle": {
                    "hi": "",
                    "en": "Holi re rasiya 2<br/> <span>Maithili Thakur X Seedhe Maut X Ravi Kishan X Mahan</span>"
                },
                "_id": "b495f2f6-df78-4610-b053-e80304e5aecc"
            },
            "contentType": "video",
            "bannerUrl": "",
            "pristine_image": "",
            "topicId": "b495f2f6-df78-4610-b053-e80304e5aecc",
            "commentCount": 0,
            "userId": "25b46803-c82b-496a-9a91-5946b6f19ed5",
            "hashtags": [],
            "products": []
        },
        {
            "id": "42491c8a-4836-4f6d-9617-ecacf3a9a2a4",
            "ordering": 10,
            "displayName": "Geejaga 1<br/> <span>Sanjith Hegde X Charan Raj</span>",
            "firstName": "CSB",
            "lastName": "",
            "likeCount": 1,
            "playCount": "",
            "followers": 0,
            "description": "SANJITH HEGDE X CHARAN RAJ",
            "thumbnail": "https://d35f5vastl4boc.cloudfront.net/destination/video_1706596324765_thumb.0000001.jpg",
            "url": "https://d35f5vastl4boc.cloudfront.net/destination/video_1706596324765_thumb.0000001.jpg",
            "assetId": "42491c8a-4836-4f6d-9617-ecacf3a9a2a4",
            "video": {
                "s3Url": "https://d35f5vastl4boc.cloudfront.net/video/videofile/video_1706596324765.mp4",
                "hashtags": [],
                "description": "SANJITH HEGDE X CHARAN RAJ",
                "videoId": "42491c8a-4836-4f6d-9617-ecacf3a9a2a4",
                "likeCount": 1,
                "socialType": "gluedin",
                "title": "Geejaga 1<br/> <span>Sanjith Hegde X Charan Raj</span>",
                "soundName": null,
                "videoOwnerName": "sanjith",
                "products": [],
                "shareCount": 0,
                "shoppable": false,
                "contentType": "video",
                "thumbnailUrl": "https://d35f5vastl4boc.cloudfront.net/destination/video_1706596324765_thumb.0000001.jpg",
                "localisedDescription": {
                    "hi": "",
                    "en": "SANJITH HEGDE X CHARAN RAJ"
                },
                "createdTimestamp": 1706596325304,
                "viewsCount": 5,
                "contentExpiry": 0,
                "userId": "25b46803-c82b-496a-9a91-5946b6f19ed5",
                "commentCount": 0,
                "labels": [],
                "topicId": "42491c8a-4836-4f6d-9617-ecacf3a9a2a4",
                "contentUrls": [
                    {
                        "duration": 184,
                        "urls": [
                            "https://d35f5vastl4boc.cloudfront.net/destination/video_1706596324765_cs_prod_512.MP4",
                            "https://d35f5vastl4boc.cloudfront.net/destination/video_1706596324765_cs_prod_1024.MP4"
                        ],
                        "width": 1920,
                        "type": "video",
                        "factor": 1.78,
                        "height": 1080
                    }
                ],
                "deepLink": null,
                "thumbnails": {
                    "desktop": "https://d35f5vastl4boc.cloudfront.net/image/geejaga2_desktop.webp",
                    "seeall": "https://d35f5vastl4boc.cloudfront.net/image/geejaga2_seeall.webp",
                    "mobile": "https://d35f5vastl4boc.cloudfront.net/image/geejaga2_mobile.webp"
                },
                "user": {
                    "localisedFullName": {
                        "en": "Sanjith Hegde"
                    },
                    "profileExpiry": 0,
                    "fullName": "CokeStudioBharat",
                    "followersCount": 0,
                    "userName": "CSB",
                    "userId": "25b46803-c82b-496a-9a91-5946b6f19ed5",
                    "email": "cokestudiobharat@hww.com"
                },
                "artistsDetail": [
                    {
                        "fullName": "CokeStudioBharat",
                        "userId": "25b46803-c82b-496a-9a91-5946b6f19ed5"
                    },
                    {
                        "fullName": "Sanjith Hegde",
                        "userId": "e31557ba-979a-4f60-b7cd-0e1c3ff96793"
                    },
                    {
                        "fullName": "Charan Raj",
                        "userId": "e28e8aee-8d43-4e6b-b081-be3768010841"
                    }
                ],
                "status": "ACTIVE",
                "localisedTitle": {
                    "hi": "",
                    "en": "Geejaga 1<br/> <span>Sanjith Hegde X Charan Raj</span>"
                },
                "_id": "42491c8a-4836-4f6d-9617-ecacf3a9a2a4"
            },
            "contentType": "video",
            "bannerUrl": "",
            "pristine_image": "",
            "topicId": "42491c8a-4836-4f6d-9617-ecacf3a9a2a4",
            "commentCount": 0,
            "userId": "25b46803-c82b-496a-9a91-5946b6f19ed5",
            "hashtags": [],
            "products": []
        },
        {
            "id": "2c37739b-53c6-4971-9519-f83bce799547",
            "ordering": 11,
            "displayName": "Geejaga 2<br/> <span>Sanjith Hegde X Charan Raj</span>",
            "firstName": "CSB",
            "lastName": "",
            "likeCount": 0,
            "playCount": "",
            "followers": 0,
            "description": "SANJITH HEGDE X CHARAN RAJ",
            "thumbnail": "https://d35f5vastl4boc.cloudfront.net/destination/video_1706601052981_thumb.0000001.jpg",
            "url": "https://d35f5vastl4boc.cloudfront.net/destination/video_1706601052981_thumb.0000001.jpg",
            "assetId": "2c37739b-53c6-4971-9519-f83bce799547",
            "video": {
                "s3Url": "https://d35f5vastl4boc.cloudfront.net/video/videofile/video_1706601052981.mp4",
                "hashtags": [],
                "description": "SANJITH HEGDE X CHARAN RAJ",
                "videoId": "2c37739b-53c6-4971-9519-f83bce799547",
                "likeCount": 0,
                "socialType": "gluedin",
                "title": "Geejaga 2<br/> <span>Sanjith Hegde X Charan Raj</span>",
                "soundName": null,
                "videoOwnerName": "sanjith",
                "products": [],
                "shareCount": 0,
                "shoppable": false,
                "contentType": "video",
                "thumbnailUrl": "https://d35f5vastl4boc.cloudfront.net/destination/video_1706601052981_thumb.0000001.jpg",
                "localisedDescription": {
                    "hi": "",
                    "en": "SANJITH HEGDE X CHARAN RAJ"
                },
                "createdTimestamp": 1706601053405,
                "viewsCount": 15,
                "contentExpiry": 0,
                "userId": "25b46803-c82b-496a-9a91-5946b6f19ed5",
                "commentCount": 0,
                "labels": [],
                "topicId": "2c37739b-53c6-4971-9519-f83bce799547",
                "contentUrls": [
                    {
                        "duration": 33,
                        "urls": [
                            "https://d35f5vastl4boc.cloudfront.net/destination/video_1706601052981_cs_prod_512.MP4",
                            "https://d35f5vastl4boc.cloudfront.net/destination/video_1706601052981_cs_prod_1024.MP4"
                        ],
                        "width": 1080,
                        "type": "video",
                        "factor": 1,
                        "height": 1080
                    }
                ],
                "deepLink": null,
                "thumbnails": {
                    "desktop": "https://d35f5vastl4boc.cloudfront.net/image/BTS_bharat_geejaga1_desktop.webp",
                    "seeall": "https://d35f5vastl4boc.cloudfront.net/image/BTS_bharat_geejaga1_seeall.webp",
                    "mobile": "https://d35f5vastl4boc.cloudfront.net/image/BTS_bharat_geejaga1_mobile.webp"
                },
                "user": {
                    "localisedFullName": {
                        "en": "Sanjith Hegde"
                    },
                    "profileExpiry": 0,
                    "fullName": "CokeStudioBharat",
                    "followersCount": 0,
                    "userName": "CSB",
                    "userId": "25b46803-c82b-496a-9a91-5946b6f19ed5",
                    "email": "cokestudiobharat@hww.com"
                },
                "artistsDetail": [
                    {
                        "fullName": "CokeStudioBharat",
                        "userId": "25b46803-c82b-496a-9a91-5946b6f19ed5"
                    },
                    {
                        "fullName": "Sanjith Hegde",
                        "userId": "e31557ba-979a-4f60-b7cd-0e1c3ff96793"
                    },
                    {
                        "fullName": "Charan Raj",
                        "userId": "e28e8aee-8d43-4e6b-b081-be3768010841"
                    }
                ],
                "status": "ACTIVE",
                "localisedTitle": {
                    "hi": "",
                    "en": "Geejaga 2<br/> <span>Sanjith Hegde X Charan Raj</span>"
                },
                "_id": "2c37739b-53c6-4971-9519-f83bce799547"
            },
            "contentType": "video",
            "bannerUrl": "",
            "pristine_image": "",
            "topicId": "2c37739b-53c6-4971-9519-f83bce799547",
            "commentCount": 0,
            "userId": "25b46803-c82b-496a-9a91-5946b6f19ed5",
            "hashtags": [],
            "products": []
        },
        {
            "id": "0377f201-4339-4f6a-996f-fdf34d697f93",
            "ordering": 12,
            "displayName": "Udja 1<br/> <span>Burrah X Jasleen X Oaff X Savera</span>",
            "firstName": "CSB",
            "lastName": "",
            "likeCount": 1,
            "playCount": "",
            "followers": 0,
            "description": "Udja - BURRAH X JASLEEN X OAFF X SAVERA",
            "thumbnail": "https://d35f5vastl4boc.cloudfront.net/destination/video_1706597280059_thumb.0000001.jpg",
            "url": "https://d35f5vastl4boc.cloudfront.net/destination/video_1706597280059_thumb.0000001.jpg",
            "assetId": "0377f201-4339-4f6a-996f-fdf34d697f93",
            "video": {
                "s3Url": "https://d35f5vastl4boc.cloudfront.net/video/videofile/video_1706597280059.mp4",
                "hashtags": [],
                "description": "Udja - BURRAH X JASLEEN X OAFF X SAVERA",
                "videoId": "0377f201-4339-4f6a-996f-fdf34d697f93",
                "likeCount": 1,
                "socialType": "gluedin",
                "title": "Udja 1<br/> <span>Burrah X Jasleen X Oaff X Savera</span>",
                "soundName": null,
                "videoOwnerName": "Burrah",
                "products": [],
                "shareCount": 5,
                "shoppable": false,
                "contentType": "video",
                "thumbnailUrl": "https://d35f5vastl4boc.cloudfront.net/destination/video_1706597280059_thumb.0000001.jpg",
                "localisedDescription": {
                    "hi": "",
                    "en": "Udja - BURRAH X JASLEEN X OAFF X SAVERA"
                },
                "createdTimestamp": 1706597280727,
                "viewsCount": 16,
                "contentExpiry": 0,
                "userId": "25b46803-c82b-496a-9a91-5946b6f19ed5",
                "commentCount": 0,
                "labels": [],
                "topicId": "0377f201-4339-4f6a-996f-fdf34d697f93",
                "contentUrls": [
                    {
                        "duration": 265,
                        "urls": [
                            "https://d35f5vastl4boc.cloudfront.net/destination/video_1706597280059_cs_prod_512.MP4",
                            "https://d35f5vastl4boc.cloudfront.net/destination/video_1706597280059_cs_prod_1024.MP4"
                        ],
                        "width": 1920,
                        "type": "video",
                        "factor": 1.78,
                        "height": 1080
                    }
                ],
                "deepLink": null,
                "thumbnails": {
                    "desktop": "https://d35f5vastl4boc.cloudfront.net/image/BTS_bharat_rashmeet1_desktop.webp",
                    "seeall": "https://d35f5vastl4boc.cloudfront.net/image/BTS_bharat_rashmeet1_seeall.webp",
                    "mobile": "https://d35f5vastl4boc.cloudfront.net/image/BTS_bharat_rashmeet1_mobile.webp"
                },
                "user": {
                    "localisedFullName": {
                        "en": "Burrah"
                    },
                    "profileExpiry": 0,
                    "fullName": "CokeStudioBharat",
                    "followersCount": 0,
                    "userName": "CSB",
                    "userId": "25b46803-c82b-496a-9a91-5946b6f19ed5",
                    "email": "cokestudiobharat@hww.com"
                },
                "artistsDetail": [
                    {
                        "fullName": "Melodies",
                        "userId": "f2d42b60-af38-4ab0-9199-48d0814d42ae"
                    },
                    {
                        "fullName": "Burrah",
                        "userId": "617bc63d-89c8-41dc-8005-67ee470826cd"
                    },
                    {
                        "fullName": "Jasleen Royal",
                        "userId": "80a615a0-a431-49d5-962b-9b98b9142f13"
                    },
                    {
                        "fullName": "Oaff",
                        "userId": "7f22a1ca-098f-4e41-8da2-5df583ac69ce"
                    },
                    {
                        "fullName": "Savera",
                        "userId": "659a2973-03ef-40ef-80d5-4b17e965f0ff"
                    }
                ],
                "status": "ACTIVE",
                "localisedTitle": {
                    "hi": "",
                    "en": "Udja 1<br/> <span>Burrah X Jasleen X Oaff X Savera</span>"
                },
                "_id": "0377f201-4339-4f6a-996f-fdf34d697f93"
            },
            "contentType": "video",
            "bannerUrl": "",
            "pristine_image": "",
            "topicId": "0377f201-4339-4f6a-996f-fdf34d697f93",
            "commentCount": 0,
            "userId": "25b46803-c82b-496a-9a91-5946b6f19ed5",
            "hashtags": [],
            "products": []
        },
        {
            "id": "afd167ec-6123-4f52-8ea1-dfdbd8d61311",
            "ordering": 13,
            "displayName": "Udja 2<br/> <span>Burrah X Jasleen X Oaff X Savera</span>",
            "firstName": "CSB",
            "lastName": "",
            "likeCount": 0,
            "playCount": "",
            "followers": 0,
            "description": "Udja - BURRAH X JASLEEN X OAFF X SAVERA",
            "thumbnail": "https://d35f5vastl4boc.cloudfront.net/destination/video_1706601535640_thumb.0000001.jpg",
            "url": "https://d35f5vastl4boc.cloudfront.net/destination/video_1706601535640_thumb.0000001.jpg",
            "assetId": "afd167ec-6123-4f52-8ea1-dfdbd8d61311",
            "video": {
                "s3Url": "https://d35f5vastl4boc.cloudfront.net/video/videofile/video_1706601535640.mp4",
                "hashtags": [],
                "description": "Udja - BURRAH X JASLEEN X OAFF X SAVERA",
                "videoId": "afd167ec-6123-4f52-8ea1-dfdbd8d61311",
                "likeCount": 0,
                "socialType": "gluedin",
                "title": "Udja 2<br/> <span>Burrah X Jasleen X Oaff X Savera</span>",
                "soundName": null,
                "videoOwnerName": "Burrah",
                "products": [],
                "shareCount": 0,
                "shoppable": false,
                "contentType": "video",
                "thumbnailUrl": "https://d35f5vastl4boc.cloudfront.net/destination/video_1706601535640_thumb.0000001.jpg",
                "localisedDescription": {
                    "hi": "",
                    "en": "Udja - BURRAH X JASLEEN X OAFF X SAVERA"
                },
                "createdTimestamp": 1706601535910,
                "viewsCount": 12,
                "contentExpiry": 0,
                "userId": "25b46803-c82b-496a-9a91-5946b6f19ed5",
                "commentCount": 0,
                "labels": [],
                "topicId": "afd167ec-6123-4f52-8ea1-dfdbd8d61311",
                "contentUrls": [
                    {
                        "duration": 16,
                        "urls": [
                            "https://d35f5vastl4boc.cloudfront.net/destination/video_1706601535640_cs_prod_512.MP4",
                            "https://d35f5vastl4boc.cloudfront.net/destination/video_1706601535640_cs_prod_1024.MP4"
                        ],
                        "width": 1080,
                        "type": "video",
                        "factor": 0.56,
                        "height": 1920
                    }
                ],
                "deepLink": null,
                "thumbnails": {
                    "desktop": "https://d35f5vastl4boc.cloudfront.net/image/BTS_bharat_oaff_n_savera1_desktop.webp",
                    "seeall": "https://d35f5vastl4boc.cloudfront.net/image/BTS_bharat_oaff_n_savera1_seeeall.webp",
                    "mobile": "https://d35f5vastl4boc.cloudfront.net/image/BTS_bharat_oaff_n_savera1_mobile.webp"
                },
                "user": {
                    "localisedFullName": {
                        "en": "Burrah"
                    },
                    "profileExpiry": 0,
                    "fullName": "CokeStudioBharat",
                    "followersCount": 0,
                    "userName": "CSB",
                    "userId": "25b46803-c82b-496a-9a91-5946b6f19ed5",
                    "email": "cokestudiobharat@hww.com"
                },
                "artistsDetail": [
                    {
                        "fullName": "CokeStudioBharat",
                        "userId": "25b46803-c82b-496a-9a91-5946b6f19ed5"
                    },
                    {
                        "fullName": "Burrah",
                        "userId": "617bc63d-89c8-41dc-8005-67ee470826cd"
                    },
                    {
                        "fullName": "Jasleen Royal",
                        "userId": "80a615a0-a431-49d5-962b-9b98b9142f13"
                    },
                    {
                        "fullName": "Oaff",
                        "userId": "7f22a1ca-098f-4e41-8da2-5df583ac69ce"
                    },
                    {
                        "fullName": "Savera",
                        "userId": "659a2973-03ef-40ef-80d5-4b17e965f0ff"
                    }
                ],
                "status": "ACTIVE",
                "localisedTitle": {
                    "hi": "",
                    "en": "Udja 2<br/> <span>Burrah X Jasleen X Oaff X Savera</span>"
                },
                "_id": "afd167ec-6123-4f52-8ea1-dfdbd8d61311"
            },
            "contentType": "video",
            "bannerUrl": "",
            "pristine_image": "",
            "topicId": "afd167ec-6123-4f52-8ea1-dfdbd8d61311",
            "commentCount": 0,
            "userId": "25b46803-c82b-496a-9a91-5946b6f19ed5",
            "hashtags": [],
            "products": []
        }
    ],
    "likedVideos": []
}

export const staticArtistData = {
    "railId": "5be40002-ab73-452b-a6dc-58b8bf40beb5",
    "railName": "Artist",
    "itemCount": 27,
    "type": "two_line_circular",
    "position": 2,
    "contentType": "user",
    "thumbnailUrl": "",
    "placement": "discover",
    "itemList": [
        {
            "id": "0c067a4e-d3a3-48d1-922b-7712eadc11c6",
            "ordering": 0,
            "displayName": "",
            "firstName": "Aashima Mahajan",
            "lastName": "",
            "likeCount": 0,
            "playCount": 0,
            "viewCount": 0,
            "followers": 0,
            "description": "The very soulful Aashima Mahajan happens to be a very well-known Indian musician, playback singer, performer, and actor. Having been trained in Indian and Western Classical (Opera), she has given her voice to several celebrated songs.",
            "duration": 0,
            "thumbnail": "https://d35f5vastl4boc.cloudfront.net/profileImage/thumbnail_aashima.webp",
            "url": "https://d35f5vastl4boc.cloudfront.net/profileImage/thumbnail_aashima.webp",
            "assetId": "0c067a4e-d3a3-48d1-922b-7712eadc11c6",
            "contentType": "",
            "bannerUrl": "https://d35f5vastl4boc.cloudfront.net/profileImage/detail_aashima.webp",
            "pristine_image": "",
            "profile": {
                "_id": "0c067a4e-d3a3-48d1-922b-7712eadc11c6",
                "userId": "0c067a4e-d3a3-48d1-922b-7712eadc11c6",
                "fullName": "Aashima Mahajan",
                "userName": "Aashima Mahajan",
                "email": "aashima@hww.com",
                "description": "The very soulful Aashima Mahajan happens to be a very well-known Indian musician, playback singer, performer, and actor. Having been trained in Indian and Western Classical (Opera), she has given her voice to several celebrated songs.",
                "status": "ACTIVE",
                "profileImageUrl": "https://d35f5vastl4boc.cloudfront.net/profileImage/thumbnail_aashima.webp",
                "followersCount": 0,
                "followingCount": 0,
                "videoCount": 0,
                "bookmarkCount": 0,
                "backgroundImage": "https://d35f5vastl4boc.cloudfront.net/profileImage/detail_aashima.webp"
            }
        },
        {
            "id": "1b1cdcb3-5196-40dc-9a98-6fd90696a008",
            "ordering": 1,
            "displayName": "",
            "firstName": "Noor Mohammad",
            "lastName": "",
            "likeCount": 0,
            "playCount": 0,
            "viewCount": 0,
            "followers": 0,
            "description": "Noor Mohammad, an Indian traditional Sufi music performer and songwriter, from Baki-Aker village in North Kashmir.",
            "duration": 0,
            "thumbnail": "https://d35f5vastl4boc.cloudfront.net/profileImage/thumbnail_noor.webp",
            "url": "https://d35f5vastl4boc.cloudfront.net/profileImage/thumbnail_noor.webp",
            "assetId": "1b1cdcb3-5196-40dc-9a98-6fd90696a008",
            "contentType": "",
            "bannerUrl": "https://d35f5vastl4boc.cloudfront.net/profileImage/detail_noor.webp",
            "pristine_image": "",
            "profile": {
                "_id": "1b1cdcb3-5196-40dc-9a98-6fd90696a008",
                "userId": "1b1cdcb3-5196-40dc-9a98-6fd90696a008",
                "fullName": "Noor Mohammad",
                "userName": "Noor Mohammad",
                "email": "noor@hww.com",
                "description": "Noor Mohammad, an Indian traditional Sufi music performer and songwriter, from Baki-Aker village in North Kashmir.",
                "status": "ACTIVE",
                "profileImageUrl": "https://d35f5vastl4boc.cloudfront.net/profileImage/thumbnail_noor.webp",
                "followersCount": 0,
                "followingCount": 0,
                "videoCount": 0,
                "bookmarkCount": 0,
                "backgroundImage": "https://d35f5vastl4boc.cloudfront.net/profileImage/detail_noor.webp"
            }
        },
        {
            "id": "132bab97-ca39-4486-bce6-d9c965848e43",
            "ordering": 2,
            "displayName": "",
            "firstName": "Alif",
            "lastName": "",
            "likeCount": 0,
            "playCount": 0,
            "viewCount": 0,
            "followers": 0,
            "description": "Alif, a poet, a singer and a songwriter who bagged the IRAA Award for his single ‘Like A Sufi’. Truly recognized as Mohammad Muneem Nazir, his poignant and melancholic lyrical compositions are known for their colloquial references to society.",
            "duration": 0,
            "thumbnail": "https://d35f5vastl4boc.cloudfront.net/profileImage/thumbnail_alif.webp",
            "url": "https://d35f5vastl4boc.cloudfront.net/profileImage/thumbnail_alif.webp",
            "assetId": "132bab97-ca39-4486-bce6-d9c965848e43",
            "contentType": "",
            "bannerUrl": "https://d35f5vastl4boc.cloudfront.net/profileImage/detail_alif.webp",
            "pristine_image": "",
            "profile": {
                "_id": "132bab97-ca39-4486-bce6-d9c965848e43",
                "userId": "132bab97-ca39-4486-bce6-d9c965848e43",
                "fullName": "Alif",
                "userName": "Alif",
                "email": "alif@hww.com",
                "description": "Alif, a poet, a singer and a songwriter who bagged the IRAA Award for his single ‘Like A Sufi’. Truly recognized as Mohammad Muneem Nazir, his poignant and melancholic lyrical compositions are known for their colloquial references to society.",
                "status": "ACTIVE",
                "profileImageUrl": "https://d35f5vastl4boc.cloudfront.net/profileImage/thumbnail_alif.webp",
                "followersCount": 0,
                "followingCount": 0,
                "videoCount": 0,
                "bookmarkCount": 0,
                "backgroundImage": "https://d35f5vastl4boc.cloudfront.net/profileImage/detail_alif.webp"
            }
        },
        {
            "id": "0337d7f5-0102-4ae4-8c57-65fbe1ca5ade",
            "ordering": 3,
            "displayName": "",
            "firstName": "Mansa",
            "lastName": "",
            "likeCount": 0,
            "playCount": 0,
            "viewCount": 0,
            "followers": 0,
            "description": "Mansa Pandey is known for her unique covers of timeless classics.",
            "duration": 0,
            "thumbnail": "https://d35f5vastl4boc.cloudfront.net/profileImage/thumbnail_mansa.webp",
            "url": "https://d35f5vastl4boc.cloudfront.net/profileImage/thumbnail_mansa.webp",
            "assetId": "0337d7f5-0102-4ae4-8c57-65fbe1ca5ade",
            "contentType": "",
            "bannerUrl": "https://d35f5vastl4boc.cloudfront.net/profileImage/detail_mansa.webp",
            "pristine_image": "",
            "profile": {
                "_id": "0337d7f5-0102-4ae4-8c57-65fbe1ca5ade",
                "userId": "0337d7f5-0102-4ae4-8c57-65fbe1ca5ade",
                "fullName": "Mansa",
                "userName": "Mansa",
                "email": "mansa@hww.com",
                "description": "Mansa Pandey is known for her unique covers of timeless classics.",
                "status": "ACTIVE",
                "profileImageUrl": "https://d35f5vastl4boc.cloudfront.net/profileImage/thumbnail_mansa.webp",
                "followersCount": 0,
                "followingCount": 0,
                "videoCount": 0,
                "bookmarkCount": 0,
                "backgroundImage": "https://d35f5vastl4boc.cloudfront.net/profileImage/detail_mansa.webp"
            }
        },
        {
            "id": "3a8d4779-6560-4b72-bea9-2607b44af281",
            "ordering": 4,
            "displayName": "",
            "firstName": "Osho",
            "lastName": "",
            "likeCount": 0,
            "playCount": 0,
            "viewCount": 0,
            "followers": 0,
            "description": "",
            "duration": 0,
            "thumbnail": "https://d35f5vastl4boc.cloudfront.net/profileImage/thumbnail_osho.webp",
            "url": "https://d35f5vastl4boc.cloudfront.net/profileImage/thumbnail_osho.webp",
            "assetId": "3a8d4779-6560-4b72-bea9-2607b44af281",
            "contentType": "",
            "bannerUrl": "https://d35f5vastl4boc.cloudfront.net/profileImage/detail_osho.webp",
            "pristine_image": "",
            "profile": {
                "_id": "3a8d4779-6560-4b72-bea9-2607b44af281",
                "userId": "3a8d4779-6560-4b72-bea9-2607b44af281",
                "fullName": "Osho",
                "userName": "Osho",
                "email": "osho@hww.com",
                "description": "",
                "status": "ACTIVE",
                "profileImageUrl": "https://d35f5vastl4boc.cloudfront.net/profileImage/thumbnail_osho.webp",
                "followersCount": 0,
                "followingCount": 0,
                "videoCount": 0,
                "bookmarkCount": 0,
                "backgroundImage": "https://d35f5vastl4boc.cloudfront.net/profileImage/detail_osho.webp"
            }
        },
        {
            "id": "d61d1ada-8848-48e4-a71b-5052ba40d5cc",
            "ordering": 5,
            "displayName": "",
            "firstName": "Arijit",
            "lastName": "",
            "likeCount": 0,
            "playCount": 0,
            "viewCount": 0,
            "followers": 0,
            "description": "",
            "duration": 0,
            "thumbnail": "https://d35f5vastl4boc.cloudfront.net/profileImage/thumbnail_arijit.webp",
            "url": "https://d35f5vastl4boc.cloudfront.net/profileImage/thumbnail_arijit.webp",
            "assetId": "d61d1ada-8848-48e4-a71b-5052ba40d5cc",
            "contentType": "",
            "bannerUrl": "https://d35f5vastl4boc.cloudfront.net/profileImage/detail_arijit.webp",
            "pristine_image": "",
            "profile": {
                "_id": "d61d1ada-8848-48e4-a71b-5052ba40d5cc",
                "userId": "d61d1ada-8848-48e4-a71b-5052ba40d5cc",
                "fullName": "Arijit",
                "userName": "Arijit",
                "email": "arijit@www.com",
                "description": "",
                "status": "ACTIVE",
                "profileImageUrl": "https://d35f5vastl4boc.cloudfront.net/profileImage/thumbnail_arijit.webp",
                "followersCount": 0,
                "followingCount": 0,
                "videoCount": 0,
                "bookmarkCount": 0,
                "backgroundImage": "https://d35f5vastl4boc.cloudfront.net/profileImage/detail_arijit.webp"
            }
        },
        {
            "id": "659a2973-03ef-40ef-80d5-4b17e965f0ff",
            "ordering": 6,
            "displayName": "",
            "firstName": "Savera",
            "lastName": "",
            "likeCount": 0,
            "playCount": 0,
            "viewCount": 0,
            "followers": 0,
            "description": "Based out of Bombay, singer-songwriter Savera grew up between Bangalore and Ahmedabad. Raised by his Grandmother and Aunt, one a poet and the other a thespian, Savera found love for art at an early age. He was 8 when he picked up the guitar and 13 when he formed a punk-rock band with his closest friends.  \n\nA few years later Savera moved to Mumbai, with one goal- to pursue a career in music. So much that he dropped out of college for that.  \n\nSince then, he has gone on to build a successful career as a composer. But his greatest dream has always been to write music for music’s sake.",
            "duration": 0,
            "thumbnail": "https://d35f5vastl4boc.cloudfront.net/profileImage/thumbnail_savera.webp",
            "url": "https://d35f5vastl4boc.cloudfront.net/profileImage/thumbnail_savera.webp",
            "assetId": "659a2973-03ef-40ef-80d5-4b17e965f0ff",
            "contentType": "",
            "bannerUrl": "https://d35f5vastl4boc.cloudfront.net/profileImage/detail_savera.webp",
            "pristine_image": "",
            "profile": {
                "_id": "659a2973-03ef-40ef-80d5-4b17e965f0ff",
                "userId": "659a2973-03ef-40ef-80d5-4b17e965f0ff",
                "fullName": "Savera",
                "userName": "Savera",
                "email": "savera@hww.com",
                "description": "Based out of Bombay, singer-songwriter Savera grew up between Bangalore and Ahmedabad. Raised by his Grandmother and Aunt, one a poet and the other a thespian, Savera found love for art at an early age. He was 8 when he picked up the guitar and 13 when he formed a punk-rock band with his closest friends.  \n\nA few years later Savera moved to Mumbai, with one goal- to pursue a career in music. So much that he dropped out of college for that.  \n\nSince then, he has gone on to build a successful career as a composer. But his greatest dream has always been to write music for music’s sake.",
                "status": "ACTIVE",
                "profileImageUrl": "https://d35f5vastl4boc.cloudfront.net/profileImage/thumbnail_savera.webp",
                "followersCount": 0,
                "followingCount": 0,
                "videoCount": 0,
                "bookmarkCount": 0,
                "backgroundImage": "https://d35f5vastl4boc.cloudfront.net/profileImage/detail_savera.webp"
            }
        },
        {
            "id": "7f22a1ca-098f-4e41-8da2-5df583ac69ce",
            "ordering": 7,
            "displayName": "",
            "firstName": "Oaff",
            "lastName": "",
            "likeCount": 0,
            "playCount": 0,
            "viewCount": 0,
            "followers": 0,
            "description": "\"Kabeer Kathpalia, a.k.a OAFF, is a music producer, composer, and multi-instrumentalist who bridges the gap between atmospheric electronica and accessible, feel-good pop. He is renowned for eclectic atmospheric pop sound and for his ability to lend a theatrical vibe to tracks. OAFF’s career began in a high school punk-rock band and has grown to the big screens of Bollywood. \n\nKathpalia has created worldwide immersive audio installations in addition to his independent releases as OAFF. He has composed music for over 500 advertising campaigns, including those for H&M, Airbnb, Volkswagen, and the official Olympics Channel. He composed the title theme for the Emmy-nominated show 'Inside Edge'. \"",
            "duration": 0,
            "thumbnail": "https://d35f5vastl4boc.cloudfront.net/profileImage/thumbnail_oaff.webp",
            "url": "https://d35f5vastl4boc.cloudfront.net/profileImage/thumbnail_oaff.webp",
            "assetId": "7f22a1ca-098f-4e41-8da2-5df583ac69ce",
            "contentType": "",
            "bannerUrl": "https://d35f5vastl4boc.cloudfront.net/profileImage/detail_oaff.webp",
            "pristine_image": "",
            "profile": {
                "_id": "7f22a1ca-098f-4e41-8da2-5df583ac69ce",
                "userId": "7f22a1ca-098f-4e41-8da2-5df583ac69ce",
                "fullName": "Oaff",
                "userName": "Oaff",
                "email": "oaff@hww.com",
                "description": "\"Kabeer Kathpalia, a.k.a OAFF, is a music producer, composer, and multi-instrumentalist who bridges the gap between atmospheric electronica and accessible, feel-good pop. He is renowned for eclectic atmospheric pop sound and for his ability to lend a theatrical vibe to tracks. OAFF’s career began in a high school punk-rock band and has grown to the big screens of Bollywood. \n\nKathpalia has created worldwide immersive audio installations in addition to his independent releases as OAFF. He has composed music for over 500 advertising campaigns, including those for H&M, Airbnb, Volkswagen, and the official Olympics Channel. He composed the title theme for the Emmy-nominated show 'Inside Edge'. \"",
                "status": "ACTIVE",
                "profileImageUrl": "https://d35f5vastl4boc.cloudfront.net/profileImage/thumbnail_oaff.webp",
                "followersCount": 0,
                "followingCount": 0,
                "videoCount": 0,
                "bookmarkCount": 0,
                "backgroundImage": "https://d35f5vastl4boc.cloudfront.net/profileImage/detail_oaff.webp"
            }
        },
        {
            "id": "373ce915-2ad7-4d75-9c83-c85c0eeba2ed",
            "ordering": 8,
            "displayName": "",
            "firstName": "Ravi Kishan",
            "lastName": "",
            "likeCount": 0,
            "playCount": 0,
            "viewCount": 0,
            "followers": 0,
            "description": "A popular Indian actor, film producer and television personality who has worked in Bhojpuri, Hindi and Telugu cinema.",
            "duration": 0,
            "thumbnail": "https://d35f5vastl4boc.cloudfront.net/profileImage/ravi_kishan_thumbnail.webp",
            "url": "https://d35f5vastl4boc.cloudfront.net/profileImage/ravi_kishan_thumbnail.webp",
            "assetId": "373ce915-2ad7-4d75-9c83-c85c0eeba2ed",
            "contentType": "",
            "bannerUrl": "https://d35f5vastl4boc.cloudfront.net/profileImage/ravi_kishan_detail.webp",
            "pristine_image": "",
            "profile": {
                "_id": "373ce915-2ad7-4d75-9c83-c85c0eeba2ed",
                "userId": "373ce915-2ad7-4d75-9c83-c85c0eeba2ed",
                "fullName": "Ravi Kishan",
                "userName": "Ravi Kishan",
                "email": "ravi.kishan@hww.com",
                "description": "A popular Indian actor, film producer and television personality who has worked in Bhojpuri, Hindi and Telugu cinema.",
                "status": "ACTIVE",
                "profileImageUrl": "https://d35f5vastl4boc.cloudfront.net/profileImage/ravi_kishan_thumbnail.webp",
                "followersCount": 0,
                "followingCount": 0,
                "videoCount": 0,
                "bookmarkCount": 0,
                "backgroundImage": "https://d35f5vastl4boc.cloudfront.net/profileImage/ravi_kishan_detail.webp"
            }
        },
        {
            "id": "05ef21d5-ece5-4561-a940-1419b53fb21c",
            "ordering": 9,
            "displayName": "",
            "firstName": "Sakur",
            "lastName": "",
            "likeCount": 0,
            "playCount": 0,
            "viewCount": 0,
            "followers": 0,
            "description": "Sakur Khan, fondly likes to be known as a Sufi Singer, and says that the color white is symbolic of Sufism, as it is the color of purity, and the ‘ibaadat’ of Allah. Sufism is a phenomenon of mysticism, and Sakur Khan’s songs are truly mystic in nature. As per Sakur Khan, Sufism is his family tradition, and music is a divine worship. Sakur Khan’s family has been into Sufi musical tradition since ages, and their songs comprise of songs which are a beautiful amalgamation of Hindu and Islamic traditions. Sakur Khan studied till standard 5th, and then took to music because of financial reasons. Most of Sakur Khan’s “Jajmaan” i.e. patrons are Muslims and royal families, and almost everyone in his family is trained in Sufi genre of music. Sakur Khan’s describes his family tradition as Sufi and says that Sufism is a divine medium of reaching Allah directly. \n\nBulle Shah is Sakur Khan’s favorite poet and he uses Bulle Shah’s poetry in his songs quite often. \n\nHis powerful voice echoes in the venues where he performs, as he loses himself in trance. He has performed all over India and in over 9 countries abroad including London, Turkey, Dubai and Iran. Apart from these, Sakur has also performed in Pakistan in 2006. Sakur also teaches music to as many as 30 people who learn from him and is now their ‘Ustaad’. (master or teacher). ",
            "duration": 0,
            "thumbnail": "https://d35f5vastl4boc.cloudfront.net/profileImage/thumbnail_sakur.webp",
            "url": "https://d35f5vastl4boc.cloudfront.net/profileImage/thumbnail_sakur.webp",
            "assetId": "05ef21d5-ece5-4561-a940-1419b53fb21c",
            "contentType": "",
            "bannerUrl": "https://d35f5vastl4boc.cloudfront.net/profileImage/detail_sakur.webp",
            "pristine_image": "",
            "profile": {
                "_id": "05ef21d5-ece5-4561-a940-1419b53fb21c",
                "userId": "05ef21d5-ece5-4561-a940-1419b53fb21c",
                "fullName": "Sakur Khan",
                "userName": "Sakur",
                "email": "sakur@hww.com",
                "description": "Sakur Khan, fondly likes to be known as a Sufi Singer, and says that the color white is symbolic of Sufism, as it is the color of purity, and the ‘ibaadat’ of Allah. Sufism is a phenomenon of mysticism, and Sakur Khan’s songs are truly mystic in nature. As per Sakur Khan, Sufism is his family tradition, and music is a divine worship. Sakur Khan’s family has been into Sufi musical tradition since ages, and their songs comprise of songs which are a beautiful amalgamation of Hindu and Islamic traditions. Sakur Khan studied till standard 5th, and then took to music because of financial reasons. Most of Sakur Khan’s “Jajmaan” i.e. patrons are Muslims and royal families, and almost everyone in his family is trained in Sufi genre of music. Sakur Khan’s describes his family tradition as Sufi and says that Sufism is a divine medium of reaching Allah directly. \n\nBulle Shah is Sakur Khan’s favorite poet and he uses Bulle Shah’s poetry in his songs quite often. \n\nHis powerful voice echoes in the venues where he performs, as he loses himself in trance. He has performed all over India and in over 9 countries abroad including London, Turkey, Dubai and Iran. Apart from these, Sakur has also performed in Pakistan in 2006. Sakur also teaches music to as many as 30 people who learn from him and is now their ‘Ustaad’. (master or teacher). ",
                "status": "ACTIVE",
                "profileImageUrl": "https://d35f5vastl4boc.cloudfront.net/profileImage/thumbnail_sakur.webp",
                "followersCount": 0,
                "followingCount": 0,
                "videoCount": 0,
                "bookmarkCount": 0,
                "backgroundImage": "https://d35f5vastl4boc.cloudfront.net/profileImage/detail_sakur.webp"
            }
        },
        {
            "id": "08bf4f2c-d7a4-4f32-881b-42d846ead798",
            "ordering": 10,
            "displayName": "",
            "firstName": "Kanwar Grewal",
            "lastName": "",
            "likeCount": 0,
            "playCount": 0,
            "viewCount": 0,
            "followers": 0,
            "description": "Kanwar Grewal is one of the most popular Punjabi singers, poets and Kanwar Grewal is one of the most popular Punjabi singers, poets and composers from Punjab, India. He started learning music when he was in Class 6, and his performances, focusing on Punjab’s heritage, became popular when he was still in school. \nGrewal's music is heavily influenced by Punjabi tradition and culture, but he also adds Qawwali and Sufi elements to it. His songs are mostly souful, with philosophical and spiritual overtones, reflecting his own values and life experiences. Kanwar's music is known for its folk elements intertwined with modern beats. He has also sung many spiritual songs which have been appreciated by people all over the world. His most popular songs include 'Rang', 'Nazar', 'Agg Lao Paira Di' and 'Tere Utte'. Kanwar has won several awards for his contribution to Punjabi music industry including PTC Music Awards for Best New Talent (2013), PTC Music Awards for Best Male Vocalist (2014), PTC Music Awards for Best Folk Pop Song (2015) and Brit Asia Music Awards for Best Male Vocalist (2017).",
            "duration": 0,
            "thumbnail": "https://d35f5vastl4boc.cloudfront.net/profileImage/thumbnail_kanwar.webp",
            "url": "https://d35f5vastl4boc.cloudfront.net/profileImage/thumbnail_kanwar.webp",
            "assetId": "08bf4f2c-d7a4-4f32-881b-42d846ead798",
            "contentType": "",
            "bannerUrl": "https://d35f5vastl4boc.cloudfront.net/profileImage/detail_kanwar.webp",
            "pristine_image": "",
            "profile": {
                "_id": "08bf4f2c-d7a4-4f32-881b-42d846ead798",
                "userId": "08bf4f2c-d7a4-4f32-881b-42d846ead798",
                "fullName": "Kanwar Grewal",
                "userName": "Kanwar Grewal",
                "email": "kanwar.grewal@hww.com",
                "description": "Kanwar Grewal is one of the most popular Punjabi singers, poets and Kanwar Grewal is one of the most popular Punjabi singers, poets and composers from Punjab, India. He started learning music when he was in Class 6, and his performances, focusing on Punjab’s heritage, became popular when he was still in school. \nGrewal's music is heavily influenced by Punjabi tradition and culture, but he also adds Qawwali and Sufi elements to it. His songs are mostly souful, with philosophical and spiritual overtones, reflecting his own values and life experiences. Kanwar's music is known for its folk elements intertwined with modern beats. He has also sung many spiritual songs which have been appreciated by people all over the world. His most popular songs include 'Rang', 'Nazar', 'Agg Lao Paira Di' and 'Tere Utte'. Kanwar has won several awards for his contribution to Punjabi music industry including PTC Music Awards for Best New Talent (2013), PTC Music Awards for Best Male Vocalist (2014), PTC Music Awards for Best Folk Pop Song (2015) and Brit Asia Music Awards for Best Male Vocalist (2017).",
                "status": "ACTIVE",
                "profileImageUrl": "https://d35f5vastl4boc.cloudfront.net/profileImage/thumbnail_kanwar.webp",
                "followersCount": 0,
                "followingCount": 0,
                "videoCount": 0,
                "bookmarkCount": 0,
                "backgroundImage": "https://d35f5vastl4boc.cloudfront.net/profileImage/detail_kanwar.webp"
            }
        },
        {
            "id": "439f9817-78b5-4eef-829f-c808b5d5f5c0",
            "ordering": 11,
            "displayName": "",
            "firstName": "Tajdar Junaid",
            "lastName": "",
            "likeCount": 0,
            "playCount": 0,
            "viewCount": 0,
            "followers": 0,
            "description": "Multi-instrumentalist, composer, songwriter and producer,  Tajdar Junaid’s is a  lover of all honest expressions of art. Tajdar’s work was named as one of the Top Albums of 2013 by Rolling Stone, RSJ and his composition ‘dastaan’ was heard in the legendary Iranian filmmaker Mohsen Makhmalbaf's new film \"\"The President”, which premiered at the Venice Film Festival, 2014.  \nA vivid member of the local music scene from urban and suburban parts of India, Tajdar has also collaborated with other artists in the form of theatre, dance, interactive media displays, stadium concerts; thereby extending, sharing and transforming the idea of his space and practice.  \nWith his notes travelling all over; Hollywood film \"\"Sold\"\" produced by Academy Award winner Emma Thompson (2013), to Bollywood and Tollywood with collaborations with Anurag Kashyap Productions, Aparna Sen and Rituparno Ghosh. To the indie circuit, with one that earned him a Filmfare nomination for Best background score for “Mukti Bhawan” (2018), his documentary trail represented at Sundance and IDFA and a nomination for Best Documentary at the 94th Academy Awards by “Writing With Fire” (2021), Tajdar’s is a global voice, courageous and relentless in offering his unique sensibilities towards the stories of human condition.",
            "duration": 0,
            "thumbnail": "https://d35f5vastl4boc.cloudfront.net/profileImage/thumbnail_taidar.webp",
            "url": "https://d35f5vastl4boc.cloudfront.net/profileImage/thumbnail_taidar.webp",
            "assetId": "439f9817-78b5-4eef-829f-c808b5d5f5c0",
            "contentType": "",
            "bannerUrl": "https://d35f5vastl4boc.cloudfront.net/profileImage/detail_taidar.webp",
            "pristine_image": "",
            "profile": {
                "_id": "439f9817-78b5-4eef-829f-c808b5d5f5c0",
                "userId": "439f9817-78b5-4eef-829f-c808b5d5f5c0",
                "fullName": "Tajdar Junaid",
                "userName": "Tajdar Junaid",
                "email": "tajdar@hww.com",
                "description": "Multi-instrumentalist, composer, songwriter and producer,  Tajdar Junaid’s is a  lover of all honest expressions of art. Tajdar’s work was named as one of the Top Albums of 2013 by Rolling Stone, RSJ and his composition ‘dastaan’ was heard in the legendary Iranian filmmaker Mohsen Makhmalbaf's new film \"\"The President”, which premiered at the Venice Film Festival, 2014.  \nA vivid member of the local music scene from urban and suburban parts of India, Tajdar has also collaborated with other artists in the form of theatre, dance, interactive media displays, stadium concerts; thereby extending, sharing and transforming the idea of his space and practice.  \nWith his notes travelling all over; Hollywood film \"\"Sold\"\" produced by Academy Award winner Emma Thompson (2013), to Bollywood and Tollywood with collaborations with Anurag Kashyap Productions, Aparna Sen and Rituparno Ghosh. To the indie circuit, with one that earned him a Filmfare nomination for Best background score for “Mukti Bhawan” (2018), his documentary trail represented at Sundance and IDFA and a nomination for Best Documentary at the 94th Academy Awards by “Writing With Fire” (2021), Tajdar’s is a global voice, courageous and relentless in offering his unique sensibilities towards the stories of human condition.",
                "status": "ACTIVE",
                "profileImageUrl": "https://d35f5vastl4boc.cloudfront.net/profileImage/thumbnail_taidar.webp",
                "followersCount": 0,
                "followingCount": 0,
                "videoCount": 0,
                "bookmarkCount": 0,
                "backgroundImage": "https://d35f5vastl4boc.cloudfront.net/profileImage/detail_taidar.webp"
            }
        },
        {
            "id": "e28e8aee-8d43-4e6b-b081-be3768010841",
            "ordering": 12,
            "displayName": "",
            "firstName": "Charan Raj",
            "lastName": "",
            "likeCount": 0,
            "playCount": 0,
            "viewCount": 0,
            "followers": 0,
            "description": "Charan Raj is an Indian composer and singer known for his work in Kannada cinema. He was awarded the Karnataka State Film Award for Best Music Director in 2016  \n \nRaj is trained in classical Carnatic music with Perumbavoor G. Raveendranath and in WesternClassical music with Neecia Majolly. He also holds a grade eight certificate in piano from the London School of Music. \n\nRaj's first major success came when Winds of Samsara, an album of Ricky Kej and Wouter Kellerman won the Grammy Award for Best New Age Album in 2015. The album also featured vocals by Raj.  \n\nHe has received acclaim for his work in numerous kannada fims.  He has also been awarded the Karnataka State Film Award for Best Music Director.[5] \n\nMany of Raj’s compositions are a  blend of \"\"electronic score\"\" and \"\"traditional sounds\"\".[6] His tracks are based on a different \"\"human emotion\"\" \"\"such as love, anger and fear\"\" rather than situation-based nature of tracks.",
            "duration": 0,
            "thumbnail": "https://d35f5vastl4boc.cloudfront.net/profileImage/thumbnail_charan.webp",
            "url": "https://d35f5vastl4boc.cloudfront.net/profileImage/thumbnail_charan.webp",
            "assetId": "e28e8aee-8d43-4e6b-b081-be3768010841",
            "contentType": "",
            "bannerUrl": "https://d35f5vastl4boc.cloudfront.net/profileImage/detail_charan.webp",
            "pristine_image": "",
            "profile": {
                "_id": "e28e8aee-8d43-4e6b-b081-be3768010841",
                "userId": "e28e8aee-8d43-4e6b-b081-be3768010841",
                "fullName": "Charan Raj",
                "userName": "Charan Raj",
                "email": "charan.raj@hww.com",
                "description": "Charan Raj is an Indian composer and singer known for his work in Kannada cinema. He was awarded the Karnataka State Film Award for Best Music Director in 2016  \n \nRaj is trained in classical Carnatic music with Perumbavoor G. Raveendranath and in WesternClassical music with Neecia Majolly. He also holds a grade eight certificate in piano from the London School of Music. \n\nRaj's first major success came when Winds of Samsara, an album of Ricky Kej and Wouter Kellerman won the Grammy Award for Best New Age Album in 2015. The album also featured vocals by Raj.  \n\nHe has received acclaim for his work in numerous kannada fims.  He has also been awarded the Karnataka State Film Award for Best Music Director.[5] \n\nMany of Raj’s compositions are a  blend of \"\"electronic score\"\" and \"\"traditional sounds\"\".[6] His tracks are based on a different \"\"human emotion\"\" \"\"such as love, anger and fear\"\" rather than situation-based nature of tracks.",
                "status": "ACTIVE",
                "profileImageUrl": "https://d35f5vastl4boc.cloudfront.net/profileImage/thumbnail_charan.webp",
                "followersCount": 0,
                "followingCount": 0,
                "videoCount": 0,
                "bookmarkCount": 0,
                "backgroundImage": "https://d35f5vastl4boc.cloudfront.net/profileImage/detail_charan.webp"
            }
        },
        {
            "id": "38ad9dce-aa99-4b8b-a527-24be89e9971f",
            "ordering": 13,
            "displayName": "",
            "firstName": "Bombay Brass",
            "lastName": "",
            "likeCount": 0,
            "playCount": 0,
            "viewCount": 0,
            "followers": 0,
            "description": "",
            "duration": 0,
            "thumbnail": "https://d35f5vastl4boc.cloudfront.net/profileImage/bombay_thumbnail.webp",
            "url": "https://d35f5vastl4boc.cloudfront.net/profileImage/bombay_thumbnail.webp",
            "assetId": "38ad9dce-aa99-4b8b-a527-24be89e9971f",
            "contentType": "",
            "bannerUrl": "https://d35f5vastl4boc.cloudfront.net/profileImage/bombay_detail.webp",
            "pristine_image": "",
            "profile": {
                "_id": "38ad9dce-aa99-4b8b-a527-24be89e9971f",
                "userId": "38ad9dce-aa99-4b8b-a527-24be89e9971f",
                "fullName": "Bombay Brass",
                "userName": "Bombay Brass",
                "email": "bombay@hww.com",
                "description": "",
                "status": "ACTIVE",
                "profileImageUrl": "https://d35f5vastl4boc.cloudfront.net/profileImage/bombay_thumbnail.webp",
                "followersCount": 0,
                "followingCount": 0,
                "videoCount": 0,
                "bookmarkCount": 0,
                "backgroundImage": "https://d35f5vastl4boc.cloudfront.net/profileImage/bombay_detail.webp"
            }
        },
        {
            "id": "7e06325b-8767-4c21-a792-baef7f3f6ecb",
            "ordering": 14,
            "displayName": "",
            "firstName": "Shillong Chamber Choir",
            "lastName": "",
            "likeCount": 0,
            "playCount": 0,
            "viewCount": 0,
            "followers": 0,
            "description": "Shillong Chamber Choir is celebrated for their harmonious performances.",
            "duration": 0,
            "thumbnail": "https://d35f5vastl4boc.cloudfront.net/profileImage/shillong_thumbnail.webp",
            "url": "https://d35f5vastl4boc.cloudfront.net/profileImage/shillong_thumbnail.webp",
            "assetId": "7e06325b-8767-4c21-a792-baef7f3f6ecb",
            "contentType": "",
            "bannerUrl": "https://d35f5vastl4boc.cloudfront.net/profileImage/shillong_detail.webp",
            "pristine_image": "",
            "profile": {
                "_id": "7e06325b-8767-4c21-a792-baef7f3f6ecb",
                "userId": "7e06325b-8767-4c21-a792-baef7f3f6ecb",
                "fullName": "Shillong Chamber Choir",
                "userName": "Shillong Chamber Choir",
                "email": "shillong@hww.com",
                "description": "Shillong Chamber Choir is celebrated for their harmonious performances.",
                "status": "ACTIVE",
                "profileImageUrl": "https://d35f5vastl4boc.cloudfront.net/profileImage/shillong_thumbnail.webp",
                "followersCount": 0,
                "followingCount": 0,
                "videoCount": 0,
                "bookmarkCount": 0,
                "backgroundImage": "https://d35f5vastl4boc.cloudfront.net/profileImage/shillong_detail.webp"
            }
        },
        {
            "id": "617bc63d-89c8-41dc-8005-67ee470826cd",
            "ordering": 15,
            "displayName": "",
            "firstName": "Burrah",
            "lastName": "",
            "likeCount": 0,
            "playCount": 0,
            "viewCount": 0,
            "followers": 0,
            "description": "\"Lyricist, Poet, rapper & singer - Burrah is one of Coke's most talented finds for the season. \n\nJasdeep Singh, also known as Burrah, is a Punjabi singer, songwriter, and producer.   \n\nAlthough music had been a part of his childhood, he never thought of making it a career until he experienced the joy of music making in his job. That was the moment he realised it was his true calling. Ever since , he has earned quite a lot of popularity among the lovers of Punjabi music. Burrah combines traditional Punjabi folk music with modern sounds in a way that makes it appealing to a wide range of people. However, At the core of Burrah’s philosophies – perhaps specifically as a Punjabi artist – comes from growing up in New Delhi and not being fully steeped in the Punjabi heartland. Burrah’s music comes from being more emotionally vulnerable, but that there are more serious social and cultural in India that he wishes to address in the future. He wants to liberate the Punjabi music community from haumai, which translates to ego.  \n\nBurrah believes that music gave him a sense of purpose in life  \"",
            "duration": 0,
            "thumbnail": "https://d35f5vastl4boc.cloudfront.net/profileImage/thumbnail_burrah.webp",
            "url": "https://d35f5vastl4boc.cloudfront.net/profileImage/thumbnail_burrah.webp",
            "assetId": "617bc63d-89c8-41dc-8005-67ee470826cd",
            "contentType": "",
            "bannerUrl": "https://d35f5vastl4boc.cloudfront.net/profileImage/detail_burrah.webp",
            "pristine_image": "",
            "profile": {
                "_id": "617bc63d-89c8-41dc-8005-67ee470826cd",
                "userId": "617bc63d-89c8-41dc-8005-67ee470826cd",
                "fullName": "Burrah",
                "userName": "Burrah",
                "email": "burrah@hww.com",
                "description": "\"Lyricist, Poet, rapper & singer - Burrah is one of Coke's most talented finds for the season. \n\nJasdeep Singh, also known as Burrah, is a Punjabi singer, songwriter, and producer.   \n\nAlthough music had been a part of his childhood, he never thought of making it a career until he experienced the joy of music making in his job. That was the moment he realised it was his true calling. Ever since , he has earned quite a lot of popularity among the lovers of Punjabi music. Burrah combines traditional Punjabi folk music with modern sounds in a way that makes it appealing to a wide range of people. However, At the core of Burrah’s philosophies – perhaps specifically as a Punjabi artist – comes from growing up in New Delhi and not being fully steeped in the Punjabi heartland. Burrah’s music comes from being more emotionally vulnerable, but that there are more serious social and cultural in India that he wishes to address in the future. He wants to liberate the Punjabi music community from haumai, which translates to ego.  \n\nBurrah believes that music gave him a sense of purpose in life  \"",
                "status": "ACTIVE",
                "profileImageUrl": "https://d35f5vastl4boc.cloudfront.net/profileImage/thumbnail_burrah.webp",
                "followersCount": 0,
                "followingCount": 0,
                "videoCount": 0,
                "bookmarkCount": 0,
                "backgroundImage": "https://d35f5vastl4boc.cloudfront.net/profileImage/detail_burrah.webp"
            }
        },
        {
            "id": "80a615a0-a431-49d5-962b-9b98b9142f13",
            "ordering": 16,
            "displayName": "",
            "firstName": "Jasleen Royal",
            "lastName": "",
            "likeCount": 0,
            "playCount": 0,
            "viewCount": 0,
            "followers": 0,
            "description": "She is an Indian singer, songwriter and composer. She became the first female music director in history to win the Filmfare Award for Best Music Director for the film Shershaah. ",
            "duration": 0,
            "thumbnail": "https://d35f5vastl4boc.cloudfront.net/profileImage/thumbnail_jasleen.webp",
            "url": "https://d35f5vastl4boc.cloudfront.net/profileImage/thumbnail_jasleen.webp",
            "assetId": "80a615a0-a431-49d5-962b-9b98b9142f13",
            "contentType": "",
            "bannerUrl": "https://d35f5vastl4boc.cloudfront.net/profileImage/detail_jasleen.webp",
            "pristine_image": "",
            "profile": {
                "_id": "80a615a0-a431-49d5-962b-9b98b9142f13",
                "userId": "80a615a0-a431-49d5-962b-9b98b9142f13",
                "fullName": "Jasleen Royal",
                "userName": "Jasleen Royal",
                "email": "jasleen@hww.com",
                "description": "She is an Indian singer, songwriter and composer. She became the first female music director in history to win the Filmfare Award for Best Music Director for the film Shershaah. ",
                "status": "ACTIVE",
                "profileImageUrl": "https://d35f5vastl4boc.cloudfront.net/profileImage/thumbnail_jasleen.webp",
                "followersCount": 0,
                "followingCount": 0,
                "videoCount": 0,
                "bookmarkCount": 0,
                "backgroundImage": "https://d35f5vastl4boc.cloudfront.net/profileImage/detail_jasleen.webp"
            }
        },
        {
            "id": "87deea85-d52b-4840-9dca-e728604af6d6",
            "ordering": 17,
            "displayName": "",
            "firstName": "Prabh Deep",
            "lastName": "",
            "likeCount": 0,
            "playCount": 0,
            "viewCount": 0,
            "followers": 0,
            "description": "He is an Indian rapper, songwriter and music producer. He gained recognition in the Indian music industry after the release of his debut album Class-Sikh which debuted at number 2 on iTunes India albums chart and quickly ranked up to number 1 by the end of the year",
            "duration": 0,
            "thumbnail": "https://d35f5vastl4boc.cloudfront.net/profileImage/thumbnail_prabh.webp",
            "url": "https://d35f5vastl4boc.cloudfront.net/profileImage/thumbnail_prabh.webp",
            "assetId": "87deea85-d52b-4840-9dca-e728604af6d6",
            "contentType": "",
            "bannerUrl": "https://d35f5vastl4boc.cloudfront.net/profileImage/detail_prabh.webp",
            "pristine_image": "",
            "profile": {
                "_id": "87deea85-d52b-4840-9dca-e728604af6d6",
                "userId": "87deea85-d52b-4840-9dca-e728604af6d6",
                "fullName": "Prabh Deep",
                "userName": "Prabh Deep",
                "email": "prabh@hww.com",
                "description": "He is an Indian rapper, songwriter and music producer. He gained recognition in the Indian music industry after the release of his debut album Class-Sikh which debuted at number 2 on iTunes India albums chart and quickly ranked up to number 1 by the end of the year",
                "status": "ACTIVE",
                "profileImageUrl": "https://d35f5vastl4boc.cloudfront.net/profileImage/thumbnail_prabh.webp",
                "followersCount": 0,
                "followingCount": 0,
                "videoCount": 0,
                "bookmarkCount": 0,
                "backgroundImage": "https://d35f5vastl4boc.cloudfront.net/profileImage/detail_prabh.webp"
            }
        },
        {
            "id": "e5a13e2d-b770-496c-9ff2-0f4229e861c8",
            "ordering": 18,
            "displayName": "",
            "firstName": "Rashmeet Kaur",
            "lastName": "",
            "likeCount": 0,
            "playCount": 0,
            "viewCount": 0,
            "followers": 0,
            "description": "She is one of the most versatile singer songwriters in the country. Her most famous songs are Bajre Da Sitta, the first viral song on IG reels; Nadiyon Paar, a film hit and Jadi Buti, a collaboration with Grammy nominated Major Lazer and Nucleya.",
            "duration": 0,
            "thumbnail": "https://d35f5vastl4boc.cloudfront.net/profileImage/thumbnail_rashmeet.webp",
            "url": "https://d35f5vastl4boc.cloudfront.net/profileImage/thumbnail_rashmeet.webp",
            "assetId": "e5a13e2d-b770-496c-9ff2-0f4229e861c8",
            "contentType": "",
            "bannerUrl": "https://d35f5vastl4boc.cloudfront.net/profileImage/detail_rashmeet.webp",
            "pristine_image": "",
            "profile": {
                "_id": "e5a13e2d-b770-496c-9ff2-0f4229e861c8",
                "userId": "e5a13e2d-b770-496c-9ff2-0f4229e861c8",
                "fullName": "Rashmeet Kaur",
                "userName": "Rashmeet Kaur",
                "email": "rashmeet@hww.com",
                "description": "She is one of the most versatile singer songwriters in the country. Her most famous songs are Bajre Da Sitta, the first viral song on IG reels; Nadiyon Paar, a film hit and Jadi Buti, a collaboration with Grammy nominated Major Lazer and Nucleya.",
                "status": "ACTIVE",
                "profileImageUrl": "https://d35f5vastl4boc.cloudfront.net/profileImage/thumbnail_rashmeet.webp",
                "followersCount": 0,
                "followingCount": 0,
                "videoCount": 0,
                "bookmarkCount": 0,
                "backgroundImage": "https://d35f5vastl4boc.cloudfront.net/profileImage/detail_rashmeet.webp"
            }
        },
        {
            "id": "c8e76d72-ee54-4b11-b7c6-b9fa5ffff4a8",
            "ordering": 19,
            "displayName": "",
            "firstName": "Donn",
            "lastName": "",
            "likeCount": 0,
            "playCount": 0,
            "viewCount": 0,
            "followers": 0,
            "description": "The Mumbai-based composer, producer, guitarist and vocalist crafts some of the most immersive audio experiences with his band, Passenger Revelator, mixing electronic, pop, rock and more. \n\nGrowing up in a household of qawwali enthusiasts, playing hooky with psychedelic trance during college and letting his hair down with early bands like Friday The 13th and Orange Street, Bhat has evolved his folk-fusion sensibilities over time.  \n\nBhatt draws his inspiration from the streets of India. This is the impetus behind embarking on eclectic pilgrimages like the Rajasthan Kabir Yatra or a village carnival somewhere in Chhattisgarh and recording samples on his phone. The granules of these multifarious experiences percolate into his senses until they find a way to Bhat's digital workstation at home. In fact, this is how he got started really, by firing off an apprehensive salvo of soundscapes he dreamed up in the solitude of his bedroom on Reason (audio software). \n\nFor Donn, music is a spiritual thing. It's supposed to comfort us and make us feel less alone in this universe.",
            "duration": 0,
            "thumbnail": "https://d35f5vastl4boc.cloudfront.net/profileImage/thumbnail_donn.webp",
            "url": "https://d35f5vastl4boc.cloudfront.net/profileImage/thumbnail_donn.webp",
            "assetId": "c8e76d72-ee54-4b11-b7c6-b9fa5ffff4a8",
            "contentType": "",
            "bannerUrl": "https://d35f5vastl4boc.cloudfront.net/profileImage/detail_donn.webp",
            "pristine_image": "",
            "profile": {
                "_id": "c8e76d72-ee54-4b11-b7c6-b9fa5ffff4a8",
                "userId": "c8e76d72-ee54-4b11-b7c6-b9fa5ffff4a8",
                "fullName": "Donn Bhatt",
                "userName": "Donn",
                "email": "donn@hww.com",
                "description": "The Mumbai-based composer, producer, guitarist and vocalist crafts some of the most immersive audio experiences with his band, Passenger Revelator, mixing electronic, pop, rock and more. \n\nGrowing up in a household of qawwali enthusiasts, playing hooky with psychedelic trance during college and letting his hair down with early bands like Friday The 13th and Orange Street, Bhat has evolved his folk-fusion sensibilities over time.  \n\nBhatt draws his inspiration from the streets of India. This is the impetus behind embarking on eclectic pilgrimages like the Rajasthan Kabir Yatra or a village carnival somewhere in Chhattisgarh and recording samples on his phone. The granules of these multifarious experiences percolate into his senses until they find a way to Bhat's digital workstation at home. In fact, this is how he got started really, by firing off an apprehensive salvo of soundscapes he dreamed up in the solitude of his bedroom on Reason (audio software). \n\nFor Donn, music is a spiritual thing. It's supposed to comfort us and make us feel less alone in this universe.",
                "status": "ACTIVE",
                "profileImageUrl": "https://d35f5vastl4boc.cloudfront.net/profileImage/thumbnail_donn.webp",
                "followersCount": 0,
                "followingCount": 0,
                "videoCount": 0,
                "bookmarkCount": 0,
                "backgroundImage": "https://d35f5vastl4boc.cloudfront.net/profileImage/detail_donn.webp"
            }
        },
        {
            "id": "08b74d99-1d37-4d8d-be68-cf1fb5cf16aa",
            "ordering": 20,
            "displayName": "",
            "firstName": "Aditya Gadhvi",
            "lastName": "",
            "likeCount": 0,
            "playCount": 0,
            "viewCount": 0,
            "followers": 0,
            "description": "He is a playback singer and lyricist hailing from Gujarat, India. He was a winner of \"E-Tv Lok Gayak Gujarat\" a folk and Sufi singer 2 songs from the movie 'Kamasutra3D' sung by Gadhvi shortlisted for Oscar nominations in 2014.",
            "duration": 0,
            "thumbnail": "https://d35f5vastl4boc.cloudfront.net/profileImage/thumbnail_aditya.webp",
            "url": "https://d35f5vastl4boc.cloudfront.net/profileImage/thumbnail_aditya.webp",
            "assetId": "08b74d99-1d37-4d8d-be68-cf1fb5cf16aa",
            "contentType": "",
            "bannerUrl": "https://d35f5vastl4boc.cloudfront.net/profileImage/detail_aditya.webp",
            "pristine_image": "",
            "profile": {
                "_id": "08b74d99-1d37-4d8d-be68-cf1fb5cf16aa",
                "userId": "08b74d99-1d37-4d8d-be68-cf1fb5cf16aa",
                "fullName": "Aditya Gadhvi",
                "userName": "Aditya Gadhvi",
                "email": "aditya@hww.com",
                "description": "He is a playback singer and lyricist hailing from Gujarat, India. He was a winner of \"E-Tv Lok Gayak Gujarat\" a folk and Sufi singer 2 songs from the movie 'Kamasutra3D' sung by Gadhvi shortlisted for Oscar nominations in 2014.",
                "status": "ACTIVE",
                "profileImageUrl": "https://d35f5vastl4boc.cloudfront.net/profileImage/thumbnail_aditya.webp",
                "followersCount": 0,
                "followingCount": 0,
                "videoCount": 0,
                "bookmarkCount": 0,
                "backgroundImage": "https://d35f5vastl4boc.cloudfront.net/profileImage/detail_aditya.webp"
            }
        },
        {
            "id": "350af303-ba41-4762-9bb6-2995ad239878",
            "ordering": 21,
            "displayName": "",
            "firstName": "Achint",
            "lastName": "",
            "likeCount": 0,
            "playCount": 0,
            "viewCount": 0,
            "followers": 0,
            "description": "He is a Music composer. Achint Thakkar is known for Monica O My Darling (2022), Scam 1992: The Harshad Mehta Story (2020) and Batshit Crazy (2024).",
            "duration": 0,
            "thumbnail": "https://d35f5vastl4boc.cloudfront.net/profileImage/thumbnail_achint.webp",
            "url": "https://d35f5vastl4boc.cloudfront.net/profileImage/thumbnail_achint.webp",
            "assetId": "350af303-ba41-4762-9bb6-2995ad239878",
            "contentType": "",
            "bannerUrl": "https://d35f5vastl4boc.cloudfront.net/profileImage/detaiil_achint.webp",
            "pristine_image": "",
            "profile": {
                "_id": "350af303-ba41-4762-9bb6-2995ad239878",
                "userId": "350af303-ba41-4762-9bb6-2995ad239878",
                "fullName": "Achint",
                "userName": "Achint",
                "email": "achint@hww.com",
                "description": "He is a Music composer. Achint Thakkar is known for Monica O My Darling (2022), Scam 1992: The Harshad Mehta Story (2020) and Batshit Crazy (2024).",
                "status": "ACTIVE",
                "profileImageUrl": "https://d35f5vastl4boc.cloudfront.net/profileImage/thumbnail_achint.webp",
                "followersCount": 0,
                "followingCount": 0,
                "videoCount": 0,
                "bookmarkCount": 0,
                "backgroundImage": "https://d35f5vastl4boc.cloudfront.net/profileImage/detaiil_achint.webp"
            }
        },
        {
            "id": "f7f6c930-be0c-465e-8c3e-e956f8f1ebf1",
            "ordering": 22,
            "displayName": "",
            "firstName": "Mahan",
            "lastName": "",
            "likeCount": 0,
            "playCount": 0,
            "viewCount": 0,
            "followers": 0,
            "description": "",
            "duration": 0,
            "thumbnail": "https://d35f5vastl4boc.cloudfront.net/profileImage/mahan_thumbnail.webp",
            "url": "https://d35f5vastl4boc.cloudfront.net/profileImage/mahan_thumbnail.webp",
            "assetId": "f7f6c930-be0c-465e-8c3e-e956f8f1ebf1",
            "contentType": "",
            "bannerUrl": "https://d35f5vastl4boc.cloudfront.net/profileImage/mahan_detail.webp",
            "pristine_image": "",
            "profile": {
                "_id": "f7f6c930-be0c-465e-8c3e-e956f8f1ebf1",
                "userId": "f7f6c930-be0c-465e-8c3e-e956f8f1ebf1",
                "fullName": "Mahan",
                "userName": "Mahan",
                "email": "mahan@hww.com",
                "description": "",
                "status": "ACTIVE",
                "profileImageUrl": "https://d35f5vastl4boc.cloudfront.net/profileImage/mahan_thumbnail.webp",
                "followersCount": 0,
                "followingCount": 0,
                "videoCount": 0,
                "bookmarkCount": 0,
                "backgroundImage": "https://d35f5vastl4boc.cloudfront.net/profileImage/mahan_detail.webp"
            }
        },
        {
            "id": "a93fe798-1612-4351-a645-0394daf92fa4",
            "ordering": 23,
            "displayName": "",
            "firstName": "Seedhe Maut",
            "lastName": "",
            "likeCount": 0,
            "playCount": 0,
            "viewCount": 0,
            "followers": 0,
            "description": "Seedhe Maut represent the next stage in the evolution of the capital’s hip-hop sound. Having mastered the art of delivering razor sharp, combative and witty rhymes, the duo are following in the trailblazing footsteps of international hip-hop collectives. ",
            "duration": 0,
            "thumbnail": "https://d35f5vastl4boc.cloudfront.net/profileImage/seedhe_maut_thumbnail.webp",
            "url": "https://d35f5vastl4boc.cloudfront.net/profileImage/seedhe_maut_thumbnail.webp",
            "assetId": "a93fe798-1612-4351-a645-0394daf92fa4",
            "contentType": "",
            "bannerUrl": "https://d35f5vastl4boc.cloudfront.net/profileImage/seedhe_maut_detail.webp",
            "pristine_image": "",
            "profile": {
                "_id": "a93fe798-1612-4351-a645-0394daf92fa4",
                "userId": "a93fe798-1612-4351-a645-0394daf92fa4",
                "fullName": "Seedhe Maut",
                "userName": "Seedhe Maut",
                "email": "seedhe@hww.com",
                "description": "Seedhe Maut represent the next stage in the evolution of the capital’s hip-hop sound. Having mastered the art of delivering razor sharp, combative and witty rhymes, the duo are following in the trailblazing footsteps of international hip-hop collectives. ",
                "status": "ACTIVE",
                "profileImageUrl": "https://d35f5vastl4boc.cloudfront.net/profileImage/seedhe_maut_thumbnail.webp",
                "followersCount": 0,
                "followingCount": 0,
                "videoCount": 0,
                "bookmarkCount": 0,
                "backgroundImage": "https://d35f5vastl4boc.cloudfront.net/profileImage/seedhe_maut_detail.webp"
            }
        },
        {
            "id": "f863b8c4-d72f-44f4-81ab-2038ada5adcb",
            "ordering": 24,
            "displayName": "",
            "firstName": "Maithili Thakur",
            "lastName": "",
            "likeCount": 0,
            "playCount": 0,
            "viewCount": 0,
            "followers": 0,
            "description": "Maithili Thakur  is an Indian playback singer trained in Indian classical music and Folk Music. She has sung original songs, covers, and traditional folk music prominently in Hindi, Bengali, Maithili, Urdu, Marathi, Bhojpuri, Punjabi, Tamil, English and more Indic languages.",
            "duration": 0,
            "thumbnail": "https://d35f5vastl4boc.cloudfront.net/profileImage/maithali_thumbnail.webp",
            "url": "https://d35f5vastl4boc.cloudfront.net/profileImage/maithali_thumbnail.webp",
            "assetId": "f863b8c4-d72f-44f4-81ab-2038ada5adcb",
            "contentType": "",
            "bannerUrl": "https://d35f5vastl4boc.cloudfront.net/profileImage/maithali_detail.webp",
            "pristine_image": "",
            "profile": {
                "_id": "f863b8c4-d72f-44f4-81ab-2038ada5adcb",
                "userId": "f863b8c4-d72f-44f4-81ab-2038ada5adcb",
                "fullName": "Maithili Thakur",
                "userName": "Maithili Thakur",
                "email": "maithili@hww.com",
                "description": "Maithili Thakur  is an Indian playback singer trained in Indian classical music and Folk Music. She has sung original songs, covers, and traditional folk music prominently in Hindi, Bengali, Maithili, Urdu, Marathi, Bhojpuri, Punjabi, Tamil, English and more Indic languages.",
                "status": "ACTIVE",
                "profileImageUrl": "https://d35f5vastl4boc.cloudfront.net/profileImage/maithali_thumbnail.webp",
                "followersCount": 0,
                "followingCount": 0,
                "videoCount": 0,
                "bookmarkCount": 0,
                "backgroundImage": "https://d35f5vastl4boc.cloudfront.net/profileImage/maithali_detail.webp"
            }
        },
        {
            "id": "e31557ba-979a-4f60-b7cd-0e1c3ff96793",
            "ordering": 25,
            "displayName": "",
            "firstName": "Sanjith Hegde",
            "lastName": "",
            "likeCount": 0,
            "playCount": 0,
            "viewCount": 0,
            "followers": 0,
            "description": "Sanjith Hegde is Gen Z Indian playback singer and has sung songs in Kannada, Telugu, Tamil and Hindi.",
            "duration": 0,
            "thumbnail": "https://d35f5vastl4boc.cloudfront.net/profileImage/thumbnail_sanjith.webp",
            "url": "https://d35f5vastl4boc.cloudfront.net/profileImage/thumbnail_sanjith.webp",
            "assetId": "e31557ba-979a-4f60-b7cd-0e1c3ff96793",
            "contentType": "",
            "bannerUrl": "https://d35f5vastl4boc.cloudfront.net/profileImage/detail_sanjith.webp",
            "pristine_image": "",
            "profile": {
                "_id": "e31557ba-979a-4f60-b7cd-0e1c3ff96793",
                "userId": "e31557ba-979a-4f60-b7cd-0e1c3ff96793",
                "fullName": "Sanjith Hegde",
                "userName": "Sanjith Hegde",
                "email": "sanjith@hww.com",
                "description": "Sanjith Hegde is Gen Z Indian playback singer and has sung songs in Kannada, Telugu, Tamil and Hindi.",
                "status": "ACTIVE",
                "profileImageUrl": "https://d35f5vastl4boc.cloudfront.net/profileImage/thumbnail_sanjith.webp",
                "followersCount": 0,
                "followingCount": 0,
                "videoCount": 0,
                "bookmarkCount": 0,
                "backgroundImage": "https://d35f5vastl4boc.cloudfront.net/profileImage/detail_sanjith.webp"
            }
        },
        {
            "id": "c2e7efe3-63e1-4f71-a9e0-5e0c73082f40",
            "ordering": 26,
            "displayName": "",
            "firstName": "Amira Gill",
            "lastName": "",
            "likeCount": 0,
            "playCount": 0,
            "viewCount": 0,
            "followers": 0,
            "description": "Amira Gill is one of India's fastest growing vocalists moonlighting as a music therapist - she has studied at the prestigious music school of Berklee, she is also part of the Berklee Indian Ensemble.",
            "duration": 0,
            "thumbnail": "https://d35f5vastl4boc.cloudfront.net/profileImage/thumbnail_amira.webp",
            "url": "https://d35f5vastl4boc.cloudfront.net/profileImage/thumbnail_amira.webp",
            "assetId": "c2e7efe3-63e1-4f71-a9e0-5e0c73082f40",
            "contentType": "",
            "bannerUrl": "https://d35f5vastl4boc.cloudfront.net/profileImage/detail_amira.webp",
            "pristine_image": "",
            "profile": {
                "_id": "c2e7efe3-63e1-4f71-a9e0-5e0c73082f40",
                "userId": "c2e7efe3-63e1-4f71-a9e0-5e0c73082f40",
                "fullName": "Amira Gill",
                "userName": "Amira Gill",
                "email": "amiragill@hww.com",
                "description": "Amira Gill is one of India's fastest growing vocalists moonlighting as a music therapist - she has studied at the prestigious music school of Berklee, she is also part of the Berklee Indian Ensemble.",
                "status": "ACTIVE",
                "profileImageUrl": "https://d35f5vastl4boc.cloudfront.net/profileImage/thumbnail_amira.webp",
                "followersCount": 0,
                "followingCount": 0,
                "videoCount": 0,
                "bookmarkCount": 0,
                "backgroundImage": "https://d35f5vastl4boc.cloudfront.net/profileImage/detail_amira.webp"
            }
        }
    ],
    "likedVideos": []
}

